import { Type } from "class-transformer";

/**
 * a way of expressing a striker object's properties. A broad range of properties can be expressed through the val
 * property.
 *  
 */
export class Prop {

  /**
   * The stat is a modifier if it provides a bonus or penalty to another (isMod===false) stat of the same unit.
   * The default is false.
   */
  name: string;


  /**
   * A string in property format, relating to the name. An example of some property entries:
   * [
      {"name":"melee-weapon"},
      {"name":"skill", "val": "Str/Dex:Wpn-Shortblades"},
      {"name":"base", "val": "{0}"},
      {"name":"weight", "val": "0.3(kg)"},
      {"name":"act", "val": 
      "BM(att) with -4(Str,min), 13(q) then 2d4+BM(hp,sls), 2(pen)"},
      {"name":"consumption", "val": "1(p) per 15(act)"}
    ]
   */
  val: string;

  @Type(() => Prop)
  group: Prop[];


  constructor(
    name: string,
    val: string,
    group: Prop[]

  ) {
    this.name = name;
    this.val = val;
    this.group = group;
   }
}