
export class BlogMenuState {

    public static createNow() {
        const parts: string[] = BlogMenuState.getDateStringArray();
        return {blogyearOpenIndex: parts[0],blogyearSelectedIndex: parts[0],blogmonthOpenIndex: parts[1],blogmonthSelectedIndex: parts[1],blogSelectedIndex: ''};
    }

    public static getDateStringArray(isoDate?: string): string[] {

        const date: Date = isoDate? new Date(isoDate) : new Date();
        const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
            weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' 
        });

        const parts = dateTimeFormat.formatToParts(date);

        //["Friday", ", ", "June", " ", "1", ", ", "2012"]
        const partValues = parts.map(p => p.value);

        const year = partValues[6];
        const month = partValues[2];
        return [year, month]
    }

    constructor(
        public blogyearOpenIndex: string,
        public blogyearSelectedIndex: string,
        public blogmonthOpenIndex: string,
        public blogmonthSelectedIndex: string,
        public blogSelectedIndex: string
    ) {}
    
}
