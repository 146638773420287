import { Blog } from ".";

export class BlogDTO {
  public static makeEmptyBlog = (): Blog => {
    const blog: Blog = Object.apply(Blog);
    blog.body = '';
    blog.tags = new Array<string>();
    blog.isPublished = false;
    return blog;
  }
}