import * as React from "react";
import { useForm, SubmitHandler, Controller, useFieldArray } from "react-hook-form";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { strikerTheme } from "../../../shared/components/StrikerTheme";
import TextField from "@mui/material/TextField";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { Background, CountSpecRef, SkillDef, SpecRef, Char, SkillDefDTO, BackgroundDTO, CountSpecRefDTO, CharDTO } from "../../models/specs";
import { SkillRanksPanel } from "./SkillRanksPanel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
export const BackgroundsForm = ({
  onSubmit,
  data
}: {
  data: Partial<Char>;
  onSubmit: SubmitHandler<Partial<Char>>;
}) => {


  const { handleSubmit, setValue, getValues, control } = useForm({
    defaultValues: data
  });

  const specState = useAppSelector(state => state.specState);
  const backgrounds: Background[] = BackgroundDTO.findAllInState(specState.specKeys)
  const allSkills: SkillDef[] = SkillDefDTO.findAllInState(specState.specKeys)

  const { fields, append, remove } = useFieldArray({
    control,
    name: "backgrounds"
  });

  const [backgroundsFm, setBackgroundsFm] = useState<Array<CountSpecRef>>(getValues().backgrounds as CountSpecRef[])

  const [abilityRankBudget, setAbilityRankBudget] = useState(CharDTO.abilityRanksBudget(getValues()))
  const [forteRankBudget, setForteRankBudget] = useState(CharDTO.forteRanksBudget(getValues()))
  const [fineForteRankBudget, setFineForteRankBudget] = useState(CharDTO.fineForteRanksBudget(getValues()))

  const abilityRankTracker = () => {
    console.log('fired ability rank tracker (to recalculate values)')
    setAbilityRankBudget(CharDTO.abilityRanksBudget(getValues()));
  }
  const forteRankTracker = () => {
    console.log('fired forte rank tracker (to recalculate values)')
    setForteRankBudget(CharDTO.forteRanksBudget(getValues()));
  }
  const fineForteRankTracker = () => {
    console.log('fired fine forte rank tracker (to recalculate values)')
    setFineForteRankBudget(CharDTO.fineForteRanksBudget(getValues()));
  }
  console.log(getValues().backgrounds)
  console.log(backgroundsFm)
  /**
   * adds a new blank background with blank choices
   */
  const addBackground = () => {
    let updatedBackgrounds = [...backgroundsFm, { start: 0, offset: 0, count: 0, spec: { name: '', ref: 'background' } }]
    setBackgroundsFm(updatedBackgrounds); // this is async, isn't it?
    setValue("backgrounds", updatedBackgrounds);

  }

  useEffect(() => {

    console.log('useEffect called...')

    let ageAdder = 0;
    // bonus ranks
    backgroundsFm.forEach(csrb => {
      if (csrb.spec.name) {
        let background: Background = backgrounds.filter(b => b.name === csrb.spec.name).at(0) as Background
        console.log(background)
        BackgroundDTO.makeSkillScoreAwards(background, allSkills, csrb.offset)

        ageAdder = ageAdder + (csrb.count * 6);
      }
    })
    setValue('tallies', CharDTO.setAgeFromBackground(getValues(),ageAdder));

    abilityRankTracker();
    forteRankTracker();
    fineForteRankTracker();

  }, [backgroundsFm]); // restricted to firing only when backgroundsFm changes

  /**
   * Based on the backgrounds loaded and available, this should guide the user into background selection
   * and provide allocatable skills.
   */

  return (

    <ThemeProvider theme={strikerTheme}>
      <SkillRanksPanel ranks={abilityRankBudget} />
      <SkillRanksPanel ranks={forteRankBudget} />
      <SkillRanksPanel ranks={fineForteRankBudget} />

      <div className='actions'>

        <button
          type='button'
          className='btn edit-btn'
          onClick={() => addBackground()}
        >
          Add
        </button>
      </div>

      <form className='form' onSubmit={handleSubmit(onSubmit)}>
        <h4>Backgrounds</h4>

        <Grid container spacing={1}>
          <Grid xs={9}>
            <h5>Background</h5>
          </Grid>
          <Grid xs={3}>
            <h5>Terms</h5>
          </Grid>
        </Grid>
        {fields.map((background, index) => (

          <Grid container spacing={1} key={background.spec.name}>
            <Grid xs={9}>
              <Controller
                name={`backgrounds.${index}.spec`}
                control={control}
                render={({ field: { onChange, value, ...field } }) => {

                  function handleChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
                    console.log(`changed the background to: ${event.target.value}`)
                    let currFmBg = getValues().backgrounds;
                    if (currFmBg) {
                      let currentBackground: CountSpecRef = currFmBg.at(index) as CountSpecRef;

                      let newFmBg = CountSpecRefDTO.replaceItemOnName(currFmBg, currentBackground.spec.name, event.target.value);

                      setBackgroundsFm(newFmBg); // this is async, isn't it?
                    }

                    onChange({ name: event.target.value, ref: 'background' });
                  }
                  function setInitialValue(value: SpecRef | undefined) {
                    return value ? value.name : '';
                  }

                  return (
                    <TextField {...field} value={setInitialValue(value)} onChange={handleChange} select>
                      {backgrounds.map((b) => (
                        <MenuItem key={b.name} value={b.name} selected={value.name === b.name}>
                          {b.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />

            </Grid>
            <Grid xs={3}>
              <Controller
                name={`backgrounds.${index}.count`}
                control={control}
                render={({ field: { value, onChange, ...field } }) => {
                  // still not working. it seems I'm adding elements of the same type each time

                  function handleChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
                    // the setValue() will look after itself, but we still need to change the backgroundsFm reference (which won't update itself)
                    // given that this was called, we have just set the number of terms for the background. Most likely, there will be a corresponding background to check
                    let currFmBg = getValues().backgrounds;
                    if (currFmBg) {
                      let currentBackground: CountSpecRef = currFmBg.at(index) as CountSpecRef;
                      //let currentBackground: CountSpecRef = backgroundsFm.at(index) as CountSpecRef;
                      currentBackground.count = parseInt(event.target.value);

                      let newFmBg = CountSpecRefDTO.replaceItemOnCount(currFmBg,currentBackground);

                      setBackgroundsFm(newFmBg); // this is async, isn't it?
                    }
                    onChange(event.target.value);
                  }

                  return (
                    <TextField sx={{ width: '6ch' }} {...field} value={value} onChange={handleChange} select>
                      {[...Array(11).keys()].map((n) => (
                        <MenuItem key={n}
                          value={n}
                          selected={value === n}>
                          {n}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
          </Grid>

        ))}

        <Controller name="fortes" control={control} render={({ field: { value, ...field } }) => {
          return (<TextField {...field} sx={{ display: { xs: 'none' } }} />)
        }} />

        <Controller name="tallies" control={control} render={({ field: { value, ...field } }) => {
          return (<TextField {...field} sx={{ display: { xs: 'none' } }} />)
        }} />

        <button
          type='submit'
          className='btn btn-block btn-danger'
        >
          Submit
        </button>
      </form>
    </ThemeProvider>

  )
};
