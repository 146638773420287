import React from 'react';
import { Wrapper } from '../../../assets/wrappers/Home'
import losingControl from '../../../assets/images/losing_control.jpg'

export const About = () => {

    return (
        <Wrapper>
            <div className='content'>
            <img src={losingControl} alt="it's this button here, right?" width="700px"/>
            <h2>About Us</h2>
            <p>
                Zenith Games Foundry is an equal opportunity employer that completely believes in diversity.
                 Regardless of your location in the galaxy, and alien race from which you were spawned, we
                 are only too happy to exploit you for your labours, and pay you a pittance.
            </p>
            <h4>Author's Bio</h4>

            <p>My name is Mike. I am a cranky, aspergery, pedantic nerd, who lives in his mother's basement.</p>

            <p>Ok, perhaps I can expand on this a little bit</p>

            <p>
              Striker, in one form or another has been a bit of a project my whole life, really. At age 13, when I first discovered role-playing games (it was 1981, so, off you go, do some maths)
               and my gateway drug was Dungeons & Dragons (as it was for most, I expect). I soon discovered Traveller, however, and was absolutely blown away by it. I forever hold a deep, personal
               gratitude to Marc Miller and Frank Chadwick. There were so many things about Traveller that I loved, and I think they can be piled evenly between the mechanics of the rules as well as aspects of the scenario.
            </p>

            <p>
              What might have set me apart from my peers was that I wasn't just a player, and very soon a GM, but I began creating my own adventures and then my own role-playing games. 
              My very first was called Galaxy, and I don't mind telling you now, that it was a shocker. It was loosely based on first edition D&D, it was heavily derivative of 
              Hitch-hiker's Guilde to the Galaxy (which can be seen as a good thing, as long as its a good derivation), and it turned out that it was the very same name as a role-playing game 
              invented by another kid in the year ahead of me in school. This would have been around about 1983.
            </p>

            <p>
              Anyway, by about 1986, I had invented a role-playing game based on Blade Runner (a movie that I absolutely loved), and another called Outzone. 
              These were both using a d10 mechanic of my own making, and were significantly better than my first effort.
            </p>

            <p>
              As this d10 mechanic evolved and Outzone soon got renamed Zenith, I was starting to think about publishing. In my naivete, I thought it would be about hard-work and persistence, but I eventually 
              realised (around 1993 to about 1998) that a combination of:
              <ol>
               <li>the location of Australia (far away from the United States and the United Kingdom);</li> and 
               <li>a certain reluctance by the Australian business culture to put any capital into a venture, without an englishman or an american telling them that its a good idea</li>
              </ol>
              that it wasn't going to happen. I realised, in fact, that I would have to move to America, if I wanted to pursue this as a business.
            </p>

            <p>As an aside I came up with an idea in 1997 that was near identical to what World of Warcraft was to become. I tried selling this idea to a number of Australian businesses, 
              including one called Vision Graphics in North Sydney. I wonder if they, or any of the others, realised by 2004, that they'd probably passed up being billionaires. 
              My excuse is that convincing Australian businesses to do anything innovative is uphill work, I wonder what their's is?</p>

            <p>So, I'd abandoned the whole idea, and focussed on a career in software development, which is what I work on to this current day.</p>

            <p>
              Then, finally, in 2022, the right combination of technology became available for self-publishing, along with changes in my life that freed up some spare time, and Striker was born. 
            </p>
            <p>
              ...and now, in a case of history repeating itself, it turns out that Striker is the name of some 15mm miniature rules for none other than the Traveller RPG. 
              I have no idea why it was called Striker, but is was published in 1981, two years before I even heard about role-playing games. So, we'll see whether I get into trouble 
              and have to rename the game to something else. Maybe I'll take a leaf out of the fake products industry and call it...Stryker. 
              Anyway if Marc Miller or Frank Chadwick are annoyed by this, then I apologise, I have no intention of leveraging the name Striker from these miniature rules, nor have I taken anything from them.
              In my estimation, <i>Striker - the TTRPG</i> has only one thing in common with <i>Striker - the miniatures rules</i>: the name. If that has to change, then I'll do it. 
              It won't be easy, because the name represents a technology or process in a central part of the game, and I would have to change a ton of stuff inside the game as well, or change the focus of the game.
            </p>

            <p>You can reach me via the The Striker Mechanica community Discord server, please follow this invite: <a href="https://discord.gg/VmB3hH5tTu">https://discord.gg/VmB3hH5tTu</a></p>
            </div>
        </Wrapper>
    )
}