import styled from 'styled-components'

export const Wrapper = styled.article`
.ranks-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
}

.ranks-title {
  grid-row-start: 1;
  grid-row-end: 3;
  justify-self: center;
  text-transform: capitalize;

}
.start-title {
  grid-row-start: 1;
  grid-row-end: 2;
  font-size: var(--extra-small-text);
  color: var(--farout-brown);
  text-transform: capitalize;
  align-items: end;
}
.start-ranks {
  grid-row-start: 2;
  grid-row-end: 3;
  color: var(--farout-green);
  align-items: end;
}
.allocated-title {
  grid-row-start: 1;
  grid-row-end: 2;
  font-size: var(--extra-small-text);
  color: var(--farout-brown);
  text-transform: capitalize;
  align-items: end;
}
.allocated-ranks {
  grid-row-start: 2;
  grid-row-end: 3;
  align-items: end;
  color: var(--farout-green);
  align-items: end;
}
.remaining-title {
  grid-row-start: 1;
  grid-row-end: 2;
  font-size: var(--extra-small-text);
  color: var(--farout-brown);
  text-transform: capitalize;
  align-items: end;
}
.remaining-ranks {
  grid-row-start: 2;
  grid-row-end: 3;
  align-items: end;
  color: var(--farout-green);
  align-items: end;
}
.remaining-ranks-deficit {
  grid-row-start: 2;
  grid-row-end: 3;
  align-items: end;
  color: var(--farout-red);
  align-items: end;
}
.ranks-label {
  padding-left: 1em;
  padding-top: 2em;
  align-self: start;
  padding-left: 0.5em;
}
`