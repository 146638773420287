import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { fetchBlog, fetchBlogs } from '../blogsSlice';
import { Wrapper } from '../../../assets/wrappers/BlogDisplay'
import { BlogInfo } from './BlogInfo';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttributionIcon from '@mui/icons-material/Attribution';
import moment from 'moment'
import { ROLE_ADMIN, ROLE_WRITER } from '../../auth/User';
import { Tag } from '../../shared/components';


export const BlogDisplay = () => {
  const appstate = useAppSelector(state => state.appstate);
  const userstate = useAppSelector(state => state.userstate);
  const blogstate = useAppSelector(state => state.blogstate);
  
  const { id } = useParams();
  console.log(`DisplayBlog has id: ${id}`)
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id) {
      console.log(`calling dispatch fetchBlog with id: ${id}`)
      dispatch(fetchBlog(id))
    } else {
      console.log(`calling dispatch fetchBlogs (no id given)`)
      const f = async () => {
        await dispatch(fetchBlogs());
        console.log(`is the id available?: ${blogstate.blogs}`)
        if (blogstate.blogs) {
          dispatch(fetchBlog(blogstate.blogs[0].id))
        }
      }

      f();

    }


  }, [appstate.iSidebarSelected,id]);

  //const navigate = useNavigate();
  const blog = blogstate.blog;


  let created = moment(blog && blog.created).format('MMM Do, YYYY');
  let updated = moment(blog && blog.updated).format('MMM Do, YYYY');

  const user = userstate.loggedInUser;
  const canEdit = user && (user.role === ROLE_WRITER || user.role === ROLE_ADMIN)

  return blogstate.blog && (
    <Wrapper>


      <header>
        <div className='content-distribute'>
          <div className='info'>
            <h4>{blog.title}</h4>
            <p>{blog.subtitle}</p>
            <div className='tag-content'>
              {blog.tags?.map(t => (
                <Tag key={t} content={t} isLarge={false}></Tag>               
              ))}
            </div>

          </div>
          <div className='info'>
            <BlogInfo icon={<AttributionIcon />} content={blog.author as string} label='Author' />
            <div>
              {canEdit && (
                <footer>
                  <div className='actions'>
                    <Link
                      to={`/edit-blog/${blog.id}`}
                      className='btn edit-btn'
                    >
                      Edit
                    </Link>
                  </div>
                </footer>
              )}

            </div>
          </div>
        </div>
      </header>
      <div className='content'>
        <div className='content-center'>
          <BlogInfo icon={<CalendarMonthIcon />} content={created} label='Created' />
        </div>

        <div>
          <div dangerouslySetInnerHTML={{ __html: `${blog.body}` }}></div>

        </div>

      </div>

    </Wrapper>
  )
}