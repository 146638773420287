

export const addHoursToDate = (objDate: Date, intHours: number): Date => {
  var numberOfMlSeconds = objDate.getTime();
  var addMlSeconds = (intHours * 60) * 60 * 1000;
  var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
  return newDateObj;
}
export const createNow = () => {
  const parts: string[] = getDateStringArray();
  return {blogyearOpenIndex: parts[0],blogyearSelectedIndex: parts[0],blogmonthOpenIndex: parts[1],blogmonthSelectedIndex: parts[1],blogSelectedIndex: ''};
}

export const getDateStringArray = (isoDate?: string): string[] => {

  const date: Date = isoDate? new Date(isoDate) : new Date();
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' 
  });

  const parts = dateTimeFormat.formatToParts(date);

  //["Friday", ", ", "June", " ", "1", ", ", "2012"]
  const partValues = parts.map(p => p.value);

  const year = partValues[6];
  const month = partValues[2];
  return [year, month]
}

/**
 * Tests a spec to see if it is characteristic of a SKillDef
 * 
 * Note that the casting to SkillDef will not make this a SkillDef for the purposes of typeof, its still an object
 * But using it this way will give you a simple way to test the waters of the object before using it, and to also solve
 * the linting issues as in 'object "foo" cannot be assigned to "bar" as there are no ...'
 * @param o the Spec to be tested
 * @returns return the object recast as a SkillDef if it has the characteristic or void if it does not.
 */
/*
export const jsonObjToSpec = (o: Spec | void): Spec | void => {
  if (o) {
    if (Object.keys(o).includes('name')) {
      return o as unknown as Spec;
    }
  }
}

/**
 * Tests a spec to see if it is characteristic of a SKillDef
 * 
 * Note that the casting to SkillDef will not make this a SkillDef for the purposes of typeof, its still an object
 * But using it this way will give you a simple way to test the waters of the object before using it, and to also solve
 * the linting issues as in 'object "foo" cannot be assigned to "bar" as there are no ...'
 * @param o the Spec to be tested
 * @returns return the object recast as a SkillDef if it has the characteristic or void if it does not.
 */
/*
export const jsonObjToSkillDef = (o: Spec | void): SkillDef | void => {
  if (o) {
    if (Object.keys(o).includes('name') && Object.keys(o).includes('echelon')) {
      return o as unknown as SkillDef;
    }
  }
}

/**
 * Substitutes
 * @param o the Spec to have its references subsituted
 * @param currSpecs the the map of Spec already inexistence, from where we will resolve our
 * references by type (using jsonObjToSkillDef and friends) and name.
 * @returns the substituted Spec (hopefully)
 */
export const jsonObjSubsitute = (o:any, currSpecs:any, level:number, p:any|undefined): any => {

  
  if (o) {

    if(level===0){
      p = o;
    }
  
    Object.entries(o).forEach(e => {
      
      if(Array.isArray(e[1]) ) {
        
        e[1].forEach(i => {
          let parts = i.split('|');
          let pushedElem;

          Object.entries(currSpecs).forEach((kva) => {
            if(kva[0]===parts[1]) {
              pushedElem = kva[1];
            }
          });

          if (pushedElem) {
            i = pushedElem;
          }
        });
        
        
      } else if (typeof e[1] === 'object') {
        level++;
        return jsonObjSubsitute(e[1], currSpecs,level,p);
      }
      if(level===0){
        // need to assign the parent back
        console.log('the target')
        console.log(p)
        console.log('the working')
        console.log({name:e[0],e})
        Object.assign(p,{name:e[0],e});
      }
      
    })

  }
  return currSpecs;

}

export const isNumber = (value?: string | number): boolean => {
  return ((value != null) &&
          (value !== '') &&
          !isNaN(Number(value.toString())));
}

export const camelCaseToCapWords = (s: string) => {
 const result = s.replace(/([A-Z])/g, ' $1');
 return result.charAt(0).toUpperCase() + result.slice(1);
}

export const sentenceToCapFirst = (s: string) => {
 return s.charAt(0).toUpperCase() + s.slice(1);
}

export const shrinkIfValue = (value: string | number | undefined) => {
  if (typeof value ==='string') {
    return {shrink: (value ? value.length > 0 : false)}
  } else if (typeof value ==='number') {
    return {shrink: (value ? value > 0 : false)};
  } else {
    return {shrink: false};
  }
}


export const rangeArray = (n: number) => {
  return Array.from({length: n}, (_, i) => i + 1)
}

export const pageArray = <T>(arr: Array<T>, page: number, pageSize: number): Array<T> => {

  if ((!arr || (page - 1) * pageSize > arr.length)) return [];
  
  const start = (page - 1) * pageSize;  // zero-based
  const end = Math.min(page * pageSize, arr.length);

  return arr && arr.slice(start, end);
}

