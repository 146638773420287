import React from 'react';
import { BlogSearch, BlogList } from '.'

export const BlogSearchAndList = () => {
  return (
    <>
      <BlogSearch />
      <BlogList />
    </>
  )
}

