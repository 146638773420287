import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { Wrapper } from '../../../assets/wrappers/AuthForm'
import { useNavigate } from 'react-router-dom'

import { User } from '../User';
import { loginUser, createUser, toggleRegistered } from '../authSlice';
import { infoAlert, errorAlert, clearAlert } from '../../shared/appSlice';
import { useForm, useFieldArray } from 'react-hook-form';
import { Alert, Logo } from '../../shared/components';

export const Auth = () => {
    const userstate = useAppSelector(state => state.userstate);
    const appstate = useAppSelector(state => state.appstate);
    
    const { register, handleSubmit, control, setValue, watch, formState: { errors } } = useForm<User>({
        defaultValues: {} // or dispatch call?
    });
   
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // useEffect Hook = componentDidMount, componentDidUpdate, and componentWillUnmount combined
    useEffect(() => {
        dispatch(clearAlert());
        
    });

    const handleContinueAsAnonymous = () => {
      // ensure that this component didn't re-login or something???
      console.log('redirecting to home')
      localStorage.removeItem('userToken');
      localStorage.removeItem('user');

      // high-tail it outta here
      navigate('/');
    }
    

    const onSubmit = handleSubmit(async (data: User) => {
        if(userstate.isRegistered) {
            dispatch(loginUser(data));


            console.log(JSON.stringify(userstate.loggedInUser))
 
            localStorage.setItem('userToken', userstate.loggedInUser?.userToken as string);
            localStorage.setItem('user', JSON.stringify(userstate.loggedInUser as User));

            dispatch(infoAlert('successfully logged in, redirecting...'))
            setTimeout(() => {
                navigate('/')
              }, 3000)
    
        } else {
            dispatch(createUser(data));
            if(userstate.errorMessage) {
                dispatch(errorAlert(userstate.errorMessage))   
            } else {
                dispatch(infoAlert('successfully registered'));
            }
        }
    });
    
    const emailEntry = watch('email');
    const passwordEntry = watch('password');
    const usernameOrEmailEntry = watch('usernameOrEmail');


    return (
        <Wrapper className='full-page'>
            <Logo scale="40%" />
            <h3>{userstate.isRegistered ? 'Login' : 'Register'}</h3>
            {appstate.showAlert && <Alert />}
            <form className='form' onSubmit={ onSubmit }>
                {userstate.isRegistered && (
                    <div className='form-row'>
                    <label className='form-label'>Username or Email</label>
                    <input type="text" className='form-input' {...register('usernameOrEmail')} />
                    </div>
                )}
                {!userstate.isRegistered && (
                  <div>
                    <div className='form-row'>
                    <label className='form-label'>Username</label>
                    <input type="text" className='form-input' {...register('username')} />
                    </div>
                    <div className='form-row'>
                    <label className='form-label'>Email</label>
                    <input type="text" className='form-input' {...register('email', { required: true})} />
                    {errors.email && 'Email is required'}               
                    </div>
                  </div>
                )}
                <div className='form-row'>
                <label className='form-label'>Password</label>
                <input type="password" className='form-input' {...register('password', { required: true})} />
                {errors.password && 'Password is required'}
                </div>

                <button type='submit' disabled={ (!emailEntry && !usernameOrEmailEntry) || !passwordEntry } className='btn btn-block'>
                    Submit
                </button>
                
            </form>
            <p>
                {userstate.isRegistered ? 'Not a member yet?' : 'Already a member?'}
                <button type='button' onClick={() => dispatch(toggleRegistered())} className='member-btn'>
                    {userstate.isRegistered ? 'Register' : 'Login'}
                </button>
                <br />
                Otherwise, ignore all of this and go on as <a className='member-btn' onClick={() => handleContinueAsAnonymous()}>anonymous</a>
            </p>
            <div className="center-text">
            
            </div>
        </Wrapper>
    );
};