import { plainToInstance } from "class-transformer";
import { PropRef } from "./PropDTO";
import { Profession, CountSpecRef, SkillDef, SkillScoreAward, SpecRef, PropDTO, CountSpecRefDTO } from ".";
import { getSpec, getSpecsByType, TYPE_PROFESSION } from "../../../shared/specSlice";

export const PROP_FORTE_RANKS = "forteRanks";
export const PROP_FINE_FORTE_RANKS = "fineForteRanks";
export const PROP_PRIMARY_FORTE = "primaryForte";
export const PROP_PRIMARY_FINE_FORTE = "primaryFineForte";
export const PROP_ASPECTS = "aspects";

export class ProfessionDTO {

  public static asSpecRef = (b: Profession): SpecRef => {
    return { name: b.name, ref: 'profession' }
  }

  /**
   * Finds the Profession corresponding to the named reference in the (spec) state,
   * and returns it as a fully defined Profession.
   * @param name the named reference to the Profession
   * @returns the Profession corresponding to the named reference
   */
  public static findInState = (name: string) => {
    let specsObj = getSpec(TYPE_PROFESSION, name);
    console.log('ProfessionDTO.findInState')
    console.log(specsObj) // includes 'at':, 'data':
    return plainToInstance(Profession, specsObj.data)
  }

  public static findAllInState = (keys: string[]): Profession[] => {
    let all = getSpecsByType(TYPE_PROFESSION, keys);

    let ret: Profession[] = new Array<Profession>;
    if (all.length > 0) {
      all.map(se => {
        Object.entries(se).map(e => {
          if (e[0] === 'data') {
            ret.push(plainToInstance(Profession, e[1] as Profession));
          }
        })
      })

    }
    return ret;
  }

  /**
   * Returns the string value of a prop, corresponding to the provided name for the provided Profession
   * 
   * Note that the value can be in the form of a comma-separated array of values.
   * @param name 
   * @param s 
   * @returns the string value.
   */
  public static getNamedPropValue = (name: string, s: Profession): string => {
    let valueReturn: string[] = new Array<string>();

    let prop = s.props.filter(p => p.name === name);
    if (prop && prop.length > 0) valueReturn = prop.map(p => p.val);

    return valueReturn.join(',');
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a string
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsPropRefs = (name: string, s: Profession): PropRef[] => {
    let sPropValue = ProfessionDTO.getNamedPropValue(name, s);
    return sPropValue ? PropDTO.asPropRef(sPropValue) : [];
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a string
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsString = (name: string, s: Profession): string => {
    let sPropValueObj: PropRef[] = ProfessionDTO.getNamedPropValueAsPropRefs(name, s);
    return PropDTO.asString(sPropValueObj[0]) as string
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a number
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsNumber = (name: string, s: Profession): number => {
    let sPropValueObj: PropRef[] = ProfessionDTO.getNamedPropValueAsPropRefs(name, s);
    return PropDTO.asNumber(sPropValueObj[0]) as number
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a string
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsSpecRefs = (name: string, bg: Profession): SpecRef[] => {
    let sPropValue = ProfessionDTO.getNamedPropValueAsPropRefs(name, bg);
    return PropDTO.asSpecRefsForQuality(sPropValue) as SpecRef[];
  }

  /**
   * Returns a source entry for a SkillScoreAward based on the supplied profession and the level that the profession
   * was taken in (i.e., 1st, 2nd, 3rd etc.)
   * 
   * "source": {
      "start": 0,
      "offset": 2,
      "count": 0,
      "spec": {
        "name": "navigator",
        "ref": "profession"
      }
    }
   * 
   * @param prof 
   * @param level 
   */
  public static asSkillAwardSource = (prof: Profession, level: number): CountSpecRef => {
    return {start: 0, offset: level, count: 0, spec: {name: prof.name, ref: 'profession'}}
  }

  public static makeSkillScoreAwards = (prof: Profession, level: number): SkillScoreAward[] => {
    let ret = new Array<SkillScoreAward>();

    let forteRanks: String = ProfessionDTO.getNamedPropValue(PROP_FORTE_RANKS, prof);
    console.log(forteRanks)

    let fortes: SkillScoreAward = { score: 4, awardableScore: 4, awardedScore: 0, 
                                    skill: {name: forteRanks, ref: 'skill-def'} as SpecRef, 
                                    appliesTo: CountSpecRefDTO.PARAM_OP_COUNT, 
                                    source: ProfessionDTO.asSkillAwardSource(prof, level)}
    ret = [...ret, fortes];
    
    let fineForteRanks: String = ProfessionDTO.getNamedPropValue(PROP_FINE_FORTE_RANKS, prof);


    let fineFortes: SkillScoreAward = { score: 4, awardableScore: 4, awardedScore: 0, 
                                    skill: {name: fineForteRanks, ref: 'skill-def'} as SpecRef, 
                                    appliesTo: CountSpecRefDTO.PARAM_OP_COUNT, 
                                    source: ProfessionDTO.asSkillAwardSource(prof, level)}
    ret = [...ret, fineFortes];
    
    console.log(ret)
    return ret
  }

  
  public static unMakeSkillScoreAwards = (prof: Profession, level: number): SkillScoreAward[] => {
    let ret = new Array<SkillScoreAward>();

    let forteRanks: String = ProfessionDTO.getNamedPropValue(PROP_FORTE_RANKS, prof);
    console.log(forteRanks)

    let fortes: SkillScoreAward = { score: -4, awardableScore: 4, awardedScore: 0, 
                                    skill: {name: forteRanks, ref: 'skill-def'} as SpecRef, 
                                    appliesTo: CountSpecRefDTO.PARAM_OP_COUNT, 
                                    source: ProfessionDTO.asSkillAwardSource(prof, level)}
    ret = [...ret, fortes];
    
    let fineForteRanks: String = ProfessionDTO.getNamedPropValue(PROP_FINE_FORTE_RANKS, prof);


    let fineFortes: SkillScoreAward = { score: -4, awardableScore: 4, awardedScore: 0, 
                                    skill: {name: fineForteRanks, ref: 'skill-def'} as SpecRef, 
                                    appliesTo: CountSpecRefDTO.PARAM_OP_COUNT, 
                                    source: ProfessionDTO.asSkillAwardSource(prof, level)}
    ret = [...ret, fineFortes];
    
    console.log(ret)
    return ret
  }

  
}