import { Type } from "class-transformer";
import { Prop, Spec, SpecRef } from ".";

export class Background extends Spec {

  /**
   * name - the name of the Background
   */

  /**
   * description - the description of the Background
   */


  /**
   * 
   */
  @Type(() => Prop)
  props: Prop[]

  @Type(() => SpecRef)
  enhancements: SpecRef[]

  @Type(() => SpecRef)
  equipment: SpecRef[]


  constructor(
    name: string,
    description: string,
    props: Prop[],
    enhancements: SpecRef[],
    equipment: SpecRef[]

  ) {
    super(name, description);
    this.props = props;
    this.enhancements = enhancements;
    this.equipment = equipment;
  }
}