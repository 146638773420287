import React from 'react';
import { Wrapper } from '../../../assets/wrappers/Home'
import thesquare from '../../../assets/images/machine_cellar_square_web.jpg';

export const Products = () => {

    return (
        <Wrapper>
            <div className='content'>
            <p>see a list of all titles at <a href="https://www.drivethrurpg.com/browse/pub/22923/Zenith-Games-Foundry">DriveThruRPG</a></p>
            <p>Most of the books are available as full-colour printed books at <a href="www.lulu.com">Lulu</a>: find them by searching their titles.</p>
            <img src={thesquare} alt="be on your guard, this is nothing but a wretched hive of scum and villainy" width="700px"/>
            </div>
        </Wrapper>
    )
}