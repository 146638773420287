import * as React from "react";
import { Header } from "./Header";
import { SimpleTabs } from "./TabGroup";

type FormValues = {
  firstName: string;
};

export default function App() {
  return (
    <div>
      <Header description="Create your character by filling out these tabs" />
      <SimpleTabs />
    </div>
  );
}