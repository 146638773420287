import React from 'react';
import { Wrapper } from '../../../assets/wrappers/Home'
import underConstruction from '../../../assets/images/mamagnolia_acresunderconstruction.gif';

export const Foundry = () => {

    return (
        <Wrapper>
            <div className='content'>
            <h2>The Foundry</h2>
            <p>
                Build your character, your striker, and if you're really keen, a whole sector with stars and stuff.
            </p>
            <img src={underConstruction} alt='under construction' />
            <p>
                Yeah! That's what it's all about - now i just need to go and hunt down some flaming logos. Yes, so I'm not done here by any stretch. 
                Let's face it: I'm just one guy trying to do all this.
            </p>

            <h3>Characters</h3>
            <p>
                With this you'll be able to build and save your character online, and print your character for your meat-space playing pleasure..
            </p>
            <h3>Strikers</h3>
            <p>
                Build a fully-featured and playable striker, or other vehicle.
            </p>
            <h3>Sectors</h3>
            <p>
                Build sector maps through random generation. You want a 12x12x12 lightyear sector with realistic stars and their associated planets?
                The sector generator will build an appropriately dense or sparse sector of stars for your players to explore.
            </p>
            </div>
        </Wrapper>
    )
}