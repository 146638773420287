import React, { FunctionComponent, ReactNode } from 'react';
import { Wrapper } from "../../../assets/wrappers/Tag"

/**
 * Provides structured content, commonly used to display tags. The display is in the form...
 * 
 * label
 * ICON   text
 * 
 * both the lable and icon are optional
 */
type TagParts = {
  icon?: ReactNode,
  content: string,
  label?: string
  isLarge: boolean
  onClick?: any
}

export const Tag: FunctionComponent<TagParts> = ({ icon, content, label, isLarge=false, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      {label && <div className={isLarge ? 'label-large' : 'label'}>{label}</div>}
      <div className='tag-container'>
        {icon && <span className={isLarge ? 'icon-large' : 'icon'}>{icon}</span>}
        <span className={isLarge ? 'tag-content-large' : 'tag-content'}>{content}</span>
      </div>
    </Wrapper>
  )
}
