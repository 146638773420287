import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

//

export interface FileUploadRequestData {
    readonly name: string,
    readonly file: any
}

export interface FileUploadResponseData {
    readonly fileUploadUrl: string
}

export interface FileCollectorRequestData {
  readonly fileSetUrl: string
}

export interface FileCollectorResponseData {
  readonly files: string[]
}



/**
 * pretty confusing but i think the whole mutation type arg:
 * <{}, {name: string, file: any}>
 * represents the data member as:
 * whatever the return from the server is (first arg ot {})
 * whatever we send up to the server (second arg or {name: string, file: any})
 * 
 * I tell ya redux and rtk is not easy
 */

export const fileApi = createApi({
    reducerPath: 'files',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/files' }),
    endpoints: (builder) => ({
      upload: builder.mutation<FileUploadResponseData, FileUploadRequestData>({
        query(data) {
          console.log(data);
          return {
            url: data.name,
            method: 'POST',
            credentials: 'include',
            body: data.file,
          };
        },
      }),
      collectFiles: builder.query<FileCollectorResponseData, FileCollectorRequestData>({
        query(data) {
          
          return {
            url: data.fileSetUrl, // imagining that this would be whatever is extending the baseUrl, i.e., /api/files/char
            method: 'GET',
            credentials: 'include',
          };
        },
      }),
    }),
  });
  export const { useUploadMutation, useCollectFilesQuery, useLazyCollectFilesQuery } = fileApi;
