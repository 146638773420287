import React from 'react';
import { Wrapper } from '../../../assets/wrappers/Home'

export const StrikerPlaceholder = () => {

    return (
        <Wrapper>
            <div className='content'>
            <h2>The Striker Foundry</h2>
            <p>
                A tool for building and maintaining strikers and (possibly) other vehicles. 
            </p>
            <p>
                You need an account in order to make strikers. Please login or register by hitting the anonymous button (top-right).
            </p>
            
            </div>
        </Wrapper>
    )
}