import { Wrapper } from "../../../../assets/wrappers/SkillRanksPanel";
import { RankBudget } from "../../models/specs/CharDTO"

export const SkillRanksPanel = ({
  ranks,
}: {
  ranks: RankBudget
}) => {

  return (
    <Wrapper>
      <div className='ranks-content'>
        <div className='ranks-title'>{ranks.unit}</div>
        <div className='start-title'>start</div>
        <div className='start-ranks'>{ranks.original}</div>
        <div className='start-title'>alloc.</div>
        <div className='start-ranks'>{ranks.used}</div>
        <div className='remaining-title'>remains</div>
        <div className={ranks.available <= 0 ? 'remaining-ranks-deficit' : 'remaining-ranks'}>{ranks.available}</div>
      </div>      

    </Wrapper>
  );
}