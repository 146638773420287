import React from 'react';
import { Wrapper } from '../../../assets/wrappers/Home'
import exploder from '../../../assets/images/intro/rulebook_exploder.png';
import rulebook from '../../../assets/images/intro/striker_core_rulebook.png';

export const Home = () => {

  return (
    <Wrapper>
      <div className='content'>
        <h2>Roleplay in Space</h2>
        <p>Striker is a science-fiction role-playing game that can be played as a space opera, or fit within
          any noir-style cyberpunk-ish adventure. It supports narrative and combatitive styles with an even grace, so you can sci fi any way and any how.
        </p>
        <p>Striker Core Rulebook 2nd Edition (418 pages)<br />
          <a href="https://www.lulu.com/shop/michael-coxon/striker-core-rulebook-2nd-edition/paperback/product-84r588w.html">Paperback</a> AUD 70 / USD 60 (Lulu)<br />
          <a href="https://www.drivethrurpg.com/en/product/483576/Striker-Core-Rulebook-2nd-Edition">Downloadable PDF</a> AUD 20 / USD 15 (DriveThru RPG)</p>

        <h4>The Striker Mechanic - Fast, Even, Fluid, Expansive</h4>
        <div className='content'>
        <div className='content-center'>
        <div className='info'>
            <h4>Striker is fast</h4>
            <p>You are able to task a vast variety of things very quickly, it also helps you set the task up in a way that makes sense.</p>
          </div>
          <div className='info'>
            <h4>Striker is even</h4>
            <p>You are not stuck with one style of gaming, tasking for narration and interaction is exactly the same as for combat and exploration. It means that narrative and combatitive styles both feel natural.</p>
          </div>
          <div className='info'>
            <h4>Striker is fluid</h4>
            <p>It allows you to move between circumstances without a break in the system, remote hacking a communication tower, having a fight between two starships, having a fight between two individuals,
            and diplomatic conversation are all under the one system.</p>
          </div>
          <div className='info'>
            <h4>Striker is expansive</h4>
            <p>You can provide new aspects to the skills you have already, and you can put effort in, to push for a better result.</p>
          </div>
        </div>
        </div>
        <p>It hard to appreciate how well all of this works, until you're playing. There will just be a point when the lights go on, and you realise what you can do with all this (I've seen it happen with new players).</p>
        <p>Over 200 hours of play testing has produced a system that simply works! </p>
        <p>Yes, it might look like a standard d20 adaption, but standard, it is not.</p>
        <p>It's all here to make high tech and space feel real, and offer players the maximum agency in their actions and overall story.</p>
        <p>Character progression and development, while mediated by levels is not a track, but a kind of branching out, you choose which direction your character goes in.
          So every character is unique, and if you want that special ability, you can have it, by just putting in the work. No class features are going to stop you.</p>

        <p>The World of Striker is vast and detailed, so vast and so detailed that it doesn't fit into the Core Rulebook, but I continue to push out articles that discuss some areas, and soon I will have released some epic adventures that will start to bring that world out.</p>

        <p>The Core Rulebook is in its second edition</p>

        <img src={exploder} alt='striker core rulebook' width='100%' />

        <p><a href="https://www.lulu.com/shop/michael-coxon/striker-core-rulebook-2nd-edition/paperback/product-84r588w.html">Striker Core Rulebook 2nd Edition (paperback)</a> AUD 70 / USD 60 (Lulu)</p>
        <p><a href="https://www.drivethrurpg.com/en/product/483576/Striker-Core-Rulebook-2nd-Edition">Striker Core Rulebook 2nd Edition (downloadable PDF)</a> AUD 20 / USD 15 (DriveThru RPG)</p>
        <p>If you want a preview, the complete <a href="https://www.drivethrurpg.com/en/product/460945/striker-core-rulebook">first edition is available at DriveThru RPG</a> for FREE.
          If you're expecting the difference between the first and second edition to be a few tweaks: it's not. Consider it a preview for flavour, don't spend too much time looking at the rules themselves.
          Definitely don't judge the system, until you're looking at the second edition.</p>

        <img src={rulebook} alt='striker core rulebook' width='100%' />

        <p>The Core Rulebook is completely self-contained, you need no other source books whatsoever. It's 418 pages of rules goodness for making, enhancing and equipping your character and playing the game.
          It introduces the Striker universe, its technology and politics.</p>

        <p><a href="/Future">I am working on adventures and supplimentary rulebooks. These will be published as I complete them.</a>.</p>
        <p>For updates and more information, follow this invite onto my Discord server: <a href="https://discord.gg/VmB3hH5tTu">https://discord.gg/VmB3hH5tTu</a></p>

        <h4>Free Character Sheets</h4>
        <p><a href="https://docs.google.com/spreadsheets/d/1IKf7cxaMga54y2mGfGrOjfvovLp-Hqo5HCXvWJeMIAM">Character Generator (Google Sheet)</a></p>
        <p><a href="https://drive.google.com/file/d/1Gr_B_tiBfxMLIaDFxHF2y8bVoxYmolpC/view">Printable PDF</a></p>
        <p>If you've bought <a href="https://www.drivethrurpg.com/en/product/468152/striker-book-of-one-shots">The Book of One Shots</a>,
          and you want to make use of Second Edition characters, or if you just want to have a look at some example Striker characters, enjoy the </p>
        <p><a href="https://drive.google.com/drive/folders/1koNG0hGb5AyXAFQ-kyl-D3e6_NKE6i8Z?usp=sharing"><b>8 One-Shot Characters</b></a>. </p>
        <p>
          Each character is presented in two forms:
          <ol>
            <li>PDF (as they would appear in the Book of One Shots); and</li>
            <li>Google Sheet (used to create then).</li>
          </ol>
        </p>

        <h4>The Striker Mechanic</h4>
        <p>Striker uses an innovative <a href="/Mechanic">skills-based d20 mechanic</a>. It scales, hardly needs a single lookup table, involves simple maths, easy to remember,
          and it tells a good sci fi story (even computer hacking makes sense, I mean that: I spent a lot of time on making that feel right).</p>


        <h4>Cheat Sheets and Reference Sheets</h4>
        <p>The <a href="https://drive.google.com/file/d/1GE9h9XiTTpg9Br3cWN1-1bttRnvzdoFs/view?usp=drive_link">cheat sheet</a> gives a quick, guided example on the basic play. The rest list out the skills and conjugates. The last is a guide on how to understand property expressions</p>
        <p><a href="https://drive.google.com/drive/folders/1G_3QaiFBauCA5xke-E72HPZrefEa7kMl?usp=drive_link">Game Reference Sheets</a></p>

      </div>
    </Wrapper>
  )
}