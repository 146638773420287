import React, { FunctionComponent } from 'react';
import { Char } from '../models/specs';
import { Wrapper } from '../../../assets/wrappers/BlogSummary'
import { CharInfo } from './CharInfo'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttributionIcon from '@mui/icons-material/Attribution';
import { Link } from 'react-router-dom';

import moment from 'moment'
import { deleteChar, editChar, fetchChars } from '../characterSlice';
import { ROLE_ADMIN, ROLE_WRITER } from '../../auth/User';

type CharSummaryParams = {
  key: string,
  char: Partial<Char>,
  loadingState: React.Dispatch<React.SetStateAction<boolean>>
  nCharsState: React.Dispatch<React.SetStateAction<number>>
}

export const CharSummary: FunctionComponent<CharSummaryParams> = ({ char, loadingState, nCharsState }) => {
  const userstate = useAppSelector(state => state.userstate);
  const charstate = useAppSelector(state => state.charstate);

  const dispatch = useAppDispatch();

  const handleDelete = (id: string) => {
    loadingState(true);
    console.log('handling delete')
    dispatch(deleteChar(id));
    dispatch(fetchChars()).then(() => nCharsState(charstate.chars.length - 1));
  }

  const user = userstate.loggedInUser;

  const canEdit = user && (user.role === ROLE_WRITER || user.role === ROLE_ADMIN)
  let created = moment(char.created).format('MMM Do, YYYY');

  return (
    <Wrapper>
      <header>
        <div className='info'>
          <h5>{char.name}</h5>
          <p>{char.sophontcy && char.sophontcy.name}</p>
        </div>
      </header>
      <div className='content'>
        <div className='content-center'>
          <CharInfo icon={<CalendarMonthIcon />} content={created} label='Created'/>
          <CharInfo icon={<AttributionIcon />} content={char.user as string} label='Player' />
         
        </div>
        
        { canEdit && (
          <footer>
            <div className='actions'>
              <Link
                to={`/char/${char.id}`}
                className='btn edit-btn'
              >
                View
              </Link>
              <Link
                to={`/edit-char/${char.id}`}
                className='btn edit-btn'
              >
                Edit
              </Link>
              <button
                type='button'
                className='btn delete-btn'
                onClick={() => handleDelete(char.id as string)}
              >
                Delete
              </button>
            </div>
          </footer>
        )}
      </div>
    </Wrapper>
  )
}
