import { Outlet, useNavigate } from 'react-router-dom'
import Wrapper from '../../../assets/wrappers/SharedLayout'
import { SmallSidebar, BigSidebar, Navbar } from './'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { useEffect, useState } from 'react';
import useIdle from '../../auth/useIdle';
import { logoutUser } from '../../auth/authSlice';
import { User } from '../../auth/User';

export const SharedLayout = () => {

  const userstate = useAppSelector(state => state.userstate);
  const appstate = useAppSelector(state => state.appstate);
  const dispatch = useAppDispatch();
  let loggedInUser = userstate.loggedInUser as User;


  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  /**
   * function to be passed into the useIdle hook
   */
  const handleIdle = () => {
    if (loggedInUser) {
      setShowModal(true); //show modal is triggered when this callback is called by the useIdle hook
      setRemainingTime(30); //time (in seconds) for the countdown shown in the modal, after it pops up
    }
  };

  const { isIdle } = useIdle({ onIdle: handleIdle, idleTime: 45}); // don't be idle for 45 mins

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (isIdle && showModal) {
      interval = setInterval(() => {
        setRemainingTime(
          (prevRemainingTime) =>
            prevRemainingTime > 0 ? prevRemainingTime - 1 : 0 //reduces the second by 1
        );
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isIdle, showModal]);

  useEffect(() => {
    if (remainingTime === 0 && showModal) {
      // alert("Time out!");
      handleLogOut();
    }
  }, [remainingTime, showModal, navigate]); // this is responsoble for logging user out after timer is down to zero and they have not clicked anything

  const handleLogOut = () => {
    console.log('loggin yer out, matey!')
    dispatch(logoutUser(loggedInUser));

    setShowModal(false);
    navigate("/register");
  };

  const handleStayLoggedIn = () => {
    setShowModal(false);

  };

  function millisToMinutesAndSeconds(millis: number) {
    const minutes = Math.floor(millis / 60000);
    const seconds: number = ((millis % 60000) / 1000);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds.toFixed(0);
  }


  return (
    <Wrapper>

      <main className='dashboard' >
        <SmallSidebar />
        <BigSidebar />
        <div>
          <Navbar />
          <div className='dashboard-page'>
            <Outlet />
          </div>
        </div>
      </main>

      {/* handle isIdle for the modal */}
      {isIdle && showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Idle Timeout Warning</h2>
            <p>You are about to be logged out due to inactivity.</p>
            <br />
            Time remaining: {millisToMinutesAndSeconds(remainingTime * 1000)}
            <br />
            <div className="row">
            <button className="btn btn-danger" onClick={handleLogOut}>
              Logout
            </button>
            <button className="btn btn-primary " onClick={handleStayLoggedIn}>
              Stay Logged In
            </button>
            </div>

          </div>
        </div>
      )}
    </Wrapper>
  )
}

