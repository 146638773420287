import { WritableDraft } from 'immer/dist/internal';

export const ROLE_READER:string = 'ROLE-READER'
export const ROLE_WRITER:string = 'ROLE-WRITER'
export const ROLE_PLAYER:string = 'ROLE-PLAYER'
export const ROLE_ADMIN:string = 'ROLE-ADMIN'

export class User {

    public static makeEmpty(): User {
        return new User(null, 'user', null, null, null, null, null, null, false, null);
    }

    public static makeLocalFromUser(user: User): User {
        return new User(user.id, user.role, user.created, user.lastLoggedIn, user.username, user.email, null, null, user.active, null);
    }

    public static asWritableDraft(o: User): WritableDraft<User> {
        return {
            role: o.role,
            id: o.id,
            created: o.created,
            lastLoggedIn: o.lastLoggedIn,
            username: o.username,
            email: o.email,
            usernameOrEmail: o.usernameOrEmail,
            password: o.password,
            active: o.active,
            userToken: o.userToken   
        }
    }

    constructor(
        public id:  null | string,
        public role:  null | string,
        public created:  null | string,
        public lastLoggedIn:  null | string,
        public username:  null | string,
        public email:  null | string,
        public usernameOrEmail: null | string,
        public password:  null | string,
        public active: false,
        public userToken:  null | string
    ){}
}
