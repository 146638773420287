import React, { FunctionComponent } from 'react';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Wrapper from '../../../assets/wrappers/PageBtnContainer'

import { useAppDispatch } from '../../../app/hooks';
import { changePage, prevPage, nextPage, PageState } from '../characterSlice'
import { rangeArray } from '../../shared/utils/general';

type PageBtnContainerParams = {
    pageState: PageState,
  }
  
export const PageBtnContainer: FunctionComponent<PageBtnContainerParams> = ({ pageState }) => {

    const dispatch = useAppDispatch();

    const pageCount = pageState.pageCount;
    const page = pageState.page;

    const pages = rangeArray(pageCount)

    return (
        <Wrapper>
            <button className='prev-btn' onClick={() => dispatch(prevPage)}>
                <KeyboardDoubleArrowLeftIcon />
                prev
            </button>
            <div className='btn-container'>
                {pages.map((pageNumber) => {
                    return (
                        <button
                            type='button'
                            className={pageNumber === page ? 'pageBtn active' : 'pageBtn'}
                            key={pageNumber}
                            onClick={() => dispatch(changePage(pageNumber))}
                        >
                            {pageNumber}
                        </button>
                    )
                })}
            </div>
            <button className='next-btn' onClick={() => dispatch(nextPage)}>
                next
                <KeyboardDoubleArrowRightIcon />
            </button>
        </Wrapper>
    )
}