import { Type } from "class-transformer";
import { Spec, Prop } from "."


export class Profession extends Spec {

  /**
   * name - the name of the profession, variant or archetype
   */

  /**
   * a short description of the profession, variant or archetype
   */

  /**
   * The list of fortes the player can choose to assign ranks to at each level.
   */
  @Type(() => Prop)
  props: Prop[];
  

  constructor(
    name: string,
    description: string,
    props: Prop[]
    

  ) {
    super(name, description);
    this.props = props;
   }
}