import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "../auth/User";
import { findLoggedInUser } from "../auth/authSlice";
import { plainToInstance } from 'class-transformer';
import { Background, Enhancement, Profession, Quality, SkillDef, Sophont, Spec, Vocation, Weapon } from "../character/models/specs";

export const TYPE_SKILL_DEF = 'skill-def';
export const TYPE_QUALITY = 'quality';
export const TYPE_SOPHONT = 'sophont';
export const TYPE_BACKGROUND = 'background';
export const TYPE_PROFESSION = 'profession';
export const TYPE_VOCATION = 'vocation';
export const TYPE_ENHANCEMENT = 'enhancement';
export const TYPE_WEAPON = 'weapon';
export const TYPE_ARMOUR = 'armour';
export const TYPE_VEHICLE = 'vehicle';

export const ALL_SPEC_TYPES = [TYPE_SKILL_DEF,TYPE_QUALITY,TYPE_SOPHONT,TYPE_BACKGROUND,TYPE_PROFESSION,TYPE_VOCATION,TYPE_ENHANCEMENT,TYPE_WEAPON,TYPE_ARMOUR,TYPE_VEHICLE]

export const QUALITY_TYPE_TRAIT = 'TRAIT';
export const QUALITY_TYPE_IDEAL = 'IDEAL';

export const getSkill = (name: string): SkillDef => {
  let skill = localStorage.getItem(`skill-def-${name}-spec`);
  if (skill && skill !== "undefined") {
    let skillObj: SkillDef = JSON.parse(skill);
    return skillObj;
  }
  return {} as SkillDef;
}

export const getSpec = (type: string, name: string): SpecEntry => {
  let specs = localStorage.getItem(`${type}-${name}-spec`);
  if (specs && specs !== "undefined") {
    let specsObj: SpecEntry = JSON.parse(specs);
    return specsObj;
  }

  return {} as SpecEntry;

}
export const setSpec = (type: string, name: string, spec: Spec) => {
  if (type && spec) {

    localStorage.setItem(`${type}-${name}-spec`, JSON.stringify({ at: Date.now(), data: spec } as SpecEntry));
  }
}

// localStorage.getItem(`quality-ammonia-spec`)  =>
// '{"at":1694470135571,"data":{"name":"ammonia","type":"BIOCHEMISTRY","description":""}}'
// localStorage.getItem(`profession-psion-spec`)

// localStorage.getItem(`skill-def-Str/Dex:Cbt-Melee:Chi-Fist-spec`)

export const getSpecsByType = (type: string, keys: string[]): SpecEntry[] => {
  let stateSpecs: string[] = keys.filter(s => s.includes(type)).map(s => s.substring(type.length+1, s.length - 5));
  return stateSpecs.map(n => { return getSpec(type, n) });
}


export const getQualitiesByType = (qualityType: string, keys: string[]): Quality[] => {
  let all = getSpecsByType(TYPE_QUALITY, keys);

  let ret: Quality[] = new Array<Quality>;
  if (all.length > 0) {
    all.map(se => {
      Object.entries(se).map(e => {
        if (e[0] === 'data') {
          Object.entries(e[1]).map(qe => {
            if (qe[0] === 'type' && qe[1] === qualityType) {
              ret.push(plainToInstance(Quality, e[1] as Quality));
            }
          });
        }
      })
    })

  }
  return ret;
}

export const getTraits = (keys: string[]): Quality[] => {
  return getQualitiesByType(QUALITY_TYPE_TRAIT, keys)
}

export const getIdeals = (keys: string[]): Quality[] => {
  return getQualitiesByType(QUALITY_TYPE_IDEAL, keys)
}




export const getProfessions = (keys: string[]): Profession[] => {
  let all = getSpecsByType(TYPE_PROFESSION, keys);

  let ret: Profession[] = new Array<Profession>;
  if (all.length > 0) {
    all.map(se => {
      Object.entries(se).map(e => {
        if (e[0] === 'data') {
          ret.push(plainToInstance(Profession, e[1] as Profession));
        }
      })
    })

  }
  return ret;
}

export const getVocations = (keys: string[]): Vocation[] => {
  let all = getSpecsByType(TYPE_VOCATION, keys);

  let ret: Vocation[] = new Array<Vocation>;
  if (all.length > 0) {
    all.map(se => {
      Object.entries(se).map(e => {
        if (e[0] === 'data') {
          ret.push(plainToInstance(Vocation, e[1] as Vocation));
        }
      })
    })

  }
  return ret;
}






export interface CharSelection {
  readonly alignmentsSelection: string[]
  readonly traitsSelection: Quality[]
  readonly idealsSelection: Quality[]
  readonly professionsSelection: Profession[]
  readonly vocationsSelection: Vocation[]
}


export interface SpecEntry {
  readonly at: number
  readonly data: Spec
}

export interface SpecSliceState {
  readonly specs: Array<Spec>
  readonly specKeys: Array<string>
  readonly charSelection: CharSelection
  readonly errorMessage: string
}

const initialState: SpecSliceState = {
  specs: new Array<Spec>(),
  specKeys: new Array<string>(),
  charSelection: {},
  errorMessage: ''
} as SpecSliceState;

/**
 * foundry means chars or strikers
 * type means skill-def, sophont-def etc
 */
export const fetchSpecs = createAsyncThunk<Object, { foundry: string, type: string }, { readonly rejectValue: { readonly errorMessage: string } }>(
  'specs/fetch',
  async (arg, thunkAPI) => {
    const user: User = findLoggedInUser() as User;

    if (!user) {
      return thunkAPI.rejectWithValue({ errorMessage: 'user not authorised' });
    }

    return await fetch(
      `/api/files/${arg.foundry}/${arg.type}`,
      {
        method: 'GET'
      })

      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(`Unexpected response from server (code ${response.status}).`);
        }
      })

      .catch(function (error) {
        console.error(error);
        return thunkAPI.rejectWithValue({ errorMessage: error.message });
      });
  }
);

const specsSlice = createSlice({
  name: "specState",
  initialState,
  reducers: {
    reset: () => initialState,
    loadAlignmentsSelection: (state) => { state.charSelection.alignmentsSelection = ['Lawful Good', 'Neutral Good', 'Chaotic Good', 'Lawful Neutral', 'Neutral', 'Chaotic Neutral', 'Lawful Evil', 'Neutral Evil', 'Chaotic Evil'] },
    loadTraitsSelection: (state) => { state.charSelection.traitsSelection = getTraits(state.specKeys) },
    loadIdealsSelection: (state) => { state.charSelection.idealsSelection = getIdeals(state.specKeys) },
    loadProfessionsSelection: (state) => { state.charSelection.professionsSelection = getProfessions(state.specKeys) },
    loadVocationsSelection: (state) => { state.charSelection.vocationsSelection = getVocations(state.specKeys) },
  },
  extraReducers: (builder) => {
    builder
      // the case sets the appropriate state, but also sets the localStorage (acting as a cache)
      .addCase(fetchSpecs.fulfilled, (state, action) => {
        let { foundry, type } = action.meta.arg;
        //console.log(`fetch ${foundry} specs on key: ${type}, returning...`);
        //console.log(action.payload);
        // we'll need to process the specs:
        // 1. turn the 'any's into proper objects
        // 2. substitute all of the references
        if (type === TYPE_SKILL_DEF) {
          Object.entries(action.payload).forEach(e => {

            /// right so this iterates twice
            // 1st time e[0] = specs, e[1] = the array we want to turn into instances
            // 2nd time e[0] = type, e[1] = the plain object string type ('skill-def' in this case)
            if (e[0] === 'specs') {
              Object.entries(e[1]).forEach(se => {
                let spec = plainToInstance<SkillDef, Object>(SkillDef, se[1] as SkillDef);
                setSpec(TYPE_SKILL_DEF, se[0], spec)

                let stateSpec = state.specs.find(s => s.name === spec.name);
                if (!stateSpec) {
                  state.specs.push(spec);
                  state.specKeys.push(`${TYPE_SKILL_DEF}-${spec.name}-spec`);
                } else {
                  state.specs.splice(state.specs.indexOf(stateSpec), 1, spec);
                }
              })
            }
          })


        } else if (type === TYPE_QUALITY) {
          Object.entries(action.payload).forEach(e => {

            if (e[0] === 'specs') {
              Object.entries(e[1]).forEach(se => {
                let spec = plainToInstance<Quality, Object>(Quality, se[1] as Quality);
                setSpec(TYPE_QUALITY, se[0], spec)

                let stateSpec = state.specs.find(s => s.name === spec.name);
                if (!stateSpec) {
                  state.specs.push(spec);
                  state.specKeys.push(`${TYPE_QUALITY}-${spec.name}-spec`);
                } else {
                  state.specs.splice(state.specs.indexOf(stateSpec), 1, spec);
                }
              })
            }
          })

        } else if (type === TYPE_SOPHONT) {
          Object.entries(action.payload).forEach(e => {
            if (e[0] === 'specs') {
              Object.entries(e[1]).forEach(se => {
                let spec = plainToInstance<Sophont, Object>(Sophont, se[1] as Sophont);
                setSpec(TYPE_SOPHONT, se[0], spec)

                let stateSpec = state.specs.find(s => s.name === spec.name);
                if (!stateSpec) {
                  state.specs.push(spec);
                  state.specKeys.push(`${TYPE_SOPHONT}-${spec.name}-spec`);
                } else {
                  state.specs.splice(state.specs.indexOf(stateSpec), 1, spec);
                }
              })
            }

          })

        } else if (type === TYPE_BACKGROUND) {
          Object.entries(action.payload).forEach(e => {
            if (e[0] === 'specs') {
              Object.entries(e[1]).forEach(se => {
                let spec = plainToInstance<Background, Object>(Background, se[1] as Background);
                setSpec(TYPE_BACKGROUND, se[0], spec)

                let stateSpec = state.specs.find(s => s.name === spec.name);
                if (!stateSpec) {
                  state.specs.push(spec);
                  state.specKeys.push(`${TYPE_BACKGROUND}-${spec.name}-spec`);
                } else {
                  state.specs.splice(state.specs.indexOf(stateSpec), 1, spec);
                }
              })
            }

          })

        } else if (type === TYPE_PROFESSION) {
          Object.entries(action.payload).forEach(e => {
            if (e[0] === 'specs') {
              Object.entries(e[1]).forEach(se => {
                let spec = plainToInstance<Profession, Object>(Profession, se[1] as Profession);
                setSpec(TYPE_PROFESSION, se[0], spec)

                let stateSpec = state.specs.find(s => s.name === spec.name);
                if (!stateSpec) {
                  state.specs.push(spec);
                  state.specKeys.push(`${TYPE_PROFESSION}-${spec.name}-spec`);
                } else {
                  state.specs.splice(state.specs.indexOf(stateSpec), 1, spec);
                }
              })
            }

          })

        } else if (type === TYPE_VOCATION) {
          Object.entries(action.payload).forEach(e => {
            if (e[0] === 'specs') {
              Object.entries(e[1]).forEach(se => {
                let spec = plainToInstance<Vocation, Object>(Vocation, se[1] as Vocation);
                setSpec(TYPE_VOCATION, se[0], spec)

                let stateSpec = state.specs.find(s => s.name === spec.name);
                if (!stateSpec) {
                  state.specs.push(spec);
                  state.specKeys.push(`${TYPE_VOCATION}-${spec.name}-spec`);
                } else {
                  state.specs.splice(state.specs.indexOf(stateSpec), 1, spec);
                }
              })
            }

          })

        } else if (type === TYPE_ENHANCEMENT) {
          Object.entries(action.payload).forEach(e => {

            if (e[0] === 'specs') {
              Object.entries(e[1]).forEach(se => {
                let spec = plainToInstance<Enhancement, Object>(Enhancement, se[1] as Enhancement);
                setSpec(TYPE_ENHANCEMENT, se[0], spec)

                let stateSpec = state.specs.find(s => s.name === spec.name);
                if (!stateSpec) {
                  state.specs.push(spec);
                  state.specKeys.push(`${TYPE_ENHANCEMENT}-${spec.name}-spec`);
                } else {
                  state.specs.splice(state.specs.indexOf(stateSpec), 1, spec);
                }
              })
            }

          })

        } else if (type === TYPE_WEAPON) {
          Object.entries(action.payload).forEach(e => {
            if (e[0] === 'specs') {
              Object.entries(e[1]).forEach(se => {
                let spec = plainToInstance<Weapon, Object>(Weapon, se[1] as Weapon);
                setSpec(TYPE_WEAPON, se[0], spec)

                let stateSpec = state.specs.find(s => s.name === spec.name);
                if (!stateSpec) {
                  state.specs.push(spec);
                  state.specKeys.push(`${TYPE_WEAPON}-${spec.name}-spec`);
                } else {
                  state.specs.splice(state.specs.indexOf(stateSpec), 1, spec);
                }
              })
            }
          })

        } else if (type === TYPE_ARMOUR) {
          Object.entries(action.payload).forEach(e => {
            if (e[0] === 'specs') {
              Object.entries(e[1]).forEach(se => {
                // todo - need an Armour class
                let spec = plainToInstance<Weapon, Object>(Weapon, se[1] as Weapon);
                setSpec(TYPE_ARMOUR, se[0], spec)

                let stateSpec = state.specs.find(s => s.name === spec.name);
                if (!stateSpec) {
                  state.specs.push(spec);
                  state.specKeys.push(`${TYPE_ARMOUR}-${spec.name}-spec`);
                } else {
                  state.specs.splice(state.specs.indexOf(stateSpec), 1, spec);
                }
              })
            }
          })

        } else if (type === TYPE_VEHICLE) {
          Object.entries(action.payload).forEach(e => {
            if (e[0] === 'specs') {
              Object.entries(e[1]).forEach(se => {
                // todo - need a Vehicle class
                let spec = plainToInstance<Weapon, Object>(Weapon, se[1] as Weapon);
                setSpec(TYPE_VEHICLE, se[0], spec)

                let stateSpec = state.specs.find(s => s.name === spec.name);
                if (!stateSpec) {
                  state.specs.push(spec);
                  state.specKeys.push(`${TYPE_VEHICLE}-${spec.name}-spec`);
                } else {
                  state.specs.splice(state.specs.indexOf(stateSpec), 1, spec);
                }
              })
            }
          })
        }



      })
      .addCase(fetchSpecs.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.errorMessage = action.payload.errorMessage;
        }
      })
  },

});
export const {
  loadAlignmentsSelection,
  loadTraitsSelection,
  loadIdealsSelection
} = specsSlice.actions
export default specsSlice.reducer;