import { useAppSelector, useAppDispatch } from '../../../app/hooks'

import { Logo } from './Logo'
import { Wrapper } from '../../../assets/wrappers/SmallSidebar'
import { SidebarList } from './SidebarList';
import { toggleSidebar } from '../appSlice';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const SmallSidebar = () => {
  const { 
    showSidebar, 
  } = useAppSelector(state => state.appstate);

  const dispatch = useAppDispatch();

  return (
    <Wrapper>
      <div
        className={
          showSidebar ? 'sidebar-container ' : 'sidebar-container show-sidebar'
        }
      >
        <div className='content'>
        <IconButton aria-label="toggle sidebar"
          onClick={() => {
            dispatch(toggleSidebar());
          }}>
          <CloseIcon />
        </IconButton>

        <header>
          <Logo />
        </header>

          <SidebarList />
         
        </div>
      </div>
    </Wrapper>
  )
}

