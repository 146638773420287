import styled from 'styled-components'

export const Wrapper = styled.article`
.skill-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 0.5rem;
  align-items: start;
}
.skill-name {
  grid-row-start: 1;
  grid-row-end: 2;
  padding-left: 1em;
  padding-top: 2em;
  color: var(--farout-dark-green);
}
.skill-score {
  grid-row-start: 2;
  grid-row-end: 3;
  padding-left: 1em;
}
.ranks-score {
  align-items: end;
}
.ranks-label {
  align-self: start;
  padding-left: 0.5em;
}
.tags-content {
  display: flex;
  align-items: start;
}
`