import { FunctionComponent } from 'react';
import logo from '../../../assets/images/zgf-logo.svg';

type LogoParams = {
  scale?: string
}
export const Logo: FunctionComponent<LogoParams> = ({scale='100%'}) => {
  return (
    <>
      <img src={logo} alt='zenith games foundry' width={scale} height={scale} className='logo' />
    </>
  );
}
