import { plainToInstance } from "class-transformer";
import { PropRef } from "./PropDTO";
import { Vocation, CountSpecRef, SkillDef, SkillScoreAward, SpecRef, PropDTO, CountSpecRefDTO } from ".";
import { getSpec, getSpecsByType, TYPE_VOCATION } from "../../../shared/specSlice";

export const PROP_FORTE_RANKS = "forteRanks";
export const PROP_FINE_FORTE_RANKS = "fineForteRanks";

export class VocationDTO {

  public static asSpecRef = (b: Vocation): SpecRef => {
    return { name: b.name, ref: 'vocation' }
  }

  /**
   * Finds the Vocation corresponding to the named reference in the (spec) state,
   * and returns it as a fully defined Vocation.
   * @param name the named reference to the Vocation
   * @returns the Vocation corresponding to the named reference
   */
  public static findInState = (name: string) => {
    let specsObj = getSpec(TYPE_VOCATION, name);
    console.log('VocationtDTO.findInState')
    console.log(specsObj) // includes 'at':, 'data':
    return plainToInstance(Vocation, specsObj.data)
  }

  public static findAllInState = (keys: string[]): Vocation[] => {
    let all = getSpecsByType(TYPE_VOCATION, keys);

    let ret: Vocation[] = new Array<Vocation>;
    if (all.length > 0) {
      all.map(se => {
        Object.entries(se).map(e => {
          if (e[0] === 'data') {
            ret.push(plainToInstance(Vocation, e[1] as Vocation));
          }
        })
      })

    }
    return ret;
  }

  /**
   * Returns the string value of a prop, corresponding to the provided name for the provided Vocation
   * 
   * Note that the value can be in the form of a comma-separated array of values.
   * @param name 
   * @param s 
   * @returns the string value.
   */
  public static getNamedPropValue = (name: string, s: Vocation): string => {
    let valueReturn: string[] = new Array<string>();

    let prop = s.props.filter(p => p.name === name);
    if (prop && prop.length > 0) valueReturn = prop.map(p => p.val);
    console.log(valueReturn)

    return valueReturn.join(',');
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a string
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsPropRefs = (name: string, s: Vocation): PropRef[] => {
    console.log(`PropDTO.getNamedPropValueAsPropRefs, name=${name}`)
    let sPropValue = VocationDTO.getNamedPropValue(name, s);
    console.log(sPropValue)
    return sPropValue ? PropDTO.asPropRef(sPropValue) : [];
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a string
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsString = (name: string, s: Vocation): string => {
    let sPropValueObj: PropRef[] = VocationDTO.getNamedPropValueAsPropRefs(name, s);
    return PropDTO.asString(sPropValueObj[0]) as string
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a number
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsNumber = (name: string, s: Vocation): number => {
    let sPropValueObj: PropRef[] = VocationDTO.getNamedPropValueAsPropRefs(name, s);
    return PropDTO.asNumber(sPropValueObj[0]) as number
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a string
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsSpecRefs = (name: string, bg: Vocation): SpecRef[] => {
    let sPropValue = VocationDTO.getNamedPropValueAsPropRefs(name, bg);
    return PropDTO.asSpecRefsForQuality(sPropValue) as SpecRef[];
  }

  /**
   * Returns a source entry for a SkillScoreAward based on the supplied vocation and the level that the vocation
   * was taken in (i.e., 1st, 2nd, 3rd etc.)
   * 
   * "source": {
      "start": 0,
      "offset": 2,
      "count": 0,
      "spec": {
        "name": "paramedic",
        "ref": "vocation"
      }
    }
   * 
   * @param voc 
   * @param level 
   */
  public static asSkillAwardSource = (voc: Vocation, level: number): CountSpecRef => {
    return {start: 0, offset: level, count: 0, spec: {name: voc.name, ref: 'vocation'}}
  }

  public static makeSkillScoreAwards = (voc: Vocation, level: number): SkillScoreAward[] => {
    let ret = new Array<SkillScoreAward>();

    let forteRanks: String = VocationDTO.getNamedPropValue(PROP_FORTE_RANKS, voc);
    console.log(forteRanks)

    let fortes: SkillScoreAward = { score: 1, awardableScore: 1, awardedScore: 0, 
                                    skill: {name: forteRanks, ref: 'skill-def'} as SpecRef, 
                                    appliesTo: CountSpecRefDTO.PARAM_OP_COUNT, 
                                    source: VocationDTO.asSkillAwardSource(voc, level)}
    ret = [...ret, fortes];
    
    let fineForteRanks: String = VocationDTO.getNamedPropValue(PROP_FINE_FORTE_RANKS, voc);


    let fineFortes: SkillScoreAward = { score: 1, awardableScore: 1, awardedScore: 0, 
                                    skill: {name: fineForteRanks, ref: 'skill-def'} as SpecRef, 
                                    appliesTo: CountSpecRefDTO.PARAM_OP_COUNT, 
                                    source: VocationDTO.asSkillAwardSource(voc, level)}
    ret = [...ret, fineFortes];
    
    console.log(ret)
    return ret
  }

  
  public static unMakeSkillScoreAwards = (voc: Vocation, level: number): SkillScoreAward[] => {
    let ret = new Array<SkillScoreAward>();

    let forteRanks: String = VocationDTO.getNamedPropValue(PROP_FORTE_RANKS, voc);
    console.log(forteRanks)

    let fortes: SkillScoreAward = { score: -1, awardableScore: 1, awardedScore: 0, 
                                    skill: {name: forteRanks, ref: 'skill-def'} as SpecRef, 
                                    appliesTo: CountSpecRefDTO.PARAM_OP_COUNT, 
                                    source: VocationDTO.asSkillAwardSource(voc, level)}
    ret = [...ret, fortes];
    
    let fineForteRanks: String = VocationDTO.getNamedPropValue(PROP_FINE_FORTE_RANKS, voc);


    let fineFortes: SkillScoreAward = { score: -1, awardableScore: 1, awardedScore: 0, 
                                    skill: {name: fineForteRanks, ref: 'skill-def'} as SpecRef, 
                                    appliesTo: CountSpecRefDTO.PARAM_OP_COUNT, 
                                    source: VocationDTO.asSkillAwardSource(voc, level)}
    ret = [...ret, fineFortes];
    
    console.log(ret)
    return ret
  }
  
}