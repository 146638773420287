import * as React from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Char, CharDTO } from "../../models/specs";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { strikerTheme } from "../../../shared/components/StrikerTheme";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { CountSpecRef, SkillDef, SkillDefDTO, Sophont, SpecRef, CountSpecRefDTO } from "../../models/specs";
import { useAppSelector } from "../../../../app/hooks";
import { sentenceToCapFirst, camelCaseToCapWords } from "../../../shared/utils/general";
import { PROP_ADAPTIONS, PROP_LIFESPAN, PROP_MOVES, PROP_RESISTANCES, PROP_SPEED, PROP_STARTING_AGE, SophontDTO } from "../../models/specs/SophontDTO";

export const SophontcyForm = ({
  onSubmit,
  data
}: {
  data: Partial<Char>;
  onSubmit: SubmitHandler<Partial<Char>>;
}) => {

  const { handleSubmit, getValues, setValue, control } = useForm({
    defaultValues: data
  });

  const specState = useAppSelector((state: { specState: any; }) => state.specState);

  const sophonts: Sophont[] = SophontDTO.findAllInState(specState.specKeys);
  const level: number = CharDTO.findCharacterLevel(data);
  const allSkills: SkillDef[] = SkillDefDTO.findAllInState(specState.specKeys)

  React.useEffect(() => {
    console.log(data)
    // we may be coming back to DescriptionForm from another tab.
    // so we want to make sure that everything from the current data is in the form we want to submit
    CharDTO.setFormValues(data, setValue);
    if (!data.user) {
      console.log('WARNING: no user in char!')
    }
  }, []);

  return (
    <ThemeProvider theme={strikerTheme}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>choose your sophont</h4>
        <div className='form-row'>
          <label className='form-label'>Sophontcy</label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="sophontcy"
            render={({ field: { onChange, value, ...field } }) => {

              function handleChange(oldValue: string | undefined, newValue: string) {

                // changing a sophont results in changing a large number of things about a char
                let oldSophont: Sophont | undefined = oldValue ? sophonts.filter(s => s.name === oldValue).at(0) as Sophont : undefined;
                let newSophont: Sophont = sophonts.filter(s => s.name === newValue).at(0) as Sophont;

                // ****************
                // Removing old sophont, if aany

                if (oldSophont) {
                  console.log(oldSophont)

                  let startingAgeDiff = SophontDTO.getNamedPropValueAsNumber(PROP_STARTING_AGE, oldSophont) - SophontDTO.getNamedPropValueAsNumber(PROP_STARTING_AGE, newSophont);

                  let ageValue = data.age ? data.age - startingAgeDiff : SophontDTO.getNamedPropValueAsNumber(PROP_STARTING_AGE, newSophont);
                  CharDTO.setFormValue('age', ageValue, data, setValue);

                  let skillAwards = SophontDTO.unMakeSkillScoreAwards(oldSophont, allSkills, level)
                  CharDTO.setFormValue('skillScoreAwards', skillAwards, data, setValue);
                  
                  skillAwards.forEach(sa => {
                    CharDTO.applySkillAwardsForSkill(data, sa.skill.name);
                  })
                  CharDTO.setFormValue('strength', data.strength, data, setValue);
                  CharDTO.setFormValue('dexterity', data.dexterity, data, setValue);
                  CharDTO.setFormValue('constitution', data.constitution, data, setValue);
                  CharDTO.setFormValue('intelligence', data.intelligence, data, setValue);
                  CharDTO.setFormValue('wisdom', data.wisdom, data, setValue);
                  CharDTO.setFormValue('charisma', data.charisma, data, setValue);
                  CharDTO.setFormValue('perception', data.perception, data, setValue);
                  CharDTO.setFormValue('fortes', data.fortes, data, setValue);
                  CharDTO.setFormValue('fineFortes', data.fineFortes, data, setValue);
  
                  // adaptions
                  let adaptionsProp = SophontDTO.getNamedPropValueAsPropRefs(PROP_ADAPTIONS, oldSophont);
                  let adaptionsValue = data.adaptions;
                  adaptionsProp.forEach(a => {
                    adaptionsValue = CountSpecRefDTO.subtractFromArray(getValues().adaptions as CountSpecRef[], CountSpecRefDTO.makeWithNameAndCount(a.unit as string,a.num as number), CountSpecRefDTO.PARAM_OP_COUNT);
                  })
                  CharDTO.setFormValue('adaptions', adaptionsValue, data, setValue);

                  // resistances
                  let resistancesProp = SophontDTO.getNamedPropValueAsPropRefs(PROP_RESISTANCES, oldSophont);
                  let resistancesValue = data.resistances;
                  resistancesProp.forEach(a => {
                    resistancesValue = CountSpecRefDTO.subtractFromArray(getValues().resistances as CountSpecRef[], CountSpecRefDTO.makeWithNameAndCount(a.unit as string,a.num as number), CountSpecRefDTO.PARAM_OP_COUNT);
                  })
                  CharDTO.setFormValue('resistances', resistancesValue, data, setValue);


                // ****************
                // END Removing old sophont, if aany
              }

                if (!data.age) {
                  let ageValue = SophontDTO.getNamedPropValueAsNumber(PROP_STARTING_AGE, newSophont);
                  CharDTO.setFormValue('age', ageValue, data, setValue);
                }

                let lifespanValue = SophontDTO.getNamedPropValueAsNumber(PROP_LIFESPAN, newSophont);
                CharDTO.setFormValue('lifespan', lifespanValue, data, setValue);

                let speedValue = SophontDTO.getNamedPropValueAsNumber(PROP_SPEED, newSophont);
                CharDTO.setFormValue('speed', speedValue, data, setValue);

                let movesValue = SophontDTO.getNamedPropValueAsNumber(PROP_MOVES, newSophont);
                CharDTO.setFormValue('moves', movesValue, data, setValue);

                let skillAwards = SophontDTO.makeSkillScoreAwards(newSophont, allSkills, level);
                CharDTO.setFormValue('skillScoreAwards', skillAwards, data, setValue);
                  
                skillAwards.forEach(sa => {
                  CharDTO.applySkillAwardsForSkill(data, sa.skill.name);
                })
                CharDTO.setFormValue('strength', data.strength, data, setValue);
                CharDTO.setFormValue('dexterity', data.dexterity, data, setValue);
                CharDTO.setFormValue('constitution', data.constitution, data, setValue);
                CharDTO.setFormValue('intelligence', data.intelligence, data, setValue);
                CharDTO.setFormValue('wisdom', data.wisdom, data, setValue);
                CharDTO.setFormValue('charisma', data.charisma, data, setValue);
                CharDTO.setFormValue('perception', data.perception, data, setValue);
                CharDTO.setFormValue('fortes', data.fortes, data, setValue);
                CharDTO.setFormValue('fineFortes', data.fineFortes, data, setValue);

                // adaptions
                let adaptionsProp = SophontDTO.getNamedPropValueAsPropRefs(PROP_ADAPTIONS, newSophont);
                let adaptionsValue = data.adaptions;
                adaptionsProp.forEach(a => {
                  adaptionsValue = CountSpecRefDTO.addToArray(data.adaptions as CountSpecRef[], CountSpecRefDTO.makeWithNameAndCount(a.unit as string,a.num as number), CountSpecRefDTO.PARAM_OP_COUNT);
                })
                CharDTO.setFormValue('adaptions', adaptionsValue, data, setValue);

                // resistances
                let resistancesProp = SophontDTO.getNamedPropValueAsPropRefs(PROP_RESISTANCES, newSophont);
                let resistancesValue = data.resistances;
                resistancesProp.forEach(r => {
                  resistancesValue = CountSpecRefDTO.addToArray(data.resistances as CountSpecRef[], CountSpecRefDTO.makeWithNameAndCount(r.unit as string,r.num as number), CountSpecRefDTO.PARAM_OP_COUNT);
                })
                CharDTO.setFormValue('resistances', resistancesValue, data, setValue);
                
                // now, deal with the onChange for *this* form


                onChange({ name: newValue, ref: 'sophont' });

              }
              function setInitialValue(value: SpecRef | undefined) {
                return value ? value.name : '';
              }

              return (
                <RadioGroup {...field}>
                  {sophonts.map((option) => option.name !== 'cephene,singleton' && option.name !== 'ronite' && option.name !== 'thot' && option.name !== 'xanji,xan' && (

                    <Accordion key={option.name}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <FormControlLabel
                          value={setInitialValue(value)}
                          control={<Radio />}
                          label={sentenceToCapFirst(option.name)}
                          key={option.name}
                          checked={value && value.name === option.name}
                          onChange={() => handleChange(value && value.name, option.name)}
                        />

                      </AccordionSummary>
                      <AccordionDetails>

                        <div className='spaced-out-man'>{sentenceToCapFirst(option.description)}</div>
                        <div className='begridded'>
                          {option.props.map((prop) => (
                            <div key={prop.name}><i>{camelCaseToCapWords(prop.name)}</i>: <span>{prop.val}</span></div>
                          ))}

                        </div>

                      </AccordionDetails>
                    </Accordion>

                  ))}
                </RadioGroup>
              )
            }}
          />

        </div>

        {/* hidden fields for char data update: note that we need to set the smallest breakpoint xs: to 'none'
        as opposed to the mui documentation which erroneously suggests: display: { xl: 'none', xs: 'block' }
        Napoleon Dynamite says: 'idiots' */}


        {/* <Controller name="tallies" control={control} render={({ field: { value, ...field } }) => {
          return (<TextField {...field} sx={{ display: { xs: 'none' } }} />)
        }} /> */}

        <button
          type='submit'
          className='btn btn-block btn-danger'
        >
          Submit
        </button>
      </form>
    </ThemeProvider>

  )
};
