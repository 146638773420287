import { ThemeOptions, createTheme } from '@mui/material/styles';



export const strikerTheme: ThemeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#5a3d2b',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#ffecb4',
      paper: '#ffecb4',
    },
    text: {
      primary: 'rgba(39,27,19,0.9)',
      secondary: 'rgba(39,27,19,0.6)',
      disabled: 'rgba(39,27,19,0.2)',
    },
    error: {
      main: '#dd5d48',
    },
    warning: {
      main: '#e5771e',
    },
    info: {
      main: '#75c8ae',
    },
    success: {
      main: '#abbd3d',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Big Shoulders Display',
    },
    fontFamily: 'Finlandica',
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'inherit',
      },
      styleOverrides: {
        root: {
          backgroundColor: '#5A3D2B',
          color: '#fff',
        }
      }
    },
    MuiList: {
      defaultProps: {
        dense: true,
      }
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      }
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      }
    },
    MuiTab: {
      styleOverrides: {
        root:{
          color: '#E5771E',
          '&.Mui-selected': {
            backgroundColor: '#5a3d2b',
            color: '#fff',
            borderRadius: '25px'
          }
        }
    }
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      }
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
      }
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      }
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      }
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      }
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      }
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      }
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
      }
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      }
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      }
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      }
    },
  },
  spacing: 8,
});