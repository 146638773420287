import { Type } from "class-transformer";
import { Prop, Spec } from ".";

export class Vocation extends Spec {

  /**
   * name - the name of the Vocation
   */

  /**
   * description - the description of the Vocation
   */


  /**
   * 
   */
  @Type(() => Prop)
  props: Prop[];


  constructor(
    name: string,
    description: string,
    props: Prop[]

  ) {
    super(name, description);
    this.props = props;
   }
}