export class NumRecord {
  k:string
  v:number
  constructor(
    k: string,
    v: number,
    
  ){
    this.k = k;
    this.v = v;
   }
}