import { plainToInstance } from "class-transformer";
import { PropRef } from "./PropDTO";
import { Background, CountSpecRef, SkillDef, SkillScoreAward, SpecRef, PropDTO, CountSpecRefDTO } from ".";
import { getSpec, TYPE_BACKGROUND, getSpecsByType } from "../../../shared/specSlice";

export const PROP_IS_FIRST = "is-first";
export const PROP_IS_TAKEN_ONCE = "is-taken-once";
export const PROP_FORTE_RANKS = "forteRanks";
export const PROP_FINE_FORTE_RANKS = "fineForteRanks";
export const PROP_MONEY = "money";

export class BackgroundDTO {

  public static asSpecRef = (b: Background): SpecRef => {
    return { name: b.name, ref: 'background' }
  }

  /**
   * Finds the Background corresponding to the named reference in the (spec) state,
   * and returns it as a fully defined Background.
   * @param name the named reference to the Background
   * @returns the Background corresponding to the named reference
   */
  public static findInState = (name: string) => {
    let specsObj = getSpec(TYPE_BACKGROUND, name);
    console.log('BackgroundtDTO.findInState')
    console.log(specsObj) // includes 'at':, 'data':
    return plainToInstance(Background, specsObj.data)
  }

  public static findAllInState = (keys: string[]): Background[] => {
    let all = getSpecsByType(TYPE_BACKGROUND, keys);

    let ret: Background[] = new Array<Background>;
    if (all.length > 0) {
      all.map(se => {
        Object.entries(se).map(e => {
          if (e[0] === 'data') {
            ret.push(plainToInstance(Background, e[1] as Background));
          }
        })
      })

    }
    return ret;
  }

  /**
   * Returns the string value of a prop, corresponding to the provided name for the provided Background
   * 
   * Note that the value can be in the form of a comma-separated array of values.
   * @param name 
   * @param s 
   * @returns the string value.
   */
  public static getNamedPropValue = (name: string, s: Background): string => {
    let valueReturn: string[] = new Array<string>();

    let prop = s.props.filter(p => p.name === name);
    if (prop && prop.length > 0) valueReturn = prop.map(p => p.val);
    console.log(valueReturn)

    return valueReturn.join(',');
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a string
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsPropRefs = (name: string, s: Background): PropRef[] => {
    let sPropValue = BackgroundDTO.getNamedPropValue(name, s);
    console.log(sPropValue)
    return sPropValue ? PropDTO.asPropRef(sPropValue) : [];
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a string
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsString = (name: string, s: Background): string => {
    let sPropValueObj: PropRef[] = BackgroundDTO.getNamedPropValueAsPropRefs(name, s);
    return PropDTO.asString(sPropValueObj[0]) as string
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a number
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsNumber = (name: string, s: Background): number => {
    let sPropValueObj: PropRef[] = BackgroundDTO.getNamedPropValueAsPropRefs(name, s);
    return PropDTO.asNumber(sPropValueObj[0]) as number
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a string
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsSpecRefs = (name: string, bg: Background): SpecRef[] => {
    let sPropValue = BackgroundDTO.getNamedPropValueAsPropRefs(name, bg);
    return PropDTO.asSpecRefsForQuality(sPropValue) as SpecRef[];
  }

  /**
   * Returns a source entry for a SkillScoreAward based on the supplied background and the term that the background
   * was taken in (i.e., 1st, 2nd, 3rd etc.)
   * 
   * "source": {
      "start": 0,
      "offset": 2,
      "count": 0,
      "spec": {
        "name": "bg1",
        "ref": "background"
      }
    }
   * 
   * @param bg 
   * @param term 
   */
  public static asSkillAwardSource = (bg: Background, term: number): CountSpecRef => {
    return {start: 0, offset: term, count: 0, spec: {name: bg.name, ref: 'background'}}
  }

  public static makeSkillScoreAwards = (bg: Background, allSkills: SkillDef[], term: number): SkillScoreAward[] => {
    let ret = new Array<SkillScoreAward>();

    let forteRanks: PropRef[] = BackgroundDTO.getNamedPropValueAsPropRefs(PROP_FORTE_RANKS, bg);
    forteRanks.forEach(m => {
      ret = [...ret, ...PropDTO.asSkillScoreAward(m, BackgroundDTO.asSkillAwardSource(bg, term), allSkills, CountSpecRefDTO.PARAM_OP_COUNT)];
    })
    let fineForteRanks: PropRef[] = BackgroundDTO.getNamedPropValueAsPropRefs(PROP_FINE_FORTE_RANKS, bg);
    fineForteRanks.forEach(m => {
      ret = [...ret, ...PropDTO.asSkillScoreAward(m, BackgroundDTO.asSkillAwardSource(bg, term), allSkills, CountSpecRefDTO.PARAM_OP_COUNT)];
    })

    return ret
  }

  
  public static unMakeSkillScoreAwards = (bg: Background, allSkills: SkillDef[], term: number): SkillScoreAward[] => {
    let ret = new Array<SkillScoreAward>();

    let forteRanks: PropRef[] = BackgroundDTO.getNamedPropValueAsPropRefs(PROP_FORTE_RANKS, bg);
    forteRanks.forEach(m => {
      ret = [...ret, ...PropDTO.asReversingSkillScoreAward(m, BackgroundDTO.asSkillAwardSource(bg, term), allSkills, CountSpecRefDTO.PARAM_OP_COUNT)];
    })
    let fineForteRanks: PropRef[] = BackgroundDTO.getNamedPropValueAsPropRefs(PROP_FINE_FORTE_RANKS, bg);
    fineForteRanks.forEach(m => {
      ret = [...ret, ...PropDTO.asReversingSkillScoreAward(m, BackgroundDTO.asSkillAwardSource(bg, term), allSkills, CountSpecRefDTO.PARAM_OP_COUNT)];
    })

    return ret
  }

  
}