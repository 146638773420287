
export class SpecRef {

  /**
   * The name can refer to s sinlge item or a comma-separated list of names as long as they are all of the same ref.
   */
  name: string
  ref: string;
  
  constructor(
    name: string, 
    ref: string
  ) {
    this.name = name
    this.ref = ref;
  }
}
