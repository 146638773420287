import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { strikerTheme } from "../../../shared/components/StrikerTheme";
import { Quality, SpecRef, Char } from "../../models/specs";
import { Ref, forwardRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { UseControllerProps, useWatch } from "react-hook-form";


export const CheckboxGroup = forwardRef(({
  control,
	label,
  values,
  options,
  onChange,

}: {
  label: string,
  values: SpecRef[],
  options: Quality[]
  onChange: any

} & UseControllerProps<Partial<Char>>, ref:Ref<HTMLInputElement>) => {
	const char = useWatch({ control }) as Char;



	const handleChange = (value: SpecRef) => {
		const newArray = [ ...values ];
		const item = value;

		//Ensure array isnt empty
		if (newArray.length > 0) {
			//Attempt to find an item in array with matching id
			const index = newArray.findIndex(x => x === item);

			// If theres no match add item to the array
			if (index === -1) {
				newArray.push(item);
			} else {
				//If there is a match and the value is empty, remove the item from the array
				newArray.splice(index, 1);
			}
		} else {
			//If the array is empty, add the item to the array
			newArray.push(item);
		}

		//Overwrite existing array with newArray}
		onChange(newArray);
	}

  function setInitialValue(value: SpecRef | undefined): string {
    console.log('setting intial value...')
    console.log(value)
    let val = value ? value.name : '';
    return val;
  }

	return (
    <ThemeProvider theme={strikerTheme}>
				{/* <FormGroup> */}
          <h5>{label}</h5>
          <div className='container tight-container'>
					{options.map((option) => (
            <Tooltip title={option.description} key={option.name}>
						<FormControlLabel
							control={
								<Checkbox
									checked={(values)
										?.some((checked: SpecRef) => checked.name === option.name)}
									inputRef={ref}
									onChange={() => handleChange({name: option.name, ref: 'quality'})}
								/>}
							label={option.name}
							key={option.name}
						/>
            </Tooltip>

					))}
				{/* </FormGroup> */}
        </div>
		</ThemeProvider>
	);
});
