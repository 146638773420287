import { Type } from "class-transformer";
import { SpecRef } from ".";
import { Prop } from "./Prop";
import { Spec } from "./Spec";

export class Enhancement extends Spec {

  /**
   * name - the name of the enhancement
   */

  /**
   * a short description of the enhancement
   */

  /**
   * the technology that underlies the enhancement: so far this is either cyberware or geneware
   */
  technology: string;

  @Type(() => Prop)
  props: Prop[];

  /**
   * lists the weapon, if any, on the enhancement
   */
  @Type(() => SpecRef)
  weapons: SpecRef[];


  constructor(
    name: string,
    description: string,
    technology: string,
    props: Prop[],
    weapons: SpecRef[]
    ) {
      super(name, description);
      this.technology = technology;
      this.props = props;
      this.weapons = weapons;
    }
}
