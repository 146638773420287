import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Blog, BlogMenuState, BlogSearchState, BlogPageState } from './models';
import { WritableDraft } from 'immer/dist/internal';
import { findUserToken } from '../auth/authSlice';


export const pageSize = 4;

export interface BlogState {
    readonly tags: string[],
    readonly id: string,
    readonly isPublished: boolean,
    readonly author: string,
    readonly created: string,
    readonly updated: string,
    readonly title: string,
    readonly subtitle: string,
    readonly body: string
}
export interface PageState {
    readonly pageCount: number,
    readonly page: number
}

export interface BlogsYear {
    readonly year: string,
    readonly months: BlogsMonth[]
}
export interface BlogsMonth {
    readonly month: string,
    readonly blogs: BlogState[]
}

export interface BlogMenuItem {
  readonly year: string
  readonly month: string
  readonly id: string
  readonly title: string
}

export interface BlogsSliceState {
    readonly blog: BlogState,
    readonly blogs: BlogState[],
    readonly tags: string[],
    readonly menuState: {
        readonly blogyearOpenIndex: string,
        readonly blogyearSelectedIndex: string,
        readonly blogmonthOpenIndex: string,
        readonly blogmonthSelectedIndex: string,
        readonly blogSelectedIndex: string  
    },
    readonly searchState: {
        readonly hasTag: string,
        readonly likeTitle: string
    },
    readonly pageState: PageState,
    readonly blogDisplayMode: string,
    readonly isLoading: boolean,
    readonly errorMessage: string,
    readonly menuBlogs: Array<BlogsYear>,
    readonly fileUploadProgress: number;

}

const initialState: BlogsSliceState = {} as BlogsSliceState;

const doMenuTheBlogs = (blogs: WritableDraft<BlogState>[]): Array<BlogsYear> => {
    const menuBlogs = new Array<BlogsYear>;
    let currentYear: BlogsYear;
    let currentMonth: BlogsMonth;
    let i = 0;
    if (menuBlogs.length === 0) {
        blogs?.map(blog => {
            i++;
            if (blog.created && blog.title && blog.id) {

                const yearAndMonth = BlogMenuState.getDateStringArray(blog.created)

                const year = yearAndMonth[0];
                const month = yearAndMonth[1];
                //console.log(`add year: ${currentYear && currentYear.year}, month: ${currentMonth && currentMonth.month}, blog year: ${year}`)

                if (!currentYear && !currentMonth) {
                    currentMonth = {month: month, blogs: [blog]} as WritableDraft<BlogsMonth>;
                    currentYear = {year: year, months: [currentMonth]} as WritableDraft<BlogsYear>;
                    return; // this is like continue in a regular for-loop
                }

                if (currentYear.year !== year) {
                    // changing years, therefore save what we have
                    menuBlogs.push(currentYear);
                    // reset the current year and month with this iteration's values
                    currentMonth = {month: month, blogs: [blog]} as WritableDraft<BlogsMonth>;
                    currentYear = {year: year, months: [currentMonth]} as WritableDraft<BlogsYear>;
                } else {
                    if (!currentMonth || currentMonth.month !== month) {
                        // not changing year, but changing months
                        currentMonth = {month: month, blogs: [blog]} as WritableDraft<BlogsMonth>;
                        currentYear.months.push(currentMonth);
                    } else {
                        // or not changing year and not changing months
                        currentMonth.blogs.push(blog);
                    }
                }

                if (i === blogs.length) {
                    // we are at the end of the list of blogs, therefore 
                    // save what we have
                    menuBlogs.push(currentYear);
                }

            }
        });
    }
    return menuBlogs;
}

export const fetchBlogs = createAsyncThunk<Blog[], void, { readonly rejectValue: { readonly errorMessage: string } }>(
    'blogs/fetch',
    async (arg, thunkAPI) => {
        return await fetch(
            '/api/blogs/',
            {
                method: 'GET',
                ...(findUserToken() ? {headers: { Authorization: `Basic ${findUserToken() as string}`}} : {})
            })

            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(`Unexpected response from server (code ${response.status}).`);
                }
            })

            .catch(function (error) {
                console.error(error);
                return thunkAPI.rejectWithValue({ errorMessage: error.message });
            });
    }
);

export const fetchBlog = createAsyncThunk<Blog, string, { readonly rejectValue: { readonly errorMessage: string } }>(
    'blog/fetch',
    async (arg, thunkAPI) => {
        return await fetch(
            `/api/blogs/${arg}`,
            {
                method: 'GET'
            })

            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(`Unexpected response from server (code ${response.status}).`);
                }
            })

            .catch(function (error) {
                console.error(error);
                return thunkAPI.rejectWithValue({ errorMessage: error.message });
            });
    }
);

export const fetchBlogTags = createAsyncThunk<string[], void, { readonly rejectValue: { readonly errorMessage: string } }>(
    'blogs/tags/fetch',
    async (arg, thunkAPI) => {
        return await fetch(
            '/api/blogs/tags/',
            {
                method: 'GET'
            })

            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(`Unexpected response from server (code ${response.status}).`);
                }
            })

            .catch(function (error) {
                console.error(error);
                return thunkAPI.rejectWithValue({ errorMessage: error.message });
            });
    }
);

export const createBlog = createAsyncThunk<Blog, Blog, { rejectValue: { readonly errorMessage: string, readonly blog: Blog } }>(
    'blogs/create',

    async (arg, thunkAPI) => {
        arg.created = new Date().toISOString();
        arg.updated = new Date().toISOString();

        return await fetch(
            '/api/blogs/',
            {
                method: 'POST',
                body: JSON.stringify(arg),
                ...(findUserToken() ? {headers: { Authorization: `Basic ${findUserToken() as string}`}} : {})
            })

            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(`Unexpected response from server (code ${response.status}).`);
                }
            })

            .catch(function (error) {
                console.error(error);
                return thunkAPI.rejectWithValue({ errorMessage: error.message, blog: arg });
            });
    }
);

export const editBlog = createAsyncThunk<Blog, Blog, { rejectValue: { readonly errorMessage: string, readonly blog: Blog } }>(
    'blogs/edit',

    async (arg, thunkAPI) => {
        arg.updated = new Date().toISOString();

        return await fetch(
            '/api/blogs/',
            {
                method: 'PATCH',
                body: JSON.stringify(arg),
                ...(findUserToken() ? {headers: { Authorization: `Basic ${findUserToken() as string}`}} : {})
            })

            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(`Unexpected response from server (code ${response.status}).`);
                }
            })

            .catch(function (error) {
                console.error(error);
                return thunkAPI.rejectWithValue({ errorMessage: error.message, blog: arg });
            });
    }
);

export const deleteBlog = createAsyncThunk<string, string, { rejectValue: { readonly errorMessage: string } }>(
    'blogs/delete',

    async (arg, thunkAPI) => {

        return await fetch(
            `/api/blogs/${arg}`,
            {
                method: 'DELETE',
                body: JSON.stringify(arg),
                ...(findUserToken() ? {headers: { Authorization: `Basic ${findUserToken() as string}`}} : {})
            })

            .then(response => {
                if (response.status === 200) {
                    return arg;
                } else {
                    throw new Error(`Unexpected response from server (code ${response.status}).`);
                }
            })

            .catch(function (error) {
                console.error(error);
                return thunkAPI.rejectWithValue({ errorMessage: error.message });
            });
    }
);

export const blogsSlice = createSlice({
    name: 'blogstate', // not sure what this 'name' is for. For the moment, I'll just call it the same as the reference in store.ts
    initialState,
    reducers: {
        reset: () => initialState,
        setBlogyearOpenIndex: (state, action) => { state.menuState.blogyearOpenIndex = action.payload  },
        setBlogyearSelectedIndex: (state, action) => { state.menuState.blogyearSelectedIndex = action.payload },
        setBlogmonthOpenIndex: (state, action) => { state.menuState.blogmonthOpenIndex = action.payload },
        setBlogmonthSelectedIndex: (state, action) => { state.menuState.blogmonthSelectedIndex = action.payload },
        setBlogSelectedIndex: (state, action) => { state.menuState.blogSelectedIndex = action.payload },

        // probably need to get rid of this, unfortunately we're in a bit of a bind:
        // setIsLoading(false) is best done as the last action in the reducer, but Loading is a appState property
        // and the Gods of react just can't abide reducers looking at the state of other reducers (that's heresy!)
        setLoading: (state, action) => { state.isLoading = action.payload },
        filterBlogs: (state, action) => {
            if (!state.blogs) {
                state.errorMessage = 'blogs have not been fetched, nothing to filter';
            } else {
                let ms = action.payload as WritableDraft<BlogSearchState>
                if (ms.hasTag || ms.likeTitle) {
                    const copy = [ ...state.blogs ].map(item=>({...item}))
                    state.blogs = copy.filter(b => b.tags?.includes(ms.hasTag) && b.title?.includes(ms.likeTitle)) as [WritableDraft<Blog>];
                }
            }
        },
        changePage: (state, action) => { state.pageState.page = action.payload },
        prevPage: (state, action) => {
            if (!state.pageState) {
                state.pageState = {pageCount: 0, page: 1};
            } else {
                let newPage = state.pageState.page - 1
                if (newPage < 1) {
                    newPage = state.pageState.pageCount;
                }
                state.pageState.page = newPage;
            }        
        },
        nextPage: (state, action) => {
            if (!state.pageState) {
                state.pageState = {pageCount: 0, page: 1};
            } else {
                let newPage = state.pageState.page - 1
                if (newPage < state.pageState.pageCount) {
                    newPage = 1;
                }
                state.pageState.page = newPage;
            }        
        },
        setBlogDisplayMode: (state, action) => {
            state.blogDisplayMode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlogs.fulfilled, (state, action) => {
                // for some reason, react has suddenly decided that action.payload is read only
                // so, fuck it, a solved the arbitrary error with an arbitrary copy.
                const copyOfActionPayload = structuredClone(action.payload)
                let sortedBlogs = copyOfActionPayload.sort((a,b)=>new Date(b.created).getTime()-new Date(a.created).getTime());

                state.blogs = sortedBlogs;
                state.pageState = { pageCount: Math.ceil(copyOfActionPayload.length / pageSize), page: 1 }
                state.menuBlogs = doMenuTheBlogs(sortedBlogs);
                const yearAndMonth = BlogMenuState.getDateStringArray(sortedBlogs[0].created)

                state.menuState = {
                    blogyearOpenIndex: yearAndMonth[0], 
                    blogyearSelectedIndex: yearAndMonth[0], 
                    blogmonthOpenIndex: yearAndMonth[1], 
                    blogmonthSelectedIndex: yearAndMonth[1], 
                    blogSelectedIndex: '' }
                
                state.isLoading = false;
            })
            .addCase(fetchBlogs.rejected, (state, action) => {
                if (action.payload !== undefined) {
                    state.errorMessage = action.payload.errorMessage;
                }
                state.isLoading = false;
            })
            .addCase(fetchBlog.fulfilled, (state, action) => {

                state.blog = action.payload;
                
                state.isLoading = false;
            })
            .addCase(fetchBlog.rejected, (state, action) => {
                if (action.payload !== undefined) {
                    state.errorMessage = action.payload.errorMessage;
                }
                state.isLoading = false;
            })
            .addCase(fetchBlogTags.fulfilled, (state, action) => {
                console.log(JSON.stringify(action.payload))

                if (action.payload && action.payload.length > 0) {
                    state.tags = action.payload;
                }
                state.isLoading = false;
            })
            .addCase(fetchBlogTags.rejected, (state, action) => {
                if (action.payload !== undefined) {
                    state.errorMessage = action.payload.errorMessage;
                }
                state.isLoading = false;
            })
            .addCase(createBlog.fulfilled, (state, action) => {
                state.blog = action.payload;

                state.errorMessage = '';
                state.isLoading = false;
            })
            .addCase(createBlog.rejected, (state, action) => {
                console.log(action.payload?.errorMessage)
                if (action.payload !== undefined) {
                    state.errorMessage = action.payload.errorMessage;
                }
                state.isLoading = false;
            })
            .addCase(editBlog.fulfilled, (state, action) => {

                state.blog = action.payload;
                state.errorMessage = '';
                state.isLoading = false;
            })
            .addCase(editBlog.rejected, (state, action) => {
                console.log(action.payload?.errorMessage)
                if (action.payload !== undefined) {
                    state.errorMessage = action.payload.errorMessage;
                }
                state.isLoading = false;
            })
            .addCase(deleteBlog.fulfilled, (state, action) => {
                state.blog = Object.apply(Blog);
                state.errorMessage = '';
                state.isLoading = false;
            })
            .addCase(deleteBlog.rejected, (state, action) => {
                if (action.payload !== undefined) {
                    state.errorMessage = action.payload.errorMessage;
                }
                state.isLoading = false;
            })
            ;
    },
});

export const {
    setBlogyearOpenIndex,
    setBlogyearSelectedIndex,
    setBlogmonthOpenIndex,
    setBlogmonthSelectedIndex,
    setBlogSelectedIndex, 
    setLoading, 
    filterBlogs, 
    changePage, 
    prevPage, 
    nextPage
} = blogsSlice.actions

export default blogsSlice.reducer;
