import { CountSpecRef, SkillDefDTO, SpecRefDTO } from ".";
import _ from "lodash"


export class CountSpecRefDTO {

  
  static readonly PARAM_OP_START: string = "START"
  static readonly PARAM_OP_OFFSET = "OFFSET"
  static readonly PARAM_OP_COUNT = "COUNT"
  static readonly PARAM_OP_ALL = "ALL"
  
  static readonly CHAR_INIT_SSA_SOURCE: CountSpecRef = new CountSpecRef(0, 0, 0, { name: 'CharInit', ref: 'Game' });
  static readonly SOPHONT_INIT_SSA_SOURCE: CountSpecRef = new CountSpecRef(0, 1, 0, { name: 'Sophont', ref: 'Game' });
  
  static readonly CHAR_INIT_STR: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.STR)
  static readonly CHAR_INIT_DEX: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.DEX)
  static readonly CHAR_INIT_CON: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.CON);
  static readonly CHAR_INIT_INT: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.INT);
  static readonly CHAR_INIT_WIS: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.WIS);
  static readonly CHAR_INIT_CHA: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.CHA);
  static readonly CHAR_INIT_PER: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.PER);
  
  static readonly CHAR_INIT_ADAPT_GRAV: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.ADAPT_GRAV);
  static readonly CHAR_INIT_ADAPT_PRESS: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.ADAPT_PRESS);
  static readonly CHAR_INIT_ADAPT_COLD: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.ADAPT_COLD);
  static readonly CHAR_INIT_ADAPT_HEAT: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.ADAPT_HEAT);
  
  static readonly CHAR_INIT_RESIST_FRC: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.RESIST_FRC);
  static readonly CHAR_INIT_RESIST_PRC: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.RESIST_PRC);
  static readonly CHAR_INIT_RESIST_SLS: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.RESIST_SLS);
  static readonly CHAR_INIT_RESIST_ELEC: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.RESIST_ELEC);
  static readonly CHAR_INIT_RESIST_FIRE: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.RESIST_FIRE);
  static readonly CHAR_INIT_RESIST_COLD: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.RESIST_COLD);
  static readonly CHAR_INIT_RESIST_HEAT: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.RESIST_HEAT);
  static readonly CHAR_INIT_RESIST_CORR: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.RESIST_CORR);
  static readonly CHAR_INIT_RESIST_PSN: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.RESIST_PSN);
  static readonly CHAR_INIT_RESIST_RAD: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.RESIST_RAD);
  
  static readonly CHAR_INIT_STR_SAVE: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.STR_SAVE);
  static readonly CHAR_INIT_DEX_SAVE: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.DEX_SAVE);
  static readonly CHAR_INIT_CON_SAVE: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.CON_SAVE);
  static readonly CHAR_INIT_INT_SAVE: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.INT_SAVE);
  static readonly CHAR_INIT_WIS_SAVE: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.WIS_SAVE);
  static readonly CHAR_INIT_CHA_SAVE: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.CHA_SAVE);
  static readonly CHAR_INIT_PER_SAVE: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.PER_SAVE);
  
  static readonly CHAR_INIT_CON_HEALTH: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.CON_HEALTH);
  static readonly CHAR_INIT_CON_WIS_ENERGY: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.CON_WIS_ENERGY);
  static readonly CHAR_INIT_INT_WIS_CHA_FOCUS: CountSpecRef = new CountSpecRef(0, 0, 0, SpecRefDTO.INT_WIS_CHA_FOCUS);
  

  public static makeWithNameAndCount = (name: string, count: number): CountSpecRef => {
    return { start: 0, offset: 0, count: count, spec: { name: name } } as CountSpecRef
  }

  public static makeWithRefNameAndCount = (name: string, ref: string, count: number): CountSpecRef => {
    return { start: 0, offset: 0, count: count, spec: { name: name, ref: ref } } as CountSpecRef
  }

  /**
   * Adds the supplied CountSpecRef element to the supplied CountSpecRef array.
   * The add operation will either:
   * <ol>
   * <li>add the CountSpecRef element (if that element does not already exist); or</li>
   * <li>add the number entries to the CountSpecRef element of the same spec name</li> 
   * </ol>
   * Every CountSpecRef element (both from the existing array elements and new element) must share the same
   * (csr).spec.ref value. If there are any inconsistencies, this method will return the 
   * supplied CountSpecRef array unchanged.
   * @param arr the supplied CountSpecRef array (all with the same (csr).spec.ref value)
   * @param e the supplied CountSpecRef element to be added (with the same (csr).spec.ref value as the supplied CountSpecRef array)
   * @param op the kind of adding operation (whether we are adding the starts, the offsets, the counts, or all three)
   */
  public static addToArray = (arr: CountSpecRef[], e: CountSpecRef, op: string): CountSpecRef[] => {
    // return arr if any element does not match on ref where ref is defined (ref inconsistency)
    if (arr.filter(a => (a.spec.ref && e.spec.ref) && a.spec.ref !== e.spec.ref).length > 0) return arr;

    // return arr if for any element the ref is defined in the array and not in the element or vice versa (ref existence inconsistency)
    if (arr.filter(a => (!a.spec.ref && e.spec.ref) || (a.spec.ref && !e.spec.ref)).length > 0) return arr;

    // return arr if for any element the name includes a comma in the array and not in the element or vice versa (paired name existence inconsistency)
    if (arr.filter(a => (!a.spec.name.includes(',') && e.spec.name.includes(',')) || (a.spec.name.includes(',') && !e.spec.name.includes(','))).length > 0) return arr;

    // return arr if for any element the name part before the comma does not match, where commas existe in both (paired name inconsistency)
    if (arr.filter(a => a.spec.name.includes(',') && e.spec.name.includes(',') && !a.spec.name.includes(e.spec.name.substring(0, e.spec.name.indexOf(',')))).length > 0) return arr;

    // if there is no inconsistency and the the element does not exist, add it in
    if (arr.filter(a => a.spec.name === e.spec.name).length === 0) return [...arr, CountSpecRefDTO.makeWithNameAndCount(e.spec.name, e.count)];

    let matched: CountSpecRef = arr.filter(a => a.spec.name === e.spec.name).at(0) as CountSpecRef;
    if (op === CountSpecRefDTO.PARAM_OP_START) {
      matched = new CountSpecRef(matched.start + e.start, matched.offset, matched.count, matched.spec)
    } else if (op === CountSpecRefDTO.PARAM_OP_OFFSET) {
      matched = CountSpecRefDTO.addOffset(matched, e.offset)
    } else if (op === CountSpecRefDTO.PARAM_OP_COUNT) {
      matched = CountSpecRefDTO.addCount(matched, e.count)
    } else {
      matched = new CountSpecRef(matched.start + e.start, matched.offset + e.offset, matched.count + e.count, matched.spec)
    }
    let ret: CountSpecRef[] = arr.filter(a => a.spec.name !== e.spec.name) as CountSpecRef[];
    return [...ret, matched];
  }

  /**
   * Removes the supplied CountSpecRef element from the supplied CountSpecRef array.
   * The subtract operation will either:
   * <ol>
   * <li>remove the CountSpecRef element (if there is an exact match in the supplied CountSpecRef array); or</li>
   * <li>subtract the number entries to the CountSpecRef element of the same spec name</li> 
   * </ol>
   * Every CountSpecRef element (both from the existing array elements and new element) must share the same
   * (csr).spec.ref value. If there are any inconsistencies, this method will return the 
   * supplied CountSpecRef array unchanged.
   * @param arr the supplied CountSpecRef array (all with the same (csr).spec.ref value)
   * @param e the supplied CountSpecRef element to be subtracted (with the same (csr).spec.ref value as the supplied CountSpecRef array)
   * @param op the kind of subtraction operation (whether we are subtracting the starts, the offsets, the counts, or all three)
   */
  public static subtractFromArray = (arr: CountSpecRef[], e: CountSpecRef, op: string): CountSpecRef[] => {
    // return arr if any element does not match on ref where ref is defined (ref inconsistency)
    if (arr.filter(a => (a.spec.ref && e.spec.ref) && a.spec.ref !== e.spec.ref).length > 0) return arr;

    // return arr if for any element the ref is defined in the array and not in the element or vice versa (ref existence inconsistency)
    if (arr.filter(a => (!a.spec.ref && e.spec.ref) || (a.spec.ref && !e.spec.ref)).length > 0) return arr;

    // return arr if for any element the name includes a comma in the array and not in the element or vice versa (paired name exixtence inconsistency)
    if (arr.filter(a => (!a.spec.name.includes(',') && e.spec.name.includes(',')) || (a.spec.name.includes(',') && !e.spec.name.includes(','))).length > 0) return arr;

    // return arr if for any element the name part before the comma does not match, where commas existe in both (paired name inconsistency)
    if (arr.filter(a => a.spec.name.includes(',') && e.spec.name.includes(',') && !a.spec.name.includes(e.spec.name.substring(0, e.spec.name.indexOf(',')))).length > 0) return arr;

    // if there is no inconsistency and there is a total match on the element, remove it
    if (arr.filter(a => a.spec.name === e.spec.name && a.start === e.start && a.offset === e.offset && a.count === e.count).length === 1) return arr.filter(a => a.spec.name !== e.spec.name);

    let matched: CountSpecRef = arr.filter(a => a.spec.name === e.spec.name).at(0) as CountSpecRef;
    if (op === CountSpecRefDTO.PARAM_OP_START) {
      matched = new CountSpecRef(matched.start - e.start, matched.offset, matched.count, matched.spec)
    } else if (op === CountSpecRefDTO.PARAM_OP_OFFSET) {
      matched = CountSpecRefDTO.addOffset(matched, -e.offset)
    } else if (op === CountSpecRefDTO.PARAM_OP_COUNT) {
      matched = CountSpecRefDTO.addCount(matched, -e.count)
    } else {
      matched = new CountSpecRef(matched.start - e.start, matched.offset - e.offset, matched.count - e.count, matched.spec)
    }
    let ret: CountSpecRef[] = arr.filter(a => a.spec.name !== e.spec.name) as CountSpecRef[];
    return [...ret, matched];

  }

  /**
   * Returns a new CountSpecRef with the count set to the supplied value, or leaves the count unchanged if the supplied value is undefined.
   * @param countSpecRef the CountSpecRef upon which the new CountSpecRef is derived
   * @param value the new value for the count
   * @returns the new CountSpecRef
   */
  public static setCount = (countSpecRef: CountSpecRef, value: number): CountSpecRef => {
    return new CountSpecRef(countSpecRef.start, countSpecRef.offset, countSpecRef.count = value ?? value, countSpecRef.spec)
  }

  /**
   * Returns a new CountSpecRef with the offset set to the supplied value, or leaves the offset unchanged if the supplied value is undefined.
   * @param countSpecRef the CountSpecRef upon which the new CountSpecRef is derived
   * @param value the new value for the offset
   * @returns the new CountSpecRef
   */
  public static setOffset = (countSpecRef: CountSpecRef, value: number): CountSpecRef => {
    return new CountSpecRef(countSpecRef.start, countSpecRef.offset = value ?? value, countSpecRef.count, countSpecRef.spec)
  }

  /**
   * Returns a new CountSpecRef with the count set to the supplied value, or leaves the count unchanged if the supplied value is undefined.
   * @param countSpecRef the CountSpecRef upon which the new CountSpecRef is derived
   * @param value the new value for the count
   * @returns the new CountSpecRef
   */
  public static addCount = (countSpecRef: CountSpecRef, value: number): CountSpecRef => {
    return new CountSpecRef(countSpecRef.start, countSpecRef.offset, countSpecRef.count = countSpecRef.count && value ? countSpecRef.count + value : value ?? value, countSpecRef.spec)
  }

  /**
   * Returns a new CountSpecRef with the offset set to the supplied value, or leaves the offset unchanged if the supplied value is undefined.
   * @param countSpecRef the CountSpecRef upon which the new CountSpecRef is derived
   * @param value the new value for the offset
   * @returns the new CountSpecRef
   */
  public static addOffset = (countSpecRef: CountSpecRef, value: number): CountSpecRef => {
    return new CountSpecRef(countSpecRef.start, countSpecRef.offset = countSpecRef.offset && value ? countSpecRef.offset + value : value ?? value, countSpecRef.count, countSpecRef.spec)
  }


  public static addByCount = (a: CountSpecRef, b: CountSpecRef): CountSpecRef | undefined => {
    if (a.spec.name === b.spec.name && a.spec.ref === b.spec.ref) {
      return new CountSpecRef(b.start, b.offset, b.count + a.count, a.spec)
    }
  }
  public static addToCount = (a: CountSpecRef, num: number): CountSpecRef | undefined => {
    return new CountSpecRef(a.start, a.offset, a.count + num, a.spec)
  }

  public static subtractByCount = (a: CountSpecRef, b: CountSpecRef): CountSpecRef | undefined => {
    if (a.spec.name === b.spec.name && a.spec.ref === b.spec.ref) {
      return new CountSpecRef(b.start, b.offset, b.count - a.count, a.spec)
    }
  }

  public static addByOffset = (a: CountSpecRef, b: CountSpecRef): CountSpecRef | undefined => {
    if (a.spec.name === b.spec.name && a.spec.ref === b.spec.ref) {
      return new CountSpecRef(b.start, b.offset + a.offset, b.count, a.spec)
    }
  }
  public static subtractByOffset = (a: CountSpecRef, b: CountSpecRef): CountSpecRef | undefined => {
    if (a.spec.name === b.spec.name && a.spec.ref === b.spec.ref) {
      return new CountSpecRef(b.start, b.offset - a.offset, b.count, a.spec)
    }
  }
  public static findBySpecName(arr: CountSpecRef[], specName: string): CountSpecRef | undefined {
    let retarr: CountSpecRef[] = arr.filter(a => a.spec.name === specName)
    if (retarr && retarr.length > 0) return retarr.at(0) as CountSpecRef
    return undefined
  }

  /**
   * Adds or replaces an item to an array of CountSpecRef following a few rules. 
   * <ol>
   * <li>the existing elements, must all be of the same spec.ref (as in x.spec.name can have different values, 
   *    but x.spec.ref must be mutually equal)</li>
   * <li>the new element must either match an existing spec (so that its count is replaced on an existing item), 
   *    or it must match at least on spec.ref (so that it can be a new element)</li>
   * <li>only one existing element can have the blank name reference (x.spec.name === ''). 
   *    Such an element can be added by calling this method with a CountSpecRef with x.spec.name === '', however 
   *    adding any element with a filled name, replaces the element with the blank name reference</li>
   * </ol>
   * @param arr 
   * @param a 
   */
  public static replaceItemOnCount = (arr: CountSpecRef[], a: CountSpecRef): CountSpecRef[] => {
    if (arr.length !== 0) {
      if (!arr.every(csr => csr.spec.ref === a.spec.ref)) return arr;
      if (a.spec.name === '' && arr.filter(csr => csr.spec.name === '').length > 0) return arr;
    }
    if (a.spec.name === '') return [...arr, a];
    else if (arr.filter(csr => csr.spec.name === a.spec.name).length === 0) {
      return [...arr.filter(csr => csr.spec.name !== ''), a];
    } else {
      let matchedCsr: CountSpecRef = arr.filter(csr => csr.spec.name === a.spec.name).at(0) as CountSpecRef;
      return [...arr.filter(csr => csr.spec.name !== a.spec.name && csr.spec.name !== ''), { ...matchedCsr, count: a.count }];
    }
  }

  /**
   * Adds or replaces an item to an array of CountSpecRef based on the spec.name and following a few rules. 
   * <ol>
   * <li>the existing elements, must all be of the same spec.ref (as in x.spec.name can have different values, 
   *    but x.spec.ref must be mutually equal)</li>
   * <li>the fromName must either match an existing spec.name (so that the name is replaced on that item), 
   *    or there must be a blank, we can replace the x.spec.name (so that it can be effectively a new element)</li>
   * <li>only one existing element can have the blank name reference (x.spec.name === '').  
   *    attempting to change any name to '' will result in the return of the original array (no change)</li>
   * </ol>
   * @param arr 
   * @param a 
   */
  public static replaceItemOnName = (arr: CountSpecRef[], fromName: string, toName: string): CountSpecRef[] => {
    if (arr.length === 0) return arr;
    else {
      if (!arr.every(csr => csr.spec.ref === arr.at(0)?.spec.ref)) return arr;
      if (toName === '') return arr;
      let from = arr.filter(csr => csr.spec.name === fromName)
      if (from.length > 0) {
        return [...arr.filter(csr => csr.spec.name !== fromName), { ...from[0], spec: { name: toName, ref: from[0].spec.ref } } as CountSpecRef];
      } else {
        // find the blank
        let blank = arr.filter(csr => csr.spec.name === '')
        if (blank.length > 0) {
          return [...arr.filter(csr => csr.spec.name !== ''), { ...blank[0], spec: { name: toName, ref: blank[0].spec.ref } } as CountSpecRef];
        } else {
          // there is nothing we can replace
          return arr;
        }
      }
    }
  }

  /**
   * Returns a map representation of the skill in terms of its bases
   * If the skill is Str/Dex:Cbt-Melee:Wpn-Poles
   * then this method would return the map
   * {
   *   ABILITY: Str/Dex
   *   FORTE: Cbt-Melee
   *   FINE-FORTE: Wpn-Poles
   * }
   * 
   * @param skillName The name of the skill in reference format, such as Int/Wis:Medicine:Conjugate
   * @returns 
   */
  public static readSkillBases = (skillName: string): Map<string, string> => {
    const ret = new Map<string, string>();
    let firstSplit = skillName.split(':');
    if (firstSplit.length === 1) {
      ret.set(SkillDefDTO.ECHELON_ABILITY, firstSplit[0])
    } else if (firstSplit.length === 2) {
      ret.set(SkillDefDTO.ECHELON_ABILITY, firstSplit[0]);
      ret.set(SkillDefDTO.ECHELON_FORTE, firstSplit[1])
    } else if (firstSplit.length === 3) {
      ret.set(SkillDefDTO.ECHELON_ABILITY, firstSplit[0]);
      ret.set(SkillDefDTO.ECHELON_FORTE, firstSplit[1])
      ret.set(SkillDefDTO.ECHELON_FINE_FORTE, firstSplit[2])
    }
    return ret;
  }

  /**
   * Returns a map with a based modifier for all of the pathways in a skill reference.
   * in a reference like Str/Dex:Cbt-Melee:Wpn-Poles
   * we return 2 / 3 : 1 : 1, so the toal based modifier is either 3 or 4
   * the returned map should then read:
   * 
   * {
   *   Str, 2
   *   Dex, 3
   *   Str/Dex:Cbt-Melee, 1
   *   Str/Dex:Cbt-Melee:Wpn-Poles, 1
   * }
   *
   * That is there are two possible pathways to a based modifier, the map represents both.
   * Note that leafSkill.spec.name will be Str/Dex:Cbt-Melee:Wpn-Poles giving us a guide on where to get the bases
   * @param char 
   * @param leafSkill CountSpecRef for the leaf node, so Wpn:Poles in this example
   */
  public static readBMs = (skill: CountSpecRef, bases: CountSpecRef[]): Map<string, number> => {
    const ret: Map<string, number> = new Map<string, number>();

    if (!skill) console.log('invalid input: no [skill] entry')
    if (!skill) return ret;

    const baseStrings: Map<string, string> = CountSpecRefDTO.readSkillBases(skill.spec.name);
    /*
      Map(3) {
        'ABILITY' => 'Str/Dex',
        'FORTE' => 'Cbt-Melee',
        'FINE_FORTE' => 'Wpn-Poles'
      }
    */


    if (baseStrings.size > 1) {
      // this is a forte, or fine forte
      // look after the abilities
      let abilityRef = baseStrings.get(SkillDefDTO.ECHELON_ABILITY) as string;
      let abilityRefs = (abilityRef.includes('/')) ? abilityRef.split('/') : [abilityRef]

      const abilities = bases.filter(a => abilityRefs.includes(a.spec.name));

      if (abilities.length !== abilityRefs.length) console.log('invalid input: entry(ies) for abilities in [bases] do(es) not match the abilities base refs in [skill]')
      if (abilities.length !== abilityRefs.length) return ret;
      /*
      [
        { start: -3, spec: { name: 'Str', ref: 'skill-def' }, count: 5 },
        { start: -3, spec: { name: 'Dex', ref: 'skill-def' }, count: 6 }
      ]
      */
      abilities.forEach(a => {
        let baseCalc = Math.trunc(0.5 * (a.start + a.offset + a.count));
        // get rid of negative signed zero
        baseCalc = (baseCalc === 0) ? 0 : baseCalc;
        ret.set(a.spec.name, baseCalc);
      })

      // now the forte
      if (baseStrings.size > 2) {
        // either the forte is represented in the input as 'Str/Dex:Cbt:Melee', or just 'Cbt-Melee'
        let fortes: CountSpecRef[] = bases.filter(f => f.spec.name === baseStrings.get(SkillDefDTO.ECHELON_FORTE) || f.spec.name.endsWith(baseStrings.get(SkillDefDTO.ECHELON_FORTE) as string)) as CountSpecRef[];
        if (fortes.length !== 1) console.log('invalid input: entry(ies) for forte in [bases] do(es) not match the forte base ref in [skill]')
        if (fortes.length !== 1) return ret;

        if (fortes.length === 1) {
          let baseCalc = Math.trunc(0.5 * (fortes[0].start + fortes[0].offset + fortes[0].count));
          // get rid of negative signed zero
          baseCalc = (baseCalc === 0) ? 0 : baseCalc;
          ret.set(fortes[0].spec.name, baseCalc);
        }
      }
    }
    //console.log(`skill.spec.name: ${skill.spec.name}, skill.start: ${skill.start} + skill.offset: ${skill.offset} + skill.count: ${skill.count}`)
    ret.set(skill.spec.name, skill.start + skill.offset + skill.count);
    return ret;
  }

  /**
   * Presents the based modifiers as a single string.
   * For based modifiers, represented by:
   * 
   * Map(4) {
   *  'Str' => 1,
   *  'Dex' => 2,
   *  'Str/Dex:Cbt-Melee' => 0,
   *  'Str/Dex:Cbt-Melee:Wpn-Poles' => 1
   * }
   * 
   * The result will be 1/2 + 0 + 1 = +2/+3
   * 
   * However, the presentation is simplified for a single-element imput map
   * Map(1) {
   *  'Str' => 1
   * }
   * 
   * returning the result +1
   * 
   * @param basedMods 
   * @returns 
   */
  public static stringifyBMs = (basedMods: Map<string, number>): string => {
    let retString = '';

    let totalsCounter: number = 0;
    let elementCount: number = 0;
    let leftSide: string = '';
    for (const key of basedMods.keys()) {
      if (key.includes(':')) {
        leftSide = leftSide + (leftSide ? ' + ' : '') + basedMods.get(key);
      } else {
        leftSide = leftSide + (leftSide ? '/' : '') + basedMods.get(key);
        totalsCounter++;
      }
    }
    retString += leftSide;

    let rightSide: number[] = Array.from({ length: totalsCounter }).map((e, idx) => 0);
    for (const key of basedMods.keys()) {
      if (key.includes(':')) {
        for (let i = 0; i < rightSide.length; i++) {
          let numberToAdd = basedMods.get(key) as number;
          rightSide[i] += numberToAdd;
        }

      } else {
        rightSide[elementCount] += basedMods.get(key) as number;
        elementCount++;
      }
    }
    retString = retString + ' = ' + rightSide.map(t => {
      if (t > 0) return '+' + t
      else return '' + t
    }).join('/');

    if (basedMods.size === 1) {
      return retString.substring(retString.indexOf('=') + 2);
    } else {
      return retString;
    }
  }

  public static makeLevelAdvancementSource = (level: number): CountSpecRef => {
    return new CountSpecRef(0, level, 0, { name: 'Sophont', ref: 'Game' });
  }

  public static equals = (a: CountSpecRef, b: CountSpecRef): boolean => {
    return _.isEqual(_.omit(a, ['__proto__']), _.omit(b, ['__proto__']));
  }
}