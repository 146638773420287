import { Type } from "class-transformer";
import { Spec, SpecRef, Prop } from "."


export class Sophont extends Spec {

  /**
   * name - the name of the sophont, variant or template
   */

  /**
   * a short description of the sophont, variant or template
   */

  /**
   * if this is a variant or template, the parent is the sophont to which it applies.
   * Values set in this instance are ones that override the parent, if something is not specified here,
   * it is assumed to have been set by the parent.
   */
  @Type(() => SpecRef)
  parent: SpecRef;

  @Type(() => Prop)
  props: Prop[]

  @Type(() => SpecRef)
  naturalWeapons: SpecRef[];
  @Type(() => SpecRef)
  enhancements: SpecRef[]

  constructor(
    name: string,
    description: string,
    parent: SpecRef,
    props: Prop[],
    naturalWeapons: SpecRef[],
    enhancements: SpecRef[]

  ) {
    super(name, description);
    this.parent = parent;
    this.props = props;
    this.naturalWeapons = naturalWeapons;
    this.enhancements = enhancements;
  }
}