import * as React from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Char, CharDTO } from "../../models/specs";
import { strikerTheme } from "../../../shared/components/StrikerTheme";
import TextField from "@mui/material/TextField";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { useAppSelector } from "../../../../app/hooks";
import { getTraits, getIdeals } from "../../../shared/specSlice";
import { Quality } from "../../models/specs";
import { CheckboxGroup } from ".";
import { TallyPanel } from "./TallyPanel";
import { shrinkIfValue } from "../../../shared/utils/general";


export const DescriptionForm = ({
  onSubmit,
  data
}: {
  data: Partial<Char>;
  onSubmit: SubmitHandler<Partial<Char>>;
}) => {
  const { handleSubmit, getValues, setValue, control } = useForm({defaultValues: data});


  const specState = useAppSelector(state => state.specState);
  const alignments = ['Lawful Good', 'Neutral Good', 'Chaotic Good', 'Lawful Neutral', 'Neutral', 'Chaotic Neutral', 'Lawful Evil', 'Neutral Evil', 'Chaotic Evil']
  const traits: Quality[] = getTraits(specState.specKeys);
  const ideals: Quality[] = getIdeals(specState.specKeys);

  React.useEffect(() => {
    console.log(data)
    // we may be coming back to DescriptionForm from another tab.
    // so we want to make sure that everything from the current data is in the form we want to submit
    CharDTO.setFormValues(data, setValue);
    if (!data.user) {
      console.log('WARNING: no user in char!')
    }
  }, []);
  
  return (

    <ThemeProvider theme={strikerTheme}>
      {CharDTO.getAgeFromBackground(data) > 0 && (<TallyPanel subject={'age addition from backgrounds chosen'} value={CharDTO.getAgeFromBackground(data)} description={'advance your age by the number shown'} />)}
      <form className='form' onSubmit={handleSubmit(onSubmit)}>
        <h4>Description</h4>
        <div className='form-row'>
          <Controller name="name" control={control} render={({ field }) => 
            <TextField sx={{ width: '40ch' }} {...field} value={field.value} label="character name" InputLabelProps={shrinkIfValue(field.value)}/>} />
        </div>
        <div className='form-row'>
          <Controller name="experience" control={control} render={({ field }) => 
            <TextField {...field} value={field.value} label="experience" InputLabelProps={shrinkIfValue(field.value)} />} />
          <div>Character Levels: {CharDTO.findCharacterLevel(data)}</div>
        </div>
        <div className='form-row'>
          <Controller name="age" control={control} render={({ field }) => 
            <TextField {...field} value={field.value} label="age" InputLabelProps={shrinkIfValue(field.value)} />} />
          <Controller name="gender" control={control} render={({ field }) => 
            <TextField {...field} value={field.value} label="gender" InputLabelProps={shrinkIfValue(field.value)} />} />
          <Controller name="height" control={control} render={({ field }) => 
            <TextField {...field} value={field.value} label="height" InputLabelProps={shrinkIfValue(field.value)} />} />
          <Controller name="weight" control={control} render={({ field }) =>
            <TextField {...field} value={field.value} label="weight" InputLabelProps={shrinkIfValue(field.value)} InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            />}
          />
        </div>
        <div className='form-row'>
          <Controller
            name="alignment"
            control={control}
            render={({ field }) => <TextField {...field} label="alignment" helperText="Please select your alignment" select>
              {alignments.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>}
          />
          <div className='form-row'>
            <Controller name="traits" control={control} render={({ field }) => <CheckboxGroup
              control={control}
              label="traits"
              values={getValues().traits || []}
              options={traits}
              {...field}
            />
            } />
          </div>

          <div className='form-row'>

            <Controller name="ideals" control={control} render={({ field }) => <CheckboxGroup
              control={control}
              label="ideals"
              values={getValues().ideals || []}
              options={ideals}
              {...field}
            />
            } />
          </div>

          <Controller name="lifespan" control={control} render={({ field }) => 
            <TextField {...field} value={field.value} label="lifespan" sx={{ display: { xl: 'none', xs: 'block' } }} />} />

        </div>
        <button
          type='submit'
          className='btn btn-block btn-danger'
        >
          Submit
        </button>

      </form>
    </ThemeProvider>



  )
};
