import { plainToInstance } from "class-transformer";
import { PropRef, PropDTO } from "./PropDTO";
import { SkillDef, Sophont, SpecRef, SkillScoreAward, CountSpecRefDTO } from ".";
import { TYPE_SOPHONT, getSpec, getSpecsByType } from "../../../shared/specSlice";

export const PROP_STARTING_AGE = "startingAge";
export const PROP_LIFESPAN = "lifespan";
export const PROP_ABILITY_MODIFIERS = "abilityModifiers";
export const PROP_ADAPTIONS = "adaptions";
export const PROP_SPEED = "speed";
export const PROP_MOVES = "moves";
export const PROP_SENSES = "senses";
export const PROP_FORTE_RANKS = "forteRanks";
export const PROP_FINE_FORTE_RANKS = "fineForteRanks";
export const PROP_HEALTH_ADVANCEMENT = "healthAdvancement";
export const PROP_ENERGY_ADVANCEMENT = "energyAdvancement";
export const PROP_FOCUS_ADVANCEMENT = "focusAdvancement";
export const PROP_QUALITIES = "qualities";
export const PROP_RESISTANCES = "resistances";


export class SophontDTO {

  public static asSpecRef = (s: Sophont): SpecRef => {
    return { name: s.name, ref: 'sophont' }
  }

  /**
   * Finds the Sophont corresponding to the named reference in the (spec) state,
   * and returns it as a fully defined Sophont.
   * @param name the named reference to the Sophont
   * @returns the Sophont corresponding to the named reference
   */
  public static findInState = (name: string) => {
    let specsObj = getSpec(TYPE_SOPHONT, name);
    console.log(`SophontDTO.findInState ${name}`)
    console.log(specsObj) // includes 'at':, 'data':
    return plainToInstance(Sophont, specsObj.data)
  }

  public static findAllInState = (keys: string[]): Sophont[] => {
    let all = getSpecsByType(TYPE_SOPHONT, keys);

    let ret: Sophont[] = new Array<Sophont>;
    if (all.length > 0) {
      all.map(se => {
        Object.entries(se).map(e => {
          if (e[0] === 'data') {
            ret.push(plainToInstance(Sophont, e[1] as Sophont));
          }
        })
      })

    }
    return ret;
  }

  /**
   * Returns the string value of a prop, corresponding to the provided name for the provided Sophont
   * 
   * Note that the value can be in the form of a comma-separated array of values.
   * @param name 
   * @param s 
   * @returns the string value.
   */
  public static getNamedPropValue = (name: string, s: Sophont): string => {
    let valueReturn: string[] = new Array<string>();

    let prop = s.props.filter(p => p.name === name);
    if (prop && prop.length > 0) valueReturn = prop.map(p => p.val);
    // if the prop is missing and there is a parent. see if there is a parent with the property
    // also some props are additive, so we just want to add them in
    console.log(prop)
    console.log(name)
    if (!prop || prop.length === 0 || name === PROP_ADAPTIONS || name === PROP_RESISTANCES || name === PROP_ABILITY_MODIFIERS || name === PROP_FORTE_RANKS || name === PROP_FINE_FORTE_RANKS || name === PROP_QUALITIES) {
      // actually Dr Hackenbacker, we need to progress up even if we do find the prop, particularly for things like qualities or bonus fortes, and we need to add all the entries together
      console.log(s.parent)
      let parent = s.parent;
      if (parent) {
        console.log(`s.parent`)
        console.log(parent.name)
        let parentObj = SophontDTO.findInState(parent.name);
        console.log(parentObj)
        let parentProp = parentObj.props.filter(pp => pp.name === name).map(pp => pp.val);
        console.log(parentProp)
        if (parentProp) {
          valueReturn = valueReturn.concat(parentProp);
        }
      }
    }

    return valueReturn.join(',');
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value(s) as PropRefs
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsPropRefs = (name: string, s: Sophont): PropRef[] => {
    let sPropValue = SophontDTO.getNamedPropValue(name, s);
    return sPropValue ? PropDTO.asPropRef(sPropValue) : [];
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a PropRef
   * Use this accessor, when you know that the property is a single item.
   * @param name 
   * @param s 
   * @returns 
   */
  public static getNamedPropValueAsPropRef = (name: string, s: Sophont): PropRef => {
    let sPropValue = SophontDTO.getNamedPropValueAsPropRefs(name, s);
    return sPropValue && sPropValue.length === 1 ? sPropValue[0] : {};
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a string
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsString = (name: string, s: Sophont): string => {
    let sPropValueObj: PropRef[] = SophontDTO.getNamedPropValueAsPropRefs(name, s);
    return PropDTO.asString(sPropValueObj[0]) as string
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as a number
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsNumber = (name: string, s: Sophont): number => {
    let sPropValueObj: PropRef[] = SophontDTO.getNamedPropValueAsPropRefs(name, s);
    return PropDTO.asNumber(sPropValueObj[0]) as number
  }

  /**
   * Finds the property corresponding to the supplied property name, and returns its value as an array of SpecRef
   * @param name 
   * @param s 
   */
  public static getNamedPropValueAsSpecRefs = (name: string, s: Sophont): SpecRef[] => {
    let sPropValue = SophontDTO.getNamedPropValueAsPropRefs(name, s);
    return PropDTO.asSpecRefsForQuality(sPropValue) as SpecRef[];
  }

  /**
   * When a sophont is chosen, abilities get "offset", fortes get "count"ed, and if the character level is greater than 0,
   * the resources get "start"ed.
   * Search through the props for abilityModifiers, startingFortes and startingFineFortes
   * @param s 
   * @returns 
   */
  public static makeSkillScoreAwards = (s: Sophont, allSkills: SkillDef[], level: number = 0): SkillScoreAward[] => {
    let ret = new Array<SkillScoreAward>();

    let abilityMods = SophontDTO.getNamedPropValueAsPropRefs(PROP_ABILITY_MODIFIERS, s);
    abilityMods.forEach(m => {
      ret = [...ret, ...PropDTO.asSkillScoreAward(m, CountSpecRefDTO.SOPHONT_INIT_SSA_SOURCE, allSkills, CountSpecRefDTO.PARAM_OP_OFFSET)];
    })
    let forteRanks: PropRef[] = SophontDTO.getNamedPropValueAsPropRefs(PROP_FORTE_RANKS, s);
    forteRanks.forEach(m => {
      ret = [...ret, ...PropDTO.asSkillScoreAward(m, CountSpecRefDTO.SOPHONT_INIT_SSA_SOURCE, allSkills, CountSpecRefDTO.PARAM_OP_COUNT)];
    })
    let fineForteRanks: PropRef[] = SophontDTO.getNamedPropValueAsPropRefs(PROP_FINE_FORTE_RANKS, s);
    fineForteRanks.forEach(m => {
      ret = [...ret, ...PropDTO.asSkillScoreAward(m, CountSpecRefDTO.SOPHONT_INIT_SSA_SOURCE, allSkills, CountSpecRefDTO.PARAM_OP_COUNT)];
    })

    if (level > 0) {
      let healthFactor: number = SophontDTO.getNamedPropValueAsNumber(PROP_HEALTH_ADVANCEMENT, s) * level;
      ret = [...ret, ...PropDTO.asSkillScoreAward({num: healthFactor, ref: 'Con:Health'}, CountSpecRefDTO.SOPHONT_INIT_SSA_SOURCE, allSkills, CountSpecRefDTO.PARAM_OP_START)];
      let energyFactor: number = SophontDTO.getNamedPropValueAsNumber(PROP_ENERGY_ADVANCEMENT, s) * level;
      ret = [...ret, ...PropDTO.asSkillScoreAward({num: energyFactor, ref: 'Con/Wis:Energy'}, CountSpecRefDTO.SOPHONT_INIT_SSA_SOURCE, allSkills, CountSpecRefDTO.PARAM_OP_START)];
      let focusFactor: number = SophontDTO.getNamedPropValueAsNumber(PROP_FOCUS_ADVANCEMENT, s) * level;
      ret = [...ret, ...PropDTO.asSkillScoreAward({num: focusFactor, ref: 'Int/Wis/Cha:Focus'}, CountSpecRefDTO.SOPHONT_INIT_SSA_SOURCE, allSkills, CountSpecRefDTO.PARAM_OP_START)];
    }
    return ret;
  }

  /**
   * Allows the user to back out of a sophont choice by creating SkillScoreAwards that negate the original choice.
   * This is assuming that the SkillScoreAwards from the previous choice have been applied.
   * @param s 
   * @returns 
   */
  public static unMakeSkillScoreAwards = (s: Sophont, allSkills: SkillDef[], level: number = 0): SkillScoreAward[] => {
    let ret = new Array<SkillScoreAward>();

    let abilityMods = SophontDTO.getNamedPropValueAsPropRefs(PROP_ABILITY_MODIFIERS, s);
    abilityMods.forEach(m => {
      ret = [...ret, ...PropDTO.asReversingSkillScoreAward(m, CountSpecRefDTO.SOPHONT_INIT_SSA_SOURCE, allSkills, CountSpecRefDTO.PARAM_OP_OFFSET)];
    })
    let forteRanks: PropRef[] = SophontDTO.getNamedPropValueAsPropRefs(PROP_FORTE_RANKS, s);
    forteRanks.forEach(m => {
      ret = [...ret, ...PropDTO.asReversingSkillScoreAward(m, CountSpecRefDTO.SOPHONT_INIT_SSA_SOURCE, allSkills, CountSpecRefDTO.PARAM_OP_COUNT)];
    })
    let fineForteRanks: PropRef[] = SophontDTO.getNamedPropValueAsPropRefs(PROP_FINE_FORTE_RANKS, s);
    fineForteRanks.forEach(m => {
      ret = [...ret, ...PropDTO.asReversingSkillScoreAward(m, CountSpecRefDTO.SOPHONT_INIT_SSA_SOURCE, allSkills, CountSpecRefDTO.PARAM_OP_COUNT)];
    })
    if (level > 0) {
      let healthFactor: number = SophontDTO.getNamedPropValueAsNumber(PROP_HEALTH_ADVANCEMENT, s) * level;
      ret = [...ret, ...PropDTO.asReversingSkillScoreAward({num: healthFactor, ref: 'Con:Health'}, CountSpecRefDTO.SOPHONT_INIT_SSA_SOURCE, allSkills, CountSpecRefDTO.PARAM_OP_START)];
      let energyFactor: number = SophontDTO.getNamedPropValueAsNumber(PROP_ENERGY_ADVANCEMENT, s) * level;
      ret = [...ret, ...PropDTO.asReversingSkillScoreAward({num: energyFactor, ref: 'Con/Wis:Energy'}, CountSpecRefDTO.SOPHONT_INIT_SSA_SOURCE, allSkills, CountSpecRefDTO.PARAM_OP_START)];
      let focusFactor: number = SophontDTO.getNamedPropValueAsNumber(PROP_FOCUS_ADVANCEMENT, s) * level;
      ret = [...ret, ...PropDTO.asReversingSkillScoreAward({num: focusFactor, ref: 'Int/Wis/Cha:Focus'}, CountSpecRefDTO.SOPHONT_INIT_SSA_SOURCE, allSkills, CountSpecRefDTO.PARAM_OP_START)];
    }
    return ret;
  }

}