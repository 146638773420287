import React from 'react';
import { useAppSelector, useAppDispatch } from '../../../app/hooks'

import { Logo } from './Logo'
import { Wrapper } from '../../../assets/wrappers/BigSidebar'
import { SidebarList } from './SidebarList';


export const BigSidebar = () => {
  const { 
    showSidebar, 
  } = useAppSelector(state => state.appstate);

  const dispatch = useAppDispatch();

  return (
    <Wrapper>
      <div
        className={
          showSidebar ? 'sidebar-container ' : 'sidebar-container show-sidebar'
        }
      >
        <div className='content'>
        <header>
          <Logo />
        </header>

          <SidebarList />
         
        </div>
      </div>
    </Wrapper>
  )
}
