import { Type } from 'class-transformer';
import { SpecRef, CountSpecRef, Level, NumRecord, SkillScoreAward } from '.';

export class Char {
        // id
        id: string
        user: string
        userId: string
        created: string

        // cl
        experience: number

        // description
        name: string
        gender: string
        alignment: string
        age: number
        lifespan: number
        height: number
        weight: number

        speed: number
        @Type(() => SpecRef)
        moves: SpecRef[]

        @Type(() => SpecRef)
        traits: SpecRef[]
        @Type(() => SpecRef)
        ideals: SpecRef[]
        bonds: string
        description: string
        backstory: string
        image: string

        // sophont
        @Type(() => SpecRef)
        sophontcy: SpecRef
        @Type(() => CountSpecRef)
        adaptions: CountSpecRef[]
        @Type(() => CountSpecRef)
        resistances: CountSpecRef[]
  
        // levels
        @Type(() => Level)
        levels: Level[];

        @Type(() => CountSpecRef)
        backgrounds: CountSpecRef[]

        // abilities
        @Type(() => CountSpecRef)
        strength: CountSpecRef
        @Type(() => CountSpecRef)
        dexterity: CountSpecRef
        @Type(() => CountSpecRef)
        constitution: CountSpecRef
        @Type(() => CountSpecRef)
        intelligence: CountSpecRef
        @Type(() => CountSpecRef)
        wisdom: CountSpecRef
        @Type(() => CountSpecRef)
        charisma: CountSpecRef
        @Type(() => CountSpecRef)
        perception: CountSpecRef

        @Type(() => CountSpecRef)
        fortes: CountSpecRef[]
        @Type(() => CountSpecRef)
        fineFortes: CountSpecRef[]
        
        @Type(() => SkillScoreAward)
        skillScoreAwards: SkillScoreAward[]
 
        // enhancements
        @Type(() => CountSpecRef)
        enhancements: CountSpecRef[]
        @Type(() => CountSpecRef)
        equipment: CountSpecRef[]

        // a character build artefact, currently just calculating the age (based on number of backgrounds)
        // see KEY_AGE_ADDER_FOM_BACKGROUND in CharDTO
        @Type(() => NumRecord)
        tallies: NumRecord[]


    constructor(
      id: string,
      user: string,
      userId: string,
      created: string,

      // cl
      experience: number,

      // description
      name: string,
      gender: string,
      alignment: string,
      age: number,
      lifespan: number,
      height: number,
      weight: number,

      speed: number,
      moves: SpecRef[],

      traits: SpecRef[],
      ideals: SpecRef[],
      bonds: string,
      description: string,
      backstory: string,
      image: string,

      // sophont
      sophontcy: SpecRef,

      adaptions: CountSpecRef[],
      resistances: CountSpecRef[],

      // professions
      levels: Level[],
      backgrounds: CountSpecRef[],

      // abilities
      strength: CountSpecRef,
      dexterity: CountSpecRef,
      constitution: CountSpecRef,
      intelligence: CountSpecRef,
      wisdom: CountSpecRef,
      charisma: CountSpecRef,
      perception: CountSpecRef,

      // fortes
      fortes: CountSpecRef[],

      // fine-fortes
      fineFortes: CountSpecRef[],

      skillScoreAwards: SkillScoreAward[],

      // enhancements
      enhancements: CountSpecRef[],
      equipment: CountSpecRef[],

      tallies: NumRecord[]

    ) {
      this.id = id
      this.user = user
      this.userId = userId
      this.created = created

      // cl
      this.experience = experience
 
      // description
      this.name = name;
      this.gender = gender;
      this.alignment = alignment;
      this.age = age;
      this.lifespan = lifespan;
      this.height = height;
      this.weight = weight;

      this.speed = speed;
      this.moves = moves;

      this.traits = traits;
      this.ideals = ideals;
      this.bonds = bonds;
      this.description = description;
      this.backstory = backstory;
      this.image = image;

      // sophont
      this.sophontcy = sophontcy;

      this.adaptions = adaptions;
      this.resistances = resistances

      // professions
      this.levels = levels;
      this.backgrounds = backgrounds;

      // abilities
      this.strength = strength;
      this.dexterity = dexterity;
      this.constitution = constitution;
      this.intelligence = intelligence;
      this.wisdom = wisdom;
      this.charisma = charisma;
      this.perception = perception;

      this.fortes = fortes;
      this.fineFortes = fineFortes;

      this.skillScoreAwards = skillScoreAwards;

      // enhancements
      this.enhancements = enhancements;
      this.equipment = equipment;

      this.tallies = tallies;
    }
    
}