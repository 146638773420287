import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

export const Header = ({
  renderCount,
  description
}: {
  renderCount?: number;
  description?: string;
}) => {
  return (
    <>
      {/* this is where our alerting needs to go */}
      <span className="counter">Render Count: {renderCount}</span>
      <h1 className="h1">
        Character Builder
      </h1>
      <div className='spread-container spaced-out-man'>
        <div style={{ fontSize: 14, lineHeight: 1.3 }}>{description}</div>

        <Link
          to={`/chars`}
          className='btn'
        >
          characters
        </Link>
      </div>


    </>
  )
}
