import React from 'react';
import { Wrapper } from '../../../assets/wrappers/Home'

export const Future = () => {

    return (
        <Wrapper>
             <div className='content'>
           <h2>
                Future Publications
            </h2>
            <p>The core rules were big, but we're not done yet.</p>

            <p>The following are publications that are planned (no promises)</p>

                <h3>Rulebooks</h3>

                <ul>
                    <li>
                        <div className='content'>
                            <h4>Automechanica - The book of Robots</h4>
                            Introduces bots and AIs as sophonts. Complete rules on character builds.
                        </div>
                    </li>
                    <li>
                        <div className='content'>
                            <h4>Strikermechanica - The tome of ships beligerant and boastful</h4>
                            Expanded rules on strikers, their types and options as well as a complete guide to striker combat.
                        </div>                        
                    </li>
                </ul>


            <h3>Worldbooks</h3>

            <ul>
                <li>
                    <div className='content'>
                    <h4>The Golem of Neolod</h4>
                    A source book that centres on the so-called Apocalypse of 2552, that brought:
                    <ul>
                    <li>the first true AI into existence</li>
                    <li>the alternate spaces of Gul, Jer and Nexus together, and</li>
                    <li>the final ingredients to the invention of the striker.</li>
                    </ul>
                    It details the makeup and history of the Monarchrate and the various xanji nations, as well as their current interactions.
                    All of this provides the context for new backgrounds, equipment, enhancements and conjugates.
                    </div>
                </li>
            </ul>

            <h3>Adventures</h3>

            <ul>
                <li>
                <div className='content'>
                    <h4>Crossing the Line</h4>
                    An introductory adventure in three acts. The first act has the party locate
                    a Freen engineer hiding on the war-torn planet of Hordivia. The second act uses the freen's knowledge to board
                    the ruin of an ancient megastriker to find and terminate a mad scientist. The third act has the party cross over to 
                    Nexus Space, invade a clavian death cult and stop the AI causing all the trouble. <i>4 to 6 1st-level characters</i><br/>
                    I expect to release this by March/April 2024.
                </div>
                </li>
                <li>
                <div className='content'>
                    <h4>The Provolve Safari</h4>
                    The party is hired by an Almuqasi prefect to bust a wayward son out of a prison. The trick is that this prison
                    is also a tourist attraction, providing a safari/hunting experience for jaded Monarchrate lords 
                    and Syndacratium executives. They mostly hunt the provolves that have formed tribes and civillisations in the habitat,
                    but a few also like the idea of a mano-a-mano thrill of hunting a prisoner. <i>4 to 6 3rd-level characters</i> 
                </div>
                    
                </li>
                <li>
                <div className='content'>
                    <h4>The Scaffolds of Abacarite</h4>
                    An epic adventure into alternate Earth: the World of Jerrimoo. <i>4 to 6 5th-level characters</i> <br/>
                    This would be released after <i>The Golem of Neolod</i> as it would rely on much of it.
                </div>
                    
                </li>
            </ul>
            </div>

        </Wrapper>
    )
}