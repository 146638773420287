import { WritableDraft } from 'immer/dist/internal';

export class BlogSearchState {

    public static makeEmpty() {
        return new BlogSearchState('','')
    }

    public static asWritableDraft(o: BlogSearchState): WritableDraft<BlogSearchState> {
        return {hasTag: o.hasTag,
            likeTitle: o.likeTitle};
    }

    constructor(
        public hasTag: string,
        public likeTitle: string
    ) {}
    
}
