import { Type } from "class-transformer";
import { Spec, Prop } from "."

export class SkillDef extends Spec {
  echelon: string;
  unrankedPenalty: number;
  qualifies: string;
  @Type(() => Prop)
  resourceCost: Prop;
  aspects: string[];
  category: string;
  
  constructor(
    name: string, 
    description: string,
    echelon: string,
    unrankedPenalty: number,
    qualifies: string,
    resourceCost: Prop,
    aspects: string[],
    category: string
  ) {
    super(name,description);
    this.echelon = echelon;
    this.unrankedPenalty = unrankedPenalty;
    this.qualifies = qualifies;
    this.resourceCost = resourceCost;
    this.aspects = aspects;
    this.category = category;
  }
}
