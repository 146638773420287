import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import 'reflect-metadata';
import { store } from './app/store';
import App from './App';
import './index.css';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <Provider store={store}>
    <HelmetProvider>
      <Helmet>
      <title>Striker by Zenith Games Foundry</title>
      <meta name="description" content="Striker is a science-fiction (sci fi) tabletop role-playing game (TTRPG) published at DriveThru RPG (electronic copy) and Lulu (hard cover and paperback)" />
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Helmet>
      <App />
      </HelmetProvider>
    </Provider>
);

