import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0.25rem;
  display: grid;
  align-items: center;


  .tag-container {
    display: inline-flex;
    width: min-content;
    background: var(--farout-aqua);
    border-radius: var(--borderRadius);
    padding: 0.25rem 0.5rem;
  }

  .icon {
    font-size: var(--small-text);
    margin-right: 1rem;
    display: flex;
    align-items: center;
    svg {
      color: var(--grey-400);
    }
  }
  .tag-content {
    font-size: var(--small-text);
    letter-spacing: var(--letterSpacing);
    white-space: nowrap;
  }

  .icon-large {
    font-size: 1rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    svg {
      color: var(--grey-400);
    }
  }
  .tag-content-large {
    letter-spacing: var(--letterSpacing);
  }

`