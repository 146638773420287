import React from 'react';
import { CharSearch, CharList } from '.'
import { FileUploader } from '../../shared/components/FileUploader';
import { useAppSelector } from '../../../app/hooks';
import { ROLE_ADMIN, User } from '../../auth/User';

export const CharSearchAndList = () => {
  const userstate = useAppSelector(state => state.userstate);

  const canUpload = () => {
    const user: User = userstate.loggedInUser as User;
    return user && (user.role === ROLE_ADMIN);
  }

  return (
    <>
      {/* file uploader for admin */}
      {canUpload() && <FileUploader/>}

      <CharSearch />
      <CharList />
    </>
  )
}

