import React from 'react';
import { Wrapper } from '../../../assets/wrappers/Home'
import underground from '../../../assets/images/underground_city.jpg'

export const Mechanic = () => {

    return (
        <Wrapper>
            <div className='content'>
            <img src={underground} alt='yes, it can happen' width="700px" />
            <h2>The Striker Mechanic</h2>
            <p>
              Striker uses the d20 as the checking die for all actions, and it shares some DNA with what is technically termed SRD 5.1 or the 5th edition of that fantasy table-top role-playing game.
            </p>
            <p>
              However, as I like to say, the Striker Mechanic takes a pretty sharp left turn early on, in order to combine the familiar basis of roll-high d20 gaming, with a deep and satisfying skill system. 
              In the Striker Mechanic, <b>SKILLS ARE EVERYTHING</b>. Every action your character takes will be based on one skill or another, all of your character's capabilities are based on the 
              combinations and strengths of their skills. There are no classes, but professions, that provide a pattern of skill choices. Levels are only there to award you ranks, based on your experience, 
              so that you can add them to your skills. Levels certainly don't have the importance they mostly do in other d20 games (that I'm aware of).
            </p>
            <p>
            While I have pulled out skills as being a headline, there are other differences as well. The main take-home messages on the Striker Mechanic are:
            </p>
            <ul>
              <li>Skills are everything, everything is a skill;</li>
              <li>3 character resources, health points (hp), energy points (ep) and focus points (fp), not just hit points;</li>
              <li>Protection from damage has two components a how hard you are to hit component, called the target difficulty class (TDC), and how armoured you are, represented by a resist score against a type of damage;</li>
              <li>Combat has base scales in order to put all the mechanics under the one roof. So combat between characters, vehicles, or fleets of vehicles, is all handled the same way.</li>
            </ul>

            <p>The Striker Mechanic scales, hardly needs a single lookup table, involves simple maths, is easy to remember, and tells a good sci fi story, even computer hacking makes sense (a common stumble).</p>

            <p>Does all this sound too good to be true? It probably is, but let me explain how it's all approached, and maybe you'll glimpse what the play testers see.</p>
            
            <h3>Skills Structure</h3>

            <p>
            All skills in striker form part of an hierarchy:
            </p>
            <ul>
              <li>Abilities - The 7 abilities of Str (Strength), Dex (Dexterity), Con (Constitution), Int (Intelligence), Wis (Wisdom), Cha (Charisma) and Per (Perception) are at the top</li>
              <li>Fortes - The fortes are like the skills or proficiencies of other games</li>
              <li>Fine-fortes - The fine-fortes are like specialisations or areas of interest within the fortes</li>
            </ul>
            <p>
              A character who is an expert in finances and financial systems has a fine-forte skill, which is written in its Ability:Forte:Fine-Forte form as 
              &nbsp;<span className="property-text">Wis:Society:<b>Finance</b></span>. This is called the A:F:FF form. You can also indicate Finance
              as a fine-forte by referring to it with the notation ::Finance. When written as Wis:Society:Finance we are fully specifying the skill as "Finance is a fine-forte of Society, which is a forte of Wis"
            </p>
            <p>
              To offer skills more versatility, there is a system of <b>aspects</b>. An aspect is kind of a context in which you know and practice your skill. One of the most common type of aspect is skill-aspects.
              These are certain other skills in which your character has ranks. To Illustrate a skill-aspect we'll consider the fine-forte of Track, which is written formally as: 
              &nbsp;<span className="property-text">Int:Investigate:<b>Track</b></span>.
            </p>
            <p>
              If you want your character to be able to use Int:Investigate:Track like that of a wilderness tracker, then they need to have at least one rank in 
              &nbsp;<span className="property-text">Wis:Survival:<b>Bushcraft</b></span>. The Bushcraft fine-forte represents your practice in a wilderness, essentially suriving by foraging, hunting, 
              gathering and building snares, traps and simple weapons. If your character has a rank in both ::Track and ::Bushcraft, then Bushcraft can become an aspect on Track, and you'll
              be finding animal footprints and small signs of broken foliage in no time.
            </p>
            <p>
              If you want to be a detective, you may not be very interested in widerness tracking, but instead you may want to use the fact that you have ranks in Society to transform 
              &nbsp;<span className="property-text">Int:Investigate:<b>Track</b></span> away from looking at foot prints in the forest, and tracking the passage of criminals in the bowels of a major city. 
            </p>
            <p>
              Most characters only practice one aspect of any given skill, but there are exceptions to this.
            </p>

            <h3>How to Test Something</h3>
            <p>
              As you might expect from a roll-high d20 mechanic, the way you test any task is to find the approprate skill and use your characters capability in that skill to get a bonus (or a penalty) to a d20 roll. 
              The basic structure is as follows:
            </p>
            <p>
              <b>roll</b> (task) = d20 + <b>B</b>(Ability:Forte:Fine-Forte)
            </p>
            <p>
              or, if you are including a skill-aspect:
            </p>
            <p>
              <b>roll</b> (task) = d20 + <b>B</b>(Ability:Forte:Fine-Forte) + <b>S</b>(Forte|Fine-Forte)
            </p>
            <p>
              These are shown in some basic Striker property notation, where B stands for Based Modifier, and S stands for either Simple Modifier or, if you prefer, Score.
            </p>
            <p>
              With the <b>Based Modifier</b>, remember in the above that skills in striker are in a structure (presented in the A:F:FF form)? Well the <b>based</b> modifier that you get from any skill is built up with:
            </p>
            <p>
             <b>B</b> = ½ <b>S</b>(ability) + ½ <b>S</b>(forte) + <b>S</b>(fine-forte)
            </p>
            <p>
              This means that for any fine-forte, you make the based modifier from combining half the score from the ability, half the score from the forte, and the full score from the fine-forte.
            </p>
            <p>
              If you want the based modifier from any forte:
            </p>
            <p>
             <b>B</b> = ½ <b>S</b>(ability) + <b>S</b>(forte)
            </p>
            <p>
              and any ability is just:
            </p>
            <p>
             <b>B</b> = <b>S</b>(ability)
            </p>
            <p>
              The <b>Score</b> is often just the ranks that you have put into the skill, but there are three main exceptions to this:
            </p>
            <ol>
              <li>Abilities, for characters, start at -3 so when you put one rank into an ability it becomes -2, therefore you need to put 3 ranks in to get that ability to 0</li>
              <li>Many skills have an unranked modifier - that is the modifier that you use when you don't have ranks on the given skill. These are generally one of -3, -2, -1 and +0, 
                or no unranked modifier. This unranked modifier is replaced by your ranks when you put them on the skill.</li>
              <li>Qualifier skills, such as Survey-Tools and Forensics-Tools, which have an unranked modifier, only take 1 rank and the score becomes +0, once you put that rank on a qualifier.</li>
            </ol>
            <p>
              Given that everything in Striker is a skill, you have just come to understand the whole way that Striker works. The rest is just examples.
            </p>
            <p>
              To fire your flechette, you make an attack roll
            </p>
            <p>
              <b>roll</b> (attack) = d20 + <b>B</b>(Dex:Cbt-Ranged:<b>Wpn-Flechettes</b>)
            </p>
            <p>
              You want that roll to be above the TDC of your target.
            </p>
            <p>
              and so on, and so on, and so on.
            </p>

            </div>
        </Wrapper>
    )
}