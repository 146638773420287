import React, { FunctionComponent } from 'react';
import { Wrapper } from '../../../assets/wrappers/BlogSummary'
import { BlogInfo } from './BlogInfo'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttributionIcon from '@mui/icons-material/Attribution';
import { Link } from 'react-router-dom';

import moment from 'moment'
import { BlogState, deleteBlog, editBlog } from '../blogsSlice';
import { ROLE_ADMIN, ROLE_WRITER } from '../../auth/User';

type BlogSummaryParams = {
  blog: BlogState,
}

export const BlogSummary: FunctionComponent<BlogSummaryParams> = ({ blog }) => {
const userstate = useAppSelector(state => state.userstate);

const dispatch = useAppDispatch();

  const user = userstate.loggedInUser;

  const canEdit = user && (user.role === ROLE_WRITER || user.role === ROLE_ADMIN)
  let created = moment(blog.created).format('MMM Do, YYYY');
  let updated = moment(blog.updated).format('MMM Do, YYYY');

  return (
    <Wrapper>
      <header>
        <div className='info'>
          <h5>{blog.title}</h5>
          <p>{blog.subtitle}</p>
        </div>
        <div className='tag-content'>
          {blog.tags?.map( t => (
              <div className='tag'>{t}</div>
            ))}
        </div>
      </header>
      <div className='content'>
        <div className='content-center'>
          <BlogInfo icon={<CalendarMonthIcon />} content={created} label='Created'/>
          <BlogInfo icon={<CalendarMonthIcon />} content={updated} label='Updated' />
          <BlogInfo icon={<AttributionIcon />} content={blog.author as string} label='Author' />
         
        </div>
        
        { canEdit && (
          <footer>
            <div className='actions'>
              <Link
                to={`/blog/${blog.id}`}
                className='btn btn-go'
              >
                View
              </Link>
              <Link
                to={`/edit-blog/${blog.id}`}
                className='btn btn-go'
              >
                Edit
              </Link>
              <button
                type='button'
                className='btn btn-danger'
                onClick={() => dispatch(deleteBlog(blog.id))}
              >
                Delete
              </button>
            </div>
          </footer>
        )}
      </div>
    </Wrapper>
  )
}
