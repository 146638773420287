import { useAppSelector, useAppDispatch } from '../../../app/hooks'

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import HouseIcon from '@mui/icons-material/House';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import WebStoriesIcon from '@mui/icons-material/WebStories';

import { Logo } from './Logo'
import { BlogsListByMonth } from '../../blogs/components/BlogsListByMonth';
import { setISidebarOpen, setISidebarSelected, setJSidebarOpen, setJSidebarSelected } from '../appSlice';

import { useNavigate } from 'react-router-dom'


export const SidebarList = () => {
    const appstate = useAppSelector(state => state.appstate);
  
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

  
    const handleListItemClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      i: number,
      j: number
    ) => {
      // console.log('sidebar indices: ' + i + ', ' + j + ', sidebar-selection: ' + Number.parseInt(`${i}${j}`));
      dispatch(setISidebarOpen(i));
      dispatch(setISidebarSelected(i));
      dispatch(setJSidebarOpen(j));
      dispatch(setJSidebarSelected(Number.parseInt(`${i}${j}`)));

      // shouldn't this be changing the display page?
      if (i === 1 && j === 0) {
        navigate('/home');
      } else if (i === 1 && j === 1) {
        navigate('/future');
      } else if (i === 1 && j === 2) {
        navigate('/about');
      } else if (i === 1 && j === 3) {
        navigate('/mechanic');
      } else if (i === 1 && j === 4) {
        navigate('/products');
      } else if (i === 1 && j === 5) {
        navigate('/errata');
      } else if (i === 2 && j === 0) {
        navigate('/foundry');
      } else if (i === 2 && j === 1) {
        navigate('/chars');
      } else if (i === 2 && j === 2) {
        navigate('/strikers');
      } else if (i === 2 && j === 3) {
        navigate('/sectors');
      }
      if (i === 3) {
        // display the latest blog
        navigate('/blogs');
      }
      
    };
  
    return (
  
      <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      >
      <ListItemButton selected={appstate.iSidebarSelected === 1} onClick={(evt) => handleListItemClick(evt, 1, 0)}>
        <ListItemIcon>
          <HouseIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
        {appstate.iSidebarOpen === 1 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
  
      <Collapse in={appstate.iSidebarOpen === 1} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} selected={appstate.jSidebarSelected === 11} onClick={(evt) => handleListItemClick(evt, 1, 1)}>
            <ListItemIcon>
              
            </ListItemIcon>
            <ListItemText primary="Future Releases" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} selected={appstate.jSidebarSelected === 12} onClick={(evt) => handleListItemClick(evt, 1, 2)}>
            <ListItemIcon>
              
            </ListItemIcon>
            <ListItemText primary="About" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} selected={appstate.jSidebarSelected === 13} onClick={(evt) => handleListItemClick(evt, 1, 3)}>
            <ListItemIcon>
              
            </ListItemIcon>
            <ListItemText primary="Striker Mechanic" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} selected={appstate.jSidebarSelected === 14} onClick={(evt) => handleListItemClick(evt, 1, 4)}>
            <ListItemIcon>
              
            </ListItemIcon>
            <ListItemText primary="Products" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} selected={appstate.jSidebarSelected === 15} onClick={(evt) => handleListItemClick(evt, 1, 5)}>
            <ListItemIcon>
              
            </ListItemIcon>
            <ListItemText primary="Errata" />
          </ListItemButton>
        </List>
      </Collapse>
    
      <ListItemButton selected={appstate.iSidebarSelected === 2}  onClick={(evt) => handleListItemClick(evt, 2, 0)}>
        <ListItemIcon>
          <LensBlurIcon />
        </ListItemIcon>
        <ListItemText primary="Foundry" />
        {appstate.iSidebarOpen === 2 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
  
      <Collapse in={appstate.iSidebarOpen === 2} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} selected={appstate.jSidebarSelected === 21} onClick={(evt) => handleListItemClick(evt, 2, 1)}>
            <ListItemIcon>
              
            </ListItemIcon>
            <ListItemText primary="Characters" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} selected={appstate.jSidebarSelected === 22} onClick={(evt) => handleListItemClick(evt, 2, 2)}>
            <ListItemIcon>
              
            </ListItemIcon>
            <ListItemText primary="Strikers" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} selected={appstate.jSidebarSelected === 23} onClick={(evt) => handleListItemClick(evt, 2, 3)}>
            <ListItemIcon>
              
            </ListItemIcon>
            <ListItemText primary="Sectors" />
          </ListItemButton>
        </List>
      </Collapse>
  
      <ListItemButton selected={appstate.iSidebarSelected === 3}  onClick={(evt) => handleListItemClick(evt, 3, 0)}>
        <ListItemIcon>
          <WebStoriesIcon />
        </ListItemIcon>
        <ListItemText primary="Blogs" />
        {appstate.iSidebarOpen === 3 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
  
      <Collapse in={appstate.iSidebarOpen === 3} timeout="auto" unmountOnExit>
        <BlogsListByMonth />
      </Collapse>
    </List>
  
           
    )
  }
  