import { Type } from "class-transformer";
import { Spec, Prop } from ".";

export class Weapon extends Spec {

  /**
   * name - the name of the weapon
   */

  /**
   * a short description of the weapon
   */

  @Type(() => Prop)
  props: Prop[]


  constructor(
    name: string,
    description: string,
    props: Prop[]
    ) {
      super(name, description);
      this.props = props;
    }
} 