import React, { FunctionComponent, ReactNode } from 'react';
import { Wrapper } from "../../../assets/wrappers/BlogInfo"

type CharInfoParts = {
  icon: ReactNode,
  content: string,
  label: string
}

export const CharInfo: FunctionComponent<CharInfoParts> = ({ icon, content, label }) => {
  return (
    <Wrapper>
      <div className='label'>{label}</div>
      <div className='blog-info-container'>
        <span className='icon'>{icon}</span>
        <span className='blog-info-content'>{content}</span>
      </div>
    </Wrapper>
  )
}
