import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { User } from './User'
import { DateTime,} from 'luxon';

export interface UserState {
  readonly id: null | string,
  readonly role: null | string,
  readonly created: null | string,
  readonly lastLoggedIn: null | string,
  readonly username: null | string,
  readonly email: null | string,
  readonly usernameOrEmail: null | string,
  readonly password: null | string,
  readonly active: boolean,
  readonly userToken: null | string

}

export interface AuthState {
  readonly user: null | UserState,
  readonly users: null | UserState[],
  readonly isRegistered: boolean,
  readonly loggedInUser: null | User,
  readonly errorMessage: null | string
}

//DateTime.now().minus({days:1})

export function findLoggedInUser(): User | undefined {

  let user = localStorage.getItem('user');
  if (user && user !== "undefined") {
    let officialUser = JSON.parse(user);
    let oneDayAgo = DateTime.now().minus({days:1}).toJSDate().getTime();
    let arbitrary5DaysAgo = DateTime.now().minus({days:5}).toJSDate().getTime();

    let lastLoggedIn = officialUser ? DateTime.fromISO(officialUser.lastLoggedIn as string).toJSDate().getTime() : arbitrary5DaysAgo;
    let isStale: boolean = oneDayAgo > lastLoggedIn;
console.log(`this time yesterday (${oneDayAgo}) > last logged in (${lastLoggedIn})? ${isStale}`)

    if (isStale) {
      console.log('user stale, dropping...')
      dropLoggedInUser();
    } else {
      return officialUser;
    }
  }
  return undefined;
}

// JSON.parse(localStorage.getItem('user')).userToken
export function findUserToken(): string | undefined {
  let user = findLoggedInUser();
  if (user) {
    return user.userToken as string;
  }
  
}

function dropLoggedInUser() {
  console.log('dropping loggedInUser');
  localStorage.removeItem('user');
  localStorage.removeItem('userToken')

}

function setLoggedInUser(user: User) {
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('userToken', user.userToken as string);
}


const initialState: AuthState = {} as AuthState;

export const fetchUsers = createAsyncThunk<User[], void, { readonly rejectValue: { readonly errorMessage: string } }>(
  'users/fetch',
  async (arg, thunkAPI) => {
    return await fetch(
      '/api/users/',
      {
        method: 'GET'
      })

      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(`Unexpected response from server (code ${response.status}).`);
        }
      })

      .catch(function (error) {
        console.error(error);
        return thunkAPI.rejectWithValue({ errorMessage: error.message });
      });
  }
);

export const fetchUser = createAsyncThunk<User, User, { readonly rejectValue: { readonly errorMessage: string } }>(
  'user/fetch',
  async (arg, thunkAPI) => {
    return await fetch(
      `/api/users/${arg.email}`,
      {
        method: 'GET'
      })

      .then(response => {
        console.log(response)
        if (response.status === 200) {
          return response.json();
        } else {
          console.log('died')
          throw new Error(`Unexpected response from server (code ${response.status}).`);
        }
      })

      .catch(function (error) {
        console.error(error);
        return thunkAPI.rejectWithValue({ errorMessage: error.message });
      });
  }
);


export const createUser = createAsyncThunk<User, User, { rejectValue: { readonly errorMessage: string, readonly user: User } }>(
  'users/create',

  async (arg, thunkAPI) => {
    console.log(`called createUser with: ${JSON.stringify(arg)}`)

    return await fetch(
      '/api/users/',
      {
        method: 'POST',
        body: JSON.stringify(arg)
      })

      .then(response => {
        console.log(response)
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(`Unexpected response from server (code ${response.status}).`);
        }
      })

      .catch(error => {
        console.error(error);
        return thunkAPI.rejectWithValue({ errorMessage: error.message, user: arg });
      });
  }
);





export const loginUser = createAsyncThunk<User, User, { rejectValue: { readonly errorMessage: string, readonly user: User } }>(
  'users/login',

  async (arg, thunkAPI) => {

    return await fetch(
      '/api/users/login',
      {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${arg.userToken}` },
        body: JSON.stringify(arg)
      })

      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(`Unexpected response from server (code ${response.status}).`);
        }
      })

      .catch(function (error) {
        console.error(error);
        return thunkAPI.rejectWithValue({ errorMessage: error.message, user: arg });
      });
  }
);


export const logoutUser = createAsyncThunk<User, User, { rejectValue: { readonly errorMessage: string, readonly user: User } }>(
  'users/logout',

  async (arg, thunkAPI) => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('user');
    return arg;
  }
);

export const usersSlice = createSlice({
  name: 'userstate', // not sure what this 'name' is for. For the moment, I'll just call it the same as the reference in store.ts
  initialState,
  reducers: {
    reset: () => initialState,
    toggleRegistered: (state) => { state.isRegistered = !state.isRegistered },
    findAndLoadUser: (state) => {
      if (!state.loggedInUser) {
        let loggedInUser = findLoggedInUser();
        if (loggedInUser) {
          state.loggedInUser = loggedInUser;
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(loginUser.fulfilled, (state, action) => {
        console.log(`login success, returning: ${JSON.stringify(action.payload)}`);
        let user: User = action.payload;
        state.loggedInUser = user;
        setLoggedInUser(user);
      })
      .addCase(loginUser.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.errorMessage = action.payload.errorMessage;
        }
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.loggedInUser = null;
        state.isRegistered = true;
        dropLoggedInUser();
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.errorMessage = action.payload.errorMessage;
        }
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.errorMessage = null;
      })
      .addCase(createUser.rejected, (state, action) => {
        console.log(action.payload?.errorMessage)
        if (action.payload !== undefined) {
          state.errorMessage = action.payload.errorMessage;
        }
      })
  },
});

export const { toggleRegistered, findAndLoadUser } = usersSlice.actions

export default usersSlice.reducer;