import { Spec } from ".";

export class Quality extends Spec {

  /**
   * name - the name of the quality
   */

  /**
   * description - the description of the quality
   */


  /**
   * 
   */
  type: string;


  constructor(
    name: string,
    type: string,
    description: string

  ) {
    super(name, description);
    this.type = type;
   }
}