import { Type } from "class-transformer";
import { CountSpecRef, SpecRef } from ".";

/**
 * <p>
 * For the purposes of this class, a score is a modifier to a skill (as modelled by CountSpecRef). 
 * The modifier can be in the form of: 
 * </p>
 * <ul>
 * <li>ranks to be assigned (to the count value)</li>
 * <li>modifier to the use of the skill (change to the offset value)</li>
 * <li>or a modifier to the base starting value (change to the start value)</li>
 * </ul>
 * 
 * A SkillScoreAward represents the modification of the specified skill (through a CountSpecRef) 
 * from the specified source (a sophont, profession, vocation or background).
 * The type of modification (to the count, offset, or start) is specified in the appliesTo
 * 
 * This class may live for the life of the character however, because it tracks 
 * the number, type, target and source of scores that either have been awarded, or can be awarded. 
 * 
 * The SkillScoreAward dies (and its awarded scores removed from the target skill)
 * if the source of the award is removed.
 * 
 * Because an award source can award ranks to a group of skills (an award group). The SkillScoreAward can also be part of a group. 
 * All items in the group share the same source (such as a term in a given background), but apply to a different skill in a set that 
 * matched the wildcarded property. This means that the score property is shared with all other SkillScoreAward instances in the group.
 * Once all ranks are consumed by any one SkillScoreAward instance in the group, the awardableScore for all members drops to 0, and 
 * the members that got the awarded ranks will have their awardedRanks property at their respective values.
 */
export class SkillScoreAward {

  /**
   * The count (if this is a rank award) or the start (if this is a start award) offered to either this SkillScoreAward 
   * or to the award group if this SkillScoreAward is part of a group.
   */
  score: number

  /**
   * The number of ranks that can be awarded by this SkillScoreAward to its skill target (the value of the skill property). 
   * If this SkillScoreAward is not part of an award group then awardableRanks = score - awardedScore.
   */
  awardableScore: number

  /**
   * The number of ranks that have been awarded by this SkillScoreAward to its skill target (the value of the skill property). 
   * The awarded ranks can never exceed the ranks property.
   */
  awardedScore: number

  /**
   * A reference to the skill to which this award applies. This can apply to a specific skill, group of skills separated by commas.
   * Note that this is a SpecRef not a CountSpecRef (which is normally used to reference a skill), because the SkillScoreAward represents
   * A possible award
   */
  @Type(() => SpecRef)
  skill: SpecRef

  /**
   * A reference to the spec that provided the award, most commonly this will be a sophont, profession, vocation or background. 
   * But could also be a mnemonic enhancer skill pack (equipment). It is modelled as a CountSpecRef, as it represents the count of the application of the
   * source. Two terms in a background would be modelled as two SkillScoreAwards the first term would have an "offset" of 1, 
   * while the second term has an "offset" of two.
   */
  @Type(() => CountSpecRef)
  source: CountSpecRef

  /**
   * optional designation for where the score is to be applied in the character's skill CountSpecRef (the target)
   */
  appliesTo: string | undefined


  constructor(
    score: number,

    awardableRanks: number,

    awardedRanks: number,

    skill: SpecRef,
  
    source: CountSpecRef,

    appliesTo?: string
  
  ){
    this.score = score;

    this.awardableScore = awardableRanks;

    this.awardedScore = awardedRanks;

    this.skill = skill;
  
    this.source = source;

    this.appliesTo = appliesTo;

  }
}