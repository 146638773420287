import { SkillDefDTO, SpecRef } from ".";
import _ from "lodash"


export class SpecRefDTO {

  static readonly STR: SpecRef = {name: 'Str', ref:'skill-def'};
  static readonly DEX: SpecRef = {name: 'Dex', ref:'skill-def'};
  static readonly CON: SpecRef = {name: 'Con', ref:'skill-def'};
  static readonly INT: SpecRef = {name: 'Int', ref:'skill-def'};
  static readonly WIS: SpecRef = {name: 'Wis', ref:'skill-def'};
  static readonly CHA: SpecRef = {name: 'Cha', ref:'skill-def'};
  static readonly PER: SpecRef = {name: 'Per', ref:'skill-def'};

  static readonly STR_SAVE: SpecRef = {name: 'Str:Save', ref:'skill-def'};
  static readonly DEX_SAVE: SpecRef = {name: 'Dex:Save', ref:'skill-def'};
  static readonly CON_SAVE: SpecRef = {name: 'Con:Save', ref:'skill-def'};
  static readonly INT_SAVE: SpecRef = {name: 'Int:Save', ref:'skill-def'};
  static readonly WIS_SAVE: SpecRef = {name: 'Wis:Save', ref:'skill-def'};
  static readonly CHA_SAVE: SpecRef = {name: 'Cha:Save', ref:'skill-def'};
  static readonly PER_SAVE: SpecRef = {name: 'Per:Save', ref:'skill-def'};

  static readonly CON_HEALTH: SpecRef = { name: 'Con:Health', ref: 'skill-def' };
  static readonly CON_WIS_ENERGY: SpecRef = { name: 'Con/Wis:Energy', ref: 'skill-def' };
  static readonly INT_WIS_CHA_FOCUS: SpecRef = { name: 'Int/Wis/Cha:Focus', ref: 'skill-def' };

  static readonly ADAPT_GRAV: SpecRef = { name: 'adapt,grav' } as SpecRef; 
  static readonly ADAPT_PRESS: SpecRef = { name: 'adapt,press' } as SpecRef; 
  static readonly ADAPT_COLD: SpecRef = { name: 'adapt,cold' } as SpecRef; 
  static readonly ADAPT_HEAT: SpecRef = { name: 'adapt,heat' } as SpecRef; 

  static readonly RESIST_FRC: SpecRef = { name: 'resist,frc' } as SpecRef;
  static readonly RESIST_PRC: SpecRef = { name: 'resist,prc' } as SpecRef;
  static readonly RESIST_SLS: SpecRef = { name: 'resist,sls' } as SpecRef;
  static readonly RESIST_ELEC: SpecRef = { name: 'resist,elec' } as SpecRef;
  static readonly RESIST_FIRE: SpecRef = { name: 'resist,fire' } as SpecRef;
  static readonly RESIST_COLD: SpecRef = { name: 'resist,cold' } as SpecRef;
  static readonly RESIST_HEAT: SpecRef = { name: 'resist,heat' } as SpecRef;
  static readonly RESIST_CORR: SpecRef = { name: 'resist,corr' } as SpecRef;
  static readonly RESIST_PSN: SpecRef = { name: 'resist,psn' } as SpecRef;
  static readonly RESIST_RAD: SpecRef = { name: 'resist,rad' } as SpecRef;


  public static findSkillEchelon = (skill: SpecRef): string => {
   
    if (skill.name === 'Str' || skill.name === 'Dex' || skill.name === 'Con' || skill.name === 'Int' || skill.name === 'Wis' || skill.name === 'Cha' || skill.name === 'Per') return SkillDefDTO.ECHELON_ABILITY;

    else if (skill.name.split(':').length === 1 || [
      'Aegis','Climbers-Tools','Disguise-Tools','Forensics-Tools','Medicine-Tools',
      'Qtm-Tools','Recon-Tools','Surveillance-Tools','Survey-Tools',
      'Armour','Cbt-Liquidbourne','Cbt-Zero-G'
      ].includes(skill.name)) {
        return SkillDefDTO.ECHELON_FORTE;
    }
    else if (skill.name.split(':').length === 2) {
      return SkillDefDTO.ECHELON_FINE_FORTE
    }

    return '';
  }

  public static findSkillEchelonFromName = (skill: string): string => {
   
    if (skill === 'Str' || skill === 'Dex' || skill === 'Con' || skill === 'Int' || skill === 'Wis' || skill === 'Cha' || skill === 'Per') return SkillDefDTO.ECHELON_ABILITY;

    else if (skill.split(':').length === 1 || [
      'Aegis','Climbers-Tools','Disguise-Tools','Forensics-Tools','Medicine-Tools',
      'Qtm-Tools','Recon-Tools','Surveillance-Tools','Survey-Tools',
      'Armour','Cbt-Liquidbourne','Cbt-Zero-G'
      ].includes(skill)) {
        return SkillDefDTO.ECHELON_FORTE;
    }
    else if (skill.split(':').length === 2) {
      return SkillDefDTO.ECHELON_FINE_FORTE
    }

    return '';
  }

  public static equals = (a: SpecRef, b: SpecRef): boolean => {
    return _.isEqual(_.omit(a,['__proto__']),_.omit(b,['__proto__']));
  }

}