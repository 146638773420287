import { Type } from "class-transformer";
import { SpecRef } from ".";

/**
 * Used to present skill modifiers, count Professions, Vocations and Backgrounds, or to specify SkillScoreAward sources.
 * In the first two cases, the count represents the number of levels taken in the Profession or Vocation
 */
export class CountSpecRef {

  /**
   * This is an establishing value for the SpecRef. This is mostly used in two areas:
   * ability skill SpecRef instances where the character might begin at -3 on each ability; and
   * health, energy and focus fortes, where the character gets a certain number of points per level
   */
  start: number;

  /**
   * This is a secondary count for the SpecRef and is usually reserved for a bonus. An example where this may apply 
   * is the bonus to an ability from a particular Sophont.
   */
  offset: number;

  /**
   * This is the main count for the SpecRef. This can represent the number of SpecRef items, such as for 
   * Equipment or Enhancement SpesRefs, or it can represent the number of ranks a Char has allocated to a Skill SpecRef 
   */
  count: number;

  /**
   * The SpecRef (SkillDef, Enhancement, Equipment or Background) to which this count applies, or a specifier for the source for a SkillScoreAward.
   */
  @Type(() => SpecRef)
  spec: SpecRef;

  constructor(
    start: number,
    offset: number,
    count: number,
    spec: SpecRef,

  ) {
    this.start = start || 0;
    this.offset = offset || 0;
    this.count = count || 0;
    this.spec = spec;
   }
  
}