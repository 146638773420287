import * as React from "react";
import { useForm, useFieldArray, SubmitHandler, Controller } from "react-hook-form";
import { strikerTheme } from "../../../shared/components/StrikerTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Grid from '@mui/material/Unstable_Grid2';
import { Level, Profession, Sophont, SpecRef, Vocation, Char, CharDTO, SkillDef, SkillDefDTO } from "../../models/specs";
import { useAppSelector } from "../../../../app/hooks";
import { getProfessions, getVocations } from "../../../shared/specSlice";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { ChangeEvent, useState } from "react";
import { SophontDTO } from "../../models/specs/SophontDTO";



export const ProfessionsForm = ({
    onSubmit,
    data
  }:{
    data: Partial<Char>;
    onSubmit: SubmitHandler<Partial<Char>>;
}) => {

  const { handleSubmit, setValue, getValues, control } = useForm({
    defaultValues: data
  });

  // this (further indirection) exists because I couldn't get useEffect running from a change in `fields`
  // i probably don't need this anymore
  const [formLevels, setFormLevels] = useState<Array<Level>>(data.levels ? data.levels : new Array<Level>());

  const { fields, append, remove } = useFieldArray({
    control,
    name: "levels"
  });

  const specState = useAppSelector(state => state.specState);

  const professions: Profession[] = getProfessions(specState.specKeys);
  const vocations: Vocation[] = getVocations(specState.specKeys);
  const sophont: Sophont = SophontDTO.findInState(getValues().sophontcy?.name as string)
  const allSkills: SkillDef[] = SkillDefDTO.findAllInState(specState.specKeys)

  React.useEffect(() => {
    console.log(data)
    // we may be coming back to DescriptionForm from another tab.
    // so we want to make sure that everything from the current data is in the form we want to submit
    CharDTO.setFormValues(data, setValue);
    if (!data.user) {
      console.log('WARNING: no user in char!')
    }
  }, []);

  /**
   * adds a new level with blank choices
   */
  const addLevel = () => {
    console.log(formLevels)
    let updatedFormLevels = [...formLevels, {level: formLevels.length + 1, profession: {name: '', ref: 'profession'}, vocation: {name: '', ref: 'vocation'}}]
    setFormLevels(updatedFormLevels); // this is async, isn't it?
   CharDTO.setFormValue('levels', updatedFormLevels, data, setValue);
  }


  /**
   * adds a new level with same profession already chosen
   */
  const continueLevel = () => {
    let lastLevel = formLevels.at(formLevels.length - 1) as Level;

    let updatedFormLevels = [...formLevels, {level: formLevels.length + 1, profession: lastLevel.profession, vocation: {name: '', ref: 'vocation'}}]
    setFormLevels(updatedFormLevels); // this is async, isn't it?
    CharDTO.setFormValue('levels', updatedFormLevels, data, setValue);
  }

  /**
   * This will attempt to advance the level of the character advancing the resources fortes and providing SkillScoreAwards for the profession and vocation choices.
   * The criteria that need to be met are:
   * <ul>
   * <li>both a profession and a vocation must be present for the new level</li>
   * <li>the sophont must be chosen</li>
   * </ul>
   * @param profOrVoc 
   */
  const tryAdvancement = (profOrVoc: SpecRef) => {
    console.log('getValues() at beginning of try advancement');
    console.log(getValues())
    console.log('last vocation level')
    console.log(getValues().levels?.findLast(e => e.vocation)?.level)
    console.log('formLevels')
    console.log(formLevels.length)
    const levelsInForm = getValues().levels;
    if (levelsInForm) {
      const level = getValues().levels?.findLast(e => e);
      console.log(level)
      if (sophont && level && profOrVoc.ref === 'profession' && level.vocation.name !== '') {
        CharDTO.advanceLevel(data, sophont, professions.filter(p => p.name === profOrVoc.name).at(0) as Profession, vocations.filter(p => p.name === level?.vocation.name).at(0) as Vocation, allSkills);
        console.log(`advanced to level (at profession done) ${level?.level}`)
      } else if (sophont && level && profOrVoc.ref === 'vocation' && level.profession.name !== '') {
        CharDTO.advanceLevel(data, sophont, professions.filter(p => p.name === level?.profession.name).at(0) as Profession, vocations.filter(p => p.name === profOrVoc.name).at(0) as Vocation, allSkills);
        console.log(`advanced to level (at vocation done) ${level?.level}`)
      }
      console.log('skillscoreawards in char (did we pass a copy?)')
      console.log(data.skillScoreAwards)
  

      // a few things go wrong here
      // its probably mainly this issue
      // 3. when the form is submitted all the ssas applied here get blown away - possibly because getValues() is what gets populated and only through form values 
      // (i'll probably need a hidden value for the SSAs).
      CharDTO.setFormValue('skillScoreAwards', data.skillScoreAwards, data, setValue);
    }
  }


  return !formLevels || formLevels.length === 0 ? (
    <div>
      <div>No levels added...</div>
      <div className='actions'>

      <button
        type='button'
        className='btn edit-btn'
        onClick={() => addLevel()}
      >
        Add
      </button>

      </div>
    </div>

  ) : (

    
    <ThemeProvider theme={strikerTheme}>

      <div className='actions'>

      <button
        type='button'
        className='btn edit-btn'
        onClick={() => addLevel()}
      >
        Add
      </button>

      <button
        type='button'
        className='btn edit-btn'
        onClick={() => continueLevel()}
      >
        Continue
      </button>

      </div>



      <form className='form' onSubmit={handleSubmit(onSubmit)}>
        <h4>Levels, Professions & Vocations</h4>


        <Grid container spacing={2}>
          <Grid xs={2}>
            <h5>Levels</h5>
          </Grid>
          <Grid xs={5}>
            <h5>Profession</h5>
          </Grid>
          <Grid xs={5}>
            <h5>Vocation</h5>
          </Grid>
        </Grid>
        {fields.map((level, index) => (

          <Grid container spacing={2} key={level.level}>
            <Grid xs={2}>
              <div>{level.level}</div>
            </Grid>
            <Grid xs={5}>
              <Controller
              name={`levels.${index}.profession`}
              control={control}
              render={({ field: { onChange, value, ...field } }) => {


                function handleChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
                  onChange({name: event.target.value, ref: 'profession'});
                  tryAdvancement({name: event.target.value, ref: 'profession'} as SpecRef);
                 }
                 function setInitialValue(value: SpecRef | undefined) {
                   return value ? value.name : '';
                 }
 
              return (
              <TextField {...field} label="profession" helperText="Select profession"  value={setInitialValue(value)} onChange={handleChange} select>
                {professions.map((prof) => (
                  <MenuItem key={prof.name} value={prof.name} selected={value.name===prof.name}>
                    {prof.name}
                  </MenuItem>
                ))}
              </TextField>
              )}}
            />

            </Grid>
            <Grid xs={5}>
            <Controller
              name={`levels.${index}.vocation`}
              control={control}
              render={({ field: { onChange, value, ...field } }) => {


                function handleChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
                  onChange({name: event.target.value, ref: 'vocation'});
                  tryAdvancement({name: event.target.value, ref: 'vocation'} as SpecRef);
                 }
                 function setInitialValue(value: SpecRef | undefined) {
                   return value ? value.name : '';
                 }
 
              return (
              <TextField {...field} label="vocation" helperText="Select vocation" value={setInitialValue(value)} onChange={handleChange} select>
                {vocations.map((voc) => (
                  <MenuItem key={voc.name} value={voc.name} selected={value.name===voc.name}>
                    {voc.name}
                  </MenuItem>
                ))}
              </TextField>
              )}}
            />
            </Grid>
          </Grid>
        
        ))}

        <Controller name="fortes" control={control} render={({ field: { value, ...field } }) => {
          return (<TextField {...field} sx={{ display: { xs: 'none' } }} />)
        }} />


        <button
          type='submit'
          className='btn btn-block btn-danger'
        >
          Submit
        </button>
      </form>
    </ThemeProvider>
    
    
  )
};
