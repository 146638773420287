import React from 'react';
import { BlogSearchAndList, BlogDisplay, BlogEntryForm } from '.'
import { useAppSelector } from '../../../app/hooks';
import {User, ROLE_ADMIN, ROLE_WRITER} from '../../auth/User'
import {DISPLAY_MODE_EDIT, DISPLAY_MODE_NEW} from '../models/Blog'


/**
 * Displays various views on blogs, based on settings in the userstate (User Redux state), and the blogstate (Blog Redux state).
 *  if role = ROLE_ADMIN or ROLE_WRITER<br />
 *  display BlogSearchAndList <br />
 *  else<br />
 *  if anonymous, ROLE_READER, or ROLE_PLAYER<br />
 *  display BlogDisplay<br />
 *  <br />
 *  however if it is ROLE_ADMIN or ROLE_WRITER and<br />
 *  the blogDisplayMode is either DISPLAY_MODE_NEW or DISPLAY_MODE_EDIT<br />
 *  then show BlogEntryForm [add or edit]) in place of BlogSearchAndList<br />
 *  <br />
 *  however if it is ROLE_ADMIN or ROLE_WRITER and<br />
 *  the blogDisplayMode is DISPLAY_MODE_READ<br />
 *  <br />
 *  then show BlogDisplay [preview mode] in place of BlogSearchAndList
 * 
 * @returns <BlogDisplay /> unless role permits <BlogSearchAndList /> and activity directs <BlogEntryForm />
 */
export const BlogContainer = () => {
  const userstate = useAppSelector(state => state.userstate);
  const blogstate = useAppSelector(state => state.blogstate);
    
    const canWrite = () => {
        const user: User = userstate.loggedInUser as User;
        return user && (user.role === ROLE_ADMIN || user.role === ROLE_WRITER);
    }

    const isWriting = () => {
        return blogstate.blogDisplayMode === DISPLAY_MODE_NEW || blogstate.blogDisplayMode === DISPLAY_MODE_EDIT
    }

  return (
    <>
        {canWrite() ? <BlogSearchAndList /> : isWriting() ? <BlogEntryForm /> : <BlogDisplay />}
    </>
  )
}

