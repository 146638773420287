import { plainToInstance } from "class-transformer";
import { TYPE_SKILL_DEF, getSpec, getSpecsByType } from "../../../shared/specSlice";
import { SpecRef, SkillDef } from ".";


const FORTE_MATCH_REX = new RegExp(/\:(.*)\:/);
const SKILL_MATCH_REX = new RegExp(/[a-zA-Z/:\-]*/);
const BONUSED_SKILL_MATCH_REX = new RegExp(/([+-]?)([0-9])?\(([a-zA-Z/:\-]*)\)/);
const UNBONUSED_SKILL_MATCH_REX = new RegExp(/([a-zA-Z/:\-]*)/);
/**
 * designed to match 4(Con:Health,Int/Wis:Energy,Con:Save,Per:Save,Str/Dex:Cbt-Melee,Dex:Cbt-Ranged) or 4(Str/Dex:Cbt-Melee) or 3(::Any)
 */
const SKILL_MOD_BONUSED_GROUP = new RegExp(/([+-]?)([0-9])\(([a-zA-Z/:\-,]*)\)/g);
/**
 * designed to match Con:Health,Int/Wis:Energy,Con:Save,Per:Save,Str/Dex:Cbt-Melee,Dex:Cbt-Ranged or Str/Dex:Cbt-Melee or ::Any
 */
const SKILL_MOD_UNBONUSED_GROUP = new RegExp(/\(([a-zA-Z/:\-,]*)\)/g);



export class SkillDefDTO {

  static readonly ECHELON_ABILITY: string = 'ABILITY';
  static readonly ECHELON_FORTE: string = 'FORTE';
  static readonly ECHELON_FINE_FORTE: string = 'FINE_FORTE';
  
  static readonly CATEGORY_ENGINEERING: string = 'ENGINEERING';
  static readonly CATEGORY_COMBAT: string = 'COMBAT';
  static readonly CATEGORY_VEHICLE: string = 'VEHICLE';
  static readonly CATEGORY_PSIONIC: string = 'PSIONIC';
  

  public static asSpecRef = (sd: SkillDef): SpecRef => {
    return { name: sd.name, ref: 'skill-def' }
  }

  public static allAsSpecRef = (sds: SkillDef[]): SpecRef[] => {
    return sds.map(sd => {
      return { name: sd.name, ref: 'skill-def' }
    })
  }

  /**
   * Finds the SkillDef corresponding to the named reference in the (spec) state,
   * and returns it as a fully defined SkillDef.
   * @param name the named reference to the SkillDef
   * @returns the SkillDef corresponding to the named reference
   */
  public static findInState = (name: string) => {
    let specsObj = getSpec(TYPE_SKILL_DEF, name);
    return plainToInstance(SkillDef, specsObj.data)
  }

  public static findAllInState = (keys: string[]): SkillDef[] => {
    let all = getSpecsByType(TYPE_SKILL_DEF, keys);
    let ret: SkillDef[] = new Array<SkillDef>;
    if (all.length > 0) {
      all.map(se => {
        Object.entries(se).map(e => {
          if (e[0] === 'data') {
            ret.push(plainToInstance(SkillDef, e[1] as SkillDef));
          }
        })
      })

    }
    return ret;
  }

  /**
   * Returns an array of SkillDef that match in name. For a reference like "Dex/Wis/Per:Vcl-Ground:Any"
   * This would mean returning all SkillDefs of the same type that match "Dex/Wis/Per:Vcl-Ground:"
   * Take Note that:
   * This is tacitly for finding all the other fine-forte skills in a group for the one entered.
   * The returns are for SkillScoreAwards, not the actual skills on characters (which would be CountSpecRefs)
   * @param groupingName 
   * @returns 
   */
  public static findAllOfGroupInStateAsSpecRef = (groupingName: string, keys: string[]): SpecRef[] => {
    let all = SkillDefDTO.findAllInState(keys);

    let matchingName = groupingName.substring(0, groupingName.lastIndexOf(":")+1)

    let ret: SpecRef[] = new Array<SpecRef>;
    if (all.length > 0) {
      ret = all.filter(se => se.name.includes(matchingName))
      .map(se => {
        return {name: se.name, ref: 'skill-def'} as SpecRef
      })

    }
    return ret;
  }



  /**
   * Builds a list of SpecRef for every skill referenceable from the input skill ref.
   * @param skillRef any kind of skill reference, including a reference to a specific skill, a wildcard reference, or a comma separated list of references.
   * @param all the complete list of possible skill defs
   */
  public static anyRefAsSpecRefs = (skillRef: string, all: SkillDef[]): SpecRef[] => {
    // 4(Con:Health,Int/Wis:Energy,Con:Save,Per:Save,Str/Dex:Cbt-Melee,Dex:Cbt-Ranged)
    // 2(Con:Health),1(Int/Wis:Energy),2(Con:Save),1(Per:Save),1(Str/Dex:Cbt-Melee),2(Dex:Cbt-Ranged)
    // 2(Int:Munitions:WpnEng-Any,Wis:Psimetabolism:Any)
    // 3(::Any)
    let ret: SpecRef[] = new Array<SpecRef>();


    // This code is made complicated by javascript's ridiculously over-complicated RexExMatchArray structure
    // but essentially, its trying to decide if skillRef's basic structure is 
    // x(a,b,c,d) or 
    // x(a), y(b), z(c), n(d) or degeneratively
    // x(a) on its own
    // then feeding the result(s) into SkillDefDTO.wildcardRefAsSpecRefs(match, all)
    const bonusGroupMatches = skillRef.matchAll(SKILL_MOD_BONUSED_GROUP);
    let matches = [...bonusGroupMatches];
    if (matches && matches.length > 0) {
      for (let matchSet of matches) {
        const [match, g1, g2, g3] = matchSet;
        //console.log(match, g1, g2, g3);

        if (g2.includes(',')) {
          let refs = g2.split(',');
          refs.forEach(ref => {
            ret = [...ret, SkillDefDTO.wildcardRefAsSpecRefs(`${g1}${g2}(${ref})`, all)]
          })
        } else {
          ret = [...ret, SkillDefDTO.wildcardRefAsSpecRefs(match, all)]
        }
      }
    } else {
      if (skillRef.includes(',')) {
        let refs = skillRef.split(',');
        refs.forEach(ref => {
          ret = [...ret, SkillDefDTO.wildcardRefAsSpecRefs(ref, all)]
        })
      } else {
        ret = [...ret, SkillDefDTO.wildcardRefAsSpecRefs(skillRef, all)]
      }
  }

    return ret;
  }

  public static wildcardRefAsSpecRefs = (wildCardRef: string, all: SkillDef[]): SpecRef => {
    // now handling the possible wildcards ":Any", "::Any", ":Eng-Any", ":Psi-Any", "::Psi-Any", Int:Munitions:WpnEng-Any, Wis:Psimetabolism:Any, "Dex/Wis/Per:Vcl-Ground:Any", "Dex/Wis/Per:Vcl-Ground:Vcl-Any"
    // this can either be just the wildcard on its own, or expressed as a bonus 4(:Any)
    const bonusedSkillMatch: RegExpMatchArray | null = wildCardRef.match(BONUSED_SKILL_MATCH_REX);
    let op = (bonusedSkillMatch && bonusedSkillMatch.at(1)) ? bonusedSkillMatch.at(1) as string : '';
    let bonus = (bonusedSkillMatch && bonusedSkillMatch.at(2)) ? bonusedSkillMatch.at(2) as string : '';
    let skillRef = (bonusedSkillMatch && bonusedSkillMatch.at(3)) ? bonusedSkillMatch.at(3) as string : '';

    skillRef = (!skillRef)? wildCardRef : skillRef;


    const forteMatch: RegExpMatchArray | null = wildCardRef.match(FORTE_MATCH_REX);
    console.log(`op=${op}, bonus=${bonus}, skillRef=${skillRef}`)

    let refName = '';


    if (all.length === 0) return {name: wildCardRef, ref: 'skill-def'} as SpecRef;
    if (skillRef === ':Any') {
      refName = all.filter(se => se.echelon === SkillDefDTO.ECHELON_FORTE).map(sd => sd.name).join(',')
    } else if (skillRef.includes('::Any')) {
      refName = all.filter(se => se.echelon === SkillDefDTO.ECHELON_FINE_FORTE).map(sd => sd.name).join(',');
    } else if (skillRef.includes(':Eng-Any')) {
      refName = all.filter(se => se.echelon === SkillDefDTO.ECHELON_FORTE && se.category === SkillDefDTO.CATEGORY_ENGINEERING).map(sd => sd.name).join(',');
    } else if (skillRef.includes('::Psi-Any')) {
      refName = all.filter(se => se.echelon === SkillDefDTO.ECHELON_FINE_FORTE && se.category === SkillDefDTO.CATEGORY_PSIONIC).map(sd => sd.name).join(',');
    } else if (skillRef.includes(':WpnEng-Any')) {
      refName = all.filter(se => se.echelon === SkillDefDTO.ECHELON_FINE_FORTE && se.name.includes('WpnEng-')).map(sd => sd.name).join(',');
    } else if (skillRef.includes('Vcl-Any')) {
      refName = all
        .filter(se => se.echelon === SkillDefDTO.ECHELON_FINE_FORTE && se.category === SkillDefDTO.CATEGORY_VEHICLE && forteMatch && forteMatch.at(1) && se.name.includes(forteMatch.at(1) as string))
        .map(sd => sd.name)
        .join(',');
    } else if (skillRef.includes('Wpn-Any')) {
      refName = all
        .filter(se => se.echelon === SkillDefDTO.ECHELON_FINE_FORTE && se.category === SkillDefDTO.CATEGORY_COMBAT && se.name.includes('Wpn-') && forteMatch && forteMatch.at(1) && se.name.includes(forteMatch.at(1) as string))
        .map(sd => sd.name)
        .join(',');
    } else if (skillRef.includes('Any')) {
      refName = all
        .filter(se => se.echelon === SkillDefDTO.ECHELON_FINE_FORTE && forteMatch && forteMatch.at(1) && se.name.includes(forteMatch.at(1) as string))
        .map(sd => sd.name)
        .join(',');
    } else {
      // its probably not a wildcard, so return the whole thing.
      refName = wildCardRef;
    }

    // if there are numbers in this, return bracketed version, otherwise just the ref.
    let retSpecRef = (op && bonus) ? {name: `${op}${bonus}(${refName})`, ref: 'skill-def'} as SpecRef : {name: refName, ref: 'skill-def'} as SpecRef;

    return retSpecRef;

  }

  public static isAbility = (skill: string) => {
    return (skill === 'Str' || skill === 'Dex' || skill === 'Con' || skill === 'Int' || skill === 'Wis' || skill === 'Cha' || skill === 'Per')
  }

  public static isSaveForte = (skill: string) => {
    return (skill === 'Str:Save' || skill === 'Dex:Save' || skill === 'Con:Save' || skill === 'Int:Save' || skill === 'Wis:Save' || skill === 'Cha:Save' || skill === 'Per:Save')
  }

  public static isResourceForte = (skill: string) => {
    return (skill === 'Con:Health' || skill === 'Con/Wis:Energy' || skill === 'Int/Wis/Cha:Focus')
  }

  public static isQualifier = (skill: string) => {
    return (skill === 'Climbers-Tools' || skill === 'Disguise-Tools' || skill === 'Forensics-Tools' || skill === 'Medicine-Tools' || skill === 'Qtm-Tools' || skill === 'Recon-Tools' || skill === 'Surveillance-Tools' || skill === 'Survey-Tools' || skill === 'Armour' || skill === 'Cbt-Liquidbourne' || skill === 'Cbt-Zero-G')
  }


  public static isActivityForte = (skill: string) => {
    return (skill.split(':').length === 1 && !SkillDefDTO.isResourceForte(skill) && !SkillDefDTO.isSaveForte(skill))
  }

  public static isForte = (skill: string) => {
    return (SkillDefDTO.isActivityForte(skill) || SkillDefDTO.isResourceForte(skill) || SkillDefDTO.isSaveForte(skill) || SkillDefDTO.isQualifier(skill))
  }

  public static isFineForte = (skill: string) => {
    return (skill.split(':').length === 2)
  }

}