import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import usersReducer from '../features/auth/authSlice';
import appReducer from '../features/shared/appSlice';
import blogsReducer from '../features/blogs/blogsSlice';
import charsReducer from '../features/character/characterSlice';
import specsReducer from '../features/shared/specSlice';
import { fileApi } from '../features/shared/fileSlice';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

export const store = configureStore({
  reducer: {
    blogstate: blogsReducer,
    userstate: usersReducer,
    charstate: charsReducer,
    appstate: appReducer,
    specState: specsReducer,
    [fileApi.reducerPath]: fileApi.reducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      // specState.specs has a lot of class instances, but there are very simple, containing
      // string, number, boolean and Array
      serializableCheck: {ignoredPaths: [
        'specState.specs','specState.specs','charstate.char.traits','charstate.char.ideals',
        'payload.strength',
        'charstate.char.strength','charstate.char.dexterity','charstate.char.constitution','charstate.char.intelligence','charstate.char.wisdom','charstate.char.charisma','charstate.char.perception',
        'charstate.char.adaptions','charstate.char.resistances','charstate.char.fortes','charstate.char.fineFortes','charstate.char.skillScoreAwards','charstate.char.skillScoreAwards.0.source'
      ],
    }
    }).concat(fileApi.middleware),
    
});
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
