import React, { FunctionComponent, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { fetchChar } from '../characterSlice';
import { Wrapper } from '../../../assets/wrappers/BlogDisplay'
import { CharInfo } from './CharInfo';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttributionIcon from '@mui/icons-material/Attribution';
import moment from 'moment'
import { ROLE_ADMIN, ROLE_PLAYER } from '../../auth/User';


export const CharDisplay = () => {

  const charstate = useAppSelector(state => state.charstate);
  const userstate = useAppSelector(state => state.userstate);

  const { id } = useParams();
    console.log(`CharDisplay has id: ${id}`)
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (id) {
            console.log(`calling dispatch fetchChar with id: ${id}`)
            dispatch(fetchChar(id))
        }

    }, [id]);

    const char = charstate.char;


    let created = moment(char && char.created).format('MMM Do, YYYY');

    const user = userstate.loggedInUser;
    const canEdit = user && (user.role === ROLE_PLAYER || user.role === ROLE_ADMIN)

    /**
     * This should display the character sheet in a nice, lovlified format.
     */
    return charstate.char && (
        <Wrapper className='full-page'>


            <header>
                <div className='content-distribute'>
                    <div className='info'>
                        <h5>{char.name}</h5>
                        <p>{char.sophontcy && char.sophontcy.name}</p>
                        

                    </div>
                    <div className='info'>
                        <CharInfo icon={<AttributionIcon />} content={char.user as string} label='Player'/>
                        <div>
                        {canEdit && (
                            <footer>
                                <div className='actions'>
                                    <Link
                                        to={`/edit-blog/${char.id}`}
                                        className='btn edit-btn'
                                    >
                                        Edit
                                    </Link>
                                </div>
                            </footer>
                        )}

                        </div>
                    </div>
                </div>
            </header>
            <div className='content'>
                <div className='content-center'>
                    <CharInfo icon={<CalendarMonthIcon />} content={created} label='Created'/>
                </div>

                <div className='content-big'>

                </div>

            </div>

        </Wrapper>
    )
}