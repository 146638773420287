import React from 'react';
import { Wrapper } from '../../../assets/wrappers/Home'

export const Intro = () => {

    return (
        <Wrapper>
            <div className='content'>
            <h2>The Character Foundry</h2>
            <p>
                A tool for building and maintaining your characters and most other creatures. 
            </p>
            <p>
                The Character Foundry is currently being built, and will be ready, hopefully around March 2024. When it does become ready, you will need an account in order to make characters. 
                
                The anonymous button (top-right) will be the way to login or register.

                Please be patient, out slave gly are working as hard as our whips can make them.
            </p>
            
            </div>
        </Wrapper>
    )
}