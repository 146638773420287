import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useUploadMutation } from '../fileSlice';
import { useAppSelector } from '../../../app/hooks';

export const FileUploader = () => {
  const [uploadFile, { data: data }] = useUploadMutation();

  const userstate = useAppSelector(state => state.userstate);

  const getCurrentAuthor = (): string => {
    return userstate.loggedInUser?.username as string
  }

  const onDrop = useCallback((acceptedFiles: any[]) => {
    let fileCount = 0;
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader()
      let fileName: string;
      if (acceptedFiles && acceptedFiles.length > 0) {
        console.log(fileCount);
        console.log(acceptedFiles[0]);
        fileName = `${getCurrentAuthor()}-` + acceptedFiles[fileCount].name;
      }

      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result
        console.log(binaryStr)
        uploadFile({ name: fileName, file: file });
      }

      reader.readAsArrayBuffer(file)
      fileCount++;

    })

  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <p>Drag 'n' drop some files here, or click to select files</p>
    </div>
  )
}