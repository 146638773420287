import * as React from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { CountSpecRef, Char, CharDTO } from "../../models/specs";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { strikerTheme } from "../../../shared/components/StrikerTheme";
import Grid from '@mui/material/Unstable_Grid2';
import { SkillDisplayForm } from ".";
import { TextField } from "@mui/material";
import { useState } from "react";
import { SkillRanksPanel } from "./SkillRanksPanel";


export const AbilitiesForm = ({
    onSubmit,
    data
  }:{
    data: Partial<Char>;
    onSubmit: SubmitHandler<Partial<Char>>;
    }) => {

  const { handleSubmit, getValues, watch, control } = useForm({
    defaultValues: data
  });

  // Once the component has been initially rendered, you can trigger further renders by updating its state with the set function. Updating your component’s state automatically queues a render.
  // so with: const [name, setName] = useState('Edward');
  // if I do setName('Toxteth o'Grady') somewhere, the state will be updated and a render is triggered

  // all of this leads me to conclude that if I waant the SkillDisplayForms to re-render when an ability CountSpecRef changes, i need to create 'set's for each of them here (the ancestor component)
  // and pass the setX() method through, so that the ability components can update its appropriate value, triggering the re-render in AbilitiesForm (the ancestor component) and thus trigger the rerenders of all the 
  // SkillDisplayForms, right???
  //const [ssas, setSsas] = useState<Array<SkillScoreAward>>(data.skillScoreAwards ? data.skillScoreAwards : new Array<SkillScoreAward>());


  const [str, setStr] = useState(data.strength);
  const [dex, setDex] = useState(data.dexterity);
  const [con, setCon] = useState(data.constitution);
  const [int, setInt] = useState(data.intelligence);
  const [wis, setWis] = useState(data.wisdom);
  const [cha, setCha] = useState(data.charisma);
  const [per, setPer] = useState(data.perception);

  const [abilityRankBudget, setAbilityRankBudget] = useState(CharDTO.abilityRanksBudget(data))
  const [forteRankBudget, setForteRankBudget] = useState(CharDTO.forteRanksBudget(data))

  const abilityRankTracker = () => {
    console.log('fired ability rank tracker (to recalculate values)')
    setAbilityRankBudget(CharDTO.abilityRanksBudget(getValues()));
  }
  const forteRankTracker = () => {
    console.log('fired forte rank tracker (to recalculate values)')
    setForteRankBudget(CharDTO.forteRanksBudget(getValues()));
  }

  console.log(getValues())

  return data.fortes && (
    
    <ThemeProvider theme={strikerTheme}>
      <SkillRanksPanel ranks={abilityRankBudget} />
      <SkillRanksPanel ranks={forteRankBudget} />
      <form className='form' onSubmit={handleSubmit(onSubmit)}>
        <h4>Abilities</h4>
        <Grid container spacing={0}>
          <Grid xs={6}>
            <Controller
              name={'strength'}
              control={control}
              render={({ field: { onChange, name, value, ...field } }) =>
                <SkillDisplayForm control={control} {...field} name={name} value={value as CountSpecRef} watchedValue={[undefined, setStr]} rankTracker={abilityRankTracker} onChange={onChange} showLarge={true} />
              }
            />

          </Grid>
          <Grid xs={6}>
            {data.fortes?.map((forte, index) => {
              if (forte.spec.name === 'Str:Save') {
                return (
                  <Controller
                    name={`fortes.${index}`}
                    control={control}
                    render={({ field: { onChange, name, value, ...field } }) =>
                      <SkillDisplayForm control={control} {...field} name={name} value={value as CountSpecRef} watchedValue={[str, undefined]} rankTracker={forteRankTracker} onChange={onChange} showLarge={false} />
                    }
                  />
                )
              }
            })
            }
          </Grid>

          <Grid xs={6}>
            <Controller
              name={'dexterity'}
              control={control}
              render={({ field: { onChange, name, value, ...field } }) =>
                <SkillDisplayForm control={control} {...field} name={name} value={value as CountSpecRef} watchedValue={[undefined,setDex]} rankTracker={abilityRankTracker} onChange={onChange} showLarge={true} />
              }
            />

          </Grid>
          <Grid xs={6}>
            {data.fortes?.map((forte, index) => {
              if (forte.spec.name === 'Dex:Save') {
                return (
                  <Controller
                    name={`fortes.${index}`}
                    control={control}
                    render={({ field: { onChange, name, value, ...field } }) =>
                      <SkillDisplayForm control={control} {...field} name={name} value={value as CountSpecRef} watchedValue={[dex, undefined]} rankTracker={forteRankTracker} onChange={onChange} showLarge={false} />
                    }
                  />
                )
              }
            })
            }
          </Grid>

          <Grid xs={6}>
            <Controller
              name={'constitution'}
              control={control}
              render={({ field: { onChange, name, value, ...field } }) =>
                <SkillDisplayForm control={control} {...field} name={name} value={value as CountSpecRef} watchedValue={[undefined,setCon]} rankTracker={abilityRankTracker} onChange={onChange} showLarge={true} />
              }
            />

          </Grid>
          <Grid xs={6}>
            {data.fortes?.map((forte, index) => {
              if (forte.spec.name === 'Con:Save') {
                return (
                  <Controller
                    name={`fortes.${index}`}
                    control={control}
                    render={({ field: { onChange, name, value, ...field } }) =>
                      <SkillDisplayForm control={control} {...field} name={name} value={value as CountSpecRef} watchedValue={[con, undefined]} rankTracker={forteRankTracker} onChange={onChange} showLarge={false} />
                    }
                  />
                )
              }
            })
            }
          </Grid>


          <Grid xs={6}>
            <Controller
              name={'intelligence'}
              control={control}
              render={({ field: { onChange, name, value, ...field } }) =>
                <SkillDisplayForm control={control} {...field} name={name} value={value as CountSpecRef} watchedValue={[undefined,setInt]} rankTracker={abilityRankTracker} onChange={onChange} showLarge={true} />
              }
            />

          </Grid>
          <Grid xs={6}>
            {data.fortes?.map((forte, index) => {
              if (forte.spec.name === 'Int:Save') {
                return (
                  <Controller
                    name={`fortes.${index}`}
                    control={control}
                    render={({ field: { onChange, name, value, ...field } }) =>
                      <SkillDisplayForm control={control} {...field} name={name} value={value as CountSpecRef} watchedValue={[int, undefined]} rankTracker={forteRankTracker} onChange={onChange} showLarge={false} />
                    }
                  />
                )
              }
            })
            }
          </Grid>


          <Grid xs={6}>
            <Controller
              name={'wisdom'}
              control={control}
              render={({ field: { onChange, name, value, ...field } }) =>
                <SkillDisplayForm control={control} {...field} name={name} value={value as CountSpecRef} watchedValue={[undefined, setWis]} rankTracker={abilityRankTracker} onChange={onChange} showLarge={true} />
              }
            />

          </Grid>
          <Grid xs={6}>
            {data.fortes?.map((forte, index) => {
              if (forte.spec.name === 'Wis:Save') {
                return (
                  <Controller
                    name={`fortes.${index}`}
                    control={control}
                    render={({ field: { onChange, name, value, ...field } }) =>
                      <SkillDisplayForm control={control} {...field} name={name} value={value as CountSpecRef} watchedValue={[wis, undefined]} rankTracker={forteRankTracker} onChange={onChange} showLarge={false} />
                    }
                  />
                )
              }
            })
            }
          </Grid>


          
          <Grid xs={6}>
            <Controller
              name={'charisma'}
              control={control}
              render={({ field: { onChange, name, value, ...field } }) =>
                <SkillDisplayForm control={control} {...field} name={name} value={value as CountSpecRef} watchedValue={[undefined,setCha]} rankTracker={abilityRankTracker} onChange={onChange} showLarge={true} />
              }
            />

          </Grid>
          <Grid xs={6}>
            {data.fortes?.map((forte, index) => {
              if (forte.spec.name === 'Cha:Save') {
                return (
                  <Controller
                    name={`fortes.${index}`}
                    control={control}
                    render={({ field: { onChange, name, value, ...field } }) =>
                      <SkillDisplayForm control={control} {...field} name={name} value={value as CountSpecRef} watchedValue={[cha, undefined]} rankTracker={forteRankTracker} onChange={onChange} showLarge={false} />
                    }
                  />
                )
              }
            })
            }
          </Grid>


          <Grid xs={6}>
            <Controller
              name={'perception'}
              control={control}
              render={({ field: { onChange, name, value, ...field } }) =>
                <SkillDisplayForm control={control} {...field} name={name} value={value as CountSpecRef} watchedValue={[undefined, setPer]} rankTracker={abilityRankTracker} onChange={onChange} showLarge={true} />
              }
            />

          </Grid>
          <Grid xs={6}>
            {data.fortes?.map((forte, index) => {
              if (forte.spec.name === 'Per:Save') {
                return (
                  <Controller
                    name={`fortes.${index}`}
                    control={control}
                    render={({ field: { onChange, name, value, ...field } }) =>
                      <SkillDisplayForm control={control} {...field} name={name} value={value as CountSpecRef} watchedValue={[per, undefined]} rankTracker={forteRankTracker} onChange={onChange} showLarge={false} />
                    }
                  />
                )
              }
            })
            }
          </Grid>
        </Grid>

        <Controller name="fortes" control={control} render={({ field: { value, ...field } }) => {
          return (<TextField {...field} sx={{ display: { xs: 'none' } }} />)
        }} />

        <button
          type='submit'
          className='btn btn-block btn-danger'
        >
          Submit
        </button>
      </form>
    </ThemeProvider>
    
  )
};
