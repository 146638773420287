import { UseFormSetValue } from "react-hook-form";
import { Level, NumRecord, SpecRef, CountSpecRef, SkillScoreAward, Char, CountSpecRefDTO, SkillScoreAwardDTO, SpecRefDTO, Vocation, Profession, ProfessionDTO, VocationDTO, SkillDef, SophontDTO, Sophont, SkillDefDTO } from ".";
import { PROP_ENERGY_ADVANCEMENT, PROP_FOCUS_ADVANCEMENT, PROP_HEALTH_ADVANCEMENT } from "./SophontDTO";

export const KEY_AGE_ADDER_FROM_BACKGROUND: string = 'AgeAdderFromBackground';

export class CharDTO {

  public static makeEmptyChar = (): Partial<Char> => {
    let char = Object.apply(Char);

    char.user = '';
    char.created = ''
    // react forms don't like undefined values anywhere (actual fail with select components)
    // yes, technically, some of the following should be set with 0, but that particular number wreaks havoc with react components
    // So far we're fine with setting the value to an empty string since it's the default event.target.value of input type number when it's empty.
    char.name = '';
    char.experience = '';
    char.age = '';
    char.gender = '';
    char.height = '';
    char.weight = '';
    char.alignment = '';
    char.lifespan = '';

    // ok so typescript does seem to be pass by reference sometimes. If I don't take a structured clone of these constants, then as I add values to the Char members
    // I am actually incrementing the underlying constants.
    char.strength = structuredClone(CountSpecRefDTO.CHAR_INIT_STR);
    char.dexterity = structuredClone(CountSpecRefDTO.CHAR_INIT_DEX);
    char.constitution = structuredClone(CountSpecRefDTO.CHAR_INIT_CON);
    char.intelligence = structuredClone(CountSpecRefDTO.CHAR_INIT_INT);
    char.wisdom = structuredClone(CountSpecRefDTO.CHAR_INIT_WIS);
    char.charisma = structuredClone(CountSpecRefDTO.CHAR_INIT_CHA);
    char.perception = structuredClone(CountSpecRefDTO.CHAR_INIT_PER);

    char.traits = new Array<SpecRef>();
    char.ideals = new Array<SpecRef>();
    char.levels = new Array<Level>();

    char.adaptions = new Array<CountSpecRef>();
    char.adaptions.push(structuredClone(CountSpecRefDTO.CHAR_INIT_ADAPT_GRAV));
    char.adaptions.push(structuredClone(CountSpecRefDTO.CHAR_INIT_ADAPT_PRESS));
    char.adaptions.push(structuredClone(CountSpecRefDTO.CHAR_INIT_ADAPT_COLD));
    char.adaptions.push(structuredClone(CountSpecRefDTO.CHAR_INIT_ADAPT_HEAT));

    char.resistances = new Array<CountSpecRef>();
    char.resistances.push(structuredClone(CountSpecRefDTO.CHAR_INIT_RESIST_FRC));
    char.resistances.push(structuredClone(CountSpecRefDTO.CHAR_INIT_RESIST_PRC));
    char.resistances.push(structuredClone(CountSpecRefDTO.CHAR_INIT_RESIST_SLS));
    char.resistances.push(structuredClone(CountSpecRefDTO.CHAR_INIT_RESIST_ELEC));
    char.resistances.push(structuredClone(CountSpecRefDTO.CHAR_INIT_RESIST_FIRE));
    char.resistances.push(structuredClone(CountSpecRefDTO.CHAR_INIT_RESIST_COLD));
    char.resistances.push(structuredClone(CountSpecRefDTO.CHAR_INIT_RESIST_HEAT));
    char.resistances.push(structuredClone(CountSpecRefDTO.CHAR_INIT_RESIST_CORR));
    char.resistances.push(structuredClone(CountSpecRefDTO.CHAR_INIT_RESIST_PSN));
    char.resistances.push(structuredClone(CountSpecRefDTO.CHAR_INIT_RESIST_RAD));


    char.fortes = new Array<CountSpecRef>();
    char.fortes.push(structuredClone(CountSpecRefDTO.CHAR_INIT_STR_SAVE));
    char.fortes.push(structuredClone(CountSpecRefDTO.CHAR_INIT_DEX_SAVE));
    char.fortes.push(structuredClone(CountSpecRefDTO.CHAR_INIT_CON_SAVE));
    char.fortes.push(structuredClone(CountSpecRefDTO.CHAR_INIT_INT_SAVE));
    char.fortes.push(structuredClone(CountSpecRefDTO.CHAR_INIT_WIS_SAVE));
    char.fortes.push(structuredClone(CountSpecRefDTO.CHAR_INIT_CHA_SAVE));
    char.fortes.push(structuredClone(CountSpecRefDTO.CHAR_INIT_PER_SAVE));

    char.fortes.push(structuredClone(CountSpecRefDTO.CHAR_INIT_CON_HEALTH));
    char.fortes.push(structuredClone(CountSpecRefDTO.CHAR_INIT_CON_WIS_ENERGY));
    char.fortes.push(structuredClone(CountSpecRefDTO.CHAR_INIT_INT_WIS_CHA_FOCUS));

    // qualifiers
    [
      'Aegis','Climbers-Tools','Disguise-Tools','Forensics-Tools','Medicine-Tools',
      'Qtm-Tools','Recon-Tools','Surveillance-Tools','Survey-Tools',
      'Armour','Cbt-Liquidbourne','Cbt-Zero-G'
    ].forEach(q => {
      char.fortes.push({ start: 0, offset: 0, count: 0, spec: { name: q, ref: 'skill-def'} } as CountSpecRef)
    })

    char.fineFortes = new Array<CountSpecRef>();

    char.backgrounds = new Array<CountSpecRef>();
    char.enhancements = new Array<CountSpecRef>();
    char.equipment = new Array<CountSpecRef>();

    char.skillScoreAwards = new Array<SkillScoreAward>();

    return char;
  }


  public static makeEmptyCharFromUser = (user: string): Partial<Char> => {
    let char: Partial<Char> = CharDTO.makeEmptyChar();
    char.user = user;
    return char;
  }

  public static makeEmptyInitialisedCharFromUser = (user: string): Partial<Char> => {
    let char: Partial<Char> = CharDTO.makeEmptyCharFromUser(user);
    char.skillScoreAwards = [
      char.strength,
      char.dexterity,
      char.constitution,char.intelligence,char.wisdom,char.charisma,
      char.perception
    ].filter(a => a !== undefined).map(a => {
        return SkillScoreAwardDTO.makeWithAppliesTo(-3, (a as CountSpecRef).spec, CountSpecRefDTO.CHAR_INIT_SSA_SOURCE, CountSpecRefDTO.PARAM_OP_START);
    })
    // apply these skill awards, becasue, well, they're not optional
    char = CharDTO.applyAbilityAwards(char);
    return char;
  }

  /**
   * Sets all the Char values in the current form.
   * This is to combat the fact that when you submit the form, it submits the result of getValues()
   * which does not include the values you have set in previous tabs.
   * @param setValue 
   */
  public static setFormValues = (char: Partial<Char>, setValue: UseFormSetValue<Partial<Char>>) => {
    console.log('in CharDTO.setFormValues: user='+ char.user)
    setValue("id", char.id);
    setValue("user", char.user);
    setValue("userId", char.userId);
    setValue("created", char.created);

    setValue("experience", char.experience);

    setValue("name", char.name);
    setValue("gender", char.gender);
    setValue("alignment", char.alignment);
    setValue("age", char.age);
    setValue("lifespan", char.lifespan);
    setValue("height", char.height);
    setValue("weight", char.weight);

    setValue("speed", char.speed);
    setValue("moves", char.moves);

    setValue("traits", char.traits);
    setValue("ideals", char.ideals);
    setValue("bonds", char.bonds);
    setValue("description", char.description);
    setValue("backstory", char.backstory);
    setValue("image", char.image);

    setValue("sophontcy", char.sophontcy);

    setValue("adaptions", char.adaptions);
    setValue("resistances", char.resistances);

    setValue("levels", char.levels);
    setValue("backgrounds", char.backgrounds);

    setValue("strength", char.strength);
    setValue("dexterity", char.dexterity);
    setValue("constitution", char.constitution);
    setValue("intelligence", char.intelligence);
    setValue("wisdom", char.wisdom);
    setValue("charisma", char.charisma);
    setValue("perception", char.perception);

    setValue("fortes", char.fortes);
    setValue("fineFortes", char.fineFortes);

    setValue("skillScoreAwards", char.skillScoreAwards);

    setValue("enhancements", char.enhancements);
    setValue("equipment", char.equipment);

    setValue("tallies", char.tallies);
  }

  public static setFormValue = (
    name: "id" | "user" | "userId" | "created" | "experience" | "name" | "gender" | "alignment" 
    | "age" | "lifespan" | "height" | "weight" | "speed" | "moves" | "traits" | "ideals" | "bonds" | "description" | "backstory" | "image"
    | "sophontcy" | "adaptions" | "resistances" | "levels" | "backgrounds"
    | "strength" | "dexterity" | "constitution" | "intelligence" | "wisdom" | "charisma" | "perception"
    | "fortes" | "fineFortes" | "skillScoreAwards" | "enhancements" | "equipment" | "tallies", 
    value: any, 
    char: Partial<Char>, 
    setValue: UseFormSetValue<Partial<Char>>) => {
      char[name] = value
      setValue(name, value)
  }

  public static fetchSkill = (char: Partial<Char>, skill: string): CountSpecRef | undefined => {
    if (skill === 'Str') return char.strength;
    else if (skill === 'Dex') return char.dexterity;
    else if (skill === 'Con') return char.constitution;
    else if (skill === 'Int') return char.intelligence;
    else if (skill === 'Wis') return char.wisdom;
    else if (skill === 'Cha') return char.charisma;
    else if (skill === 'Per') return char.perception;

    let f = char.fortes?.filter(f => f.spec.name === skill);
    if (f && f.length > 0) {
      return f.at(0);
    } else {
      f = char.fineFortes?.filter(ff => ff.spec.name === skill);
      return f?.at(0);
    }
  }

  public static setSkill = (char: Partial<Char>, skill: CountSpecRef): CountSpecRef => {

    if(!char.fortes) char.fortes = new Array<CountSpecRef>();
    if(!char.fineFortes) char.fineFortes = new Array<CountSpecRef>();
   
    if (skill.spec.name === 'Str') char.strength = skill;
    else if (skill.spec.name === 'Dex') char.dexterity = skill;
    else if (skill.spec.name === 'Con') char.constitution = skill;
    else if (skill.spec.name === 'Int') char.intelligence = skill;
    else if (skill.spec.name === 'Wis') char.wisdom = skill;
    else if (skill.spec.name === 'Cha') char.charisma = skill;
    else if (skill.spec.name === 'Per') char.perception = skill;

    

    else if (skill.spec.name.split(':').length === 1 || [
      'Aegis','Climbers-Tools','Disguise-Tools','Forensics-Tools','Medicine-Tools',
      'Qtm-Tools','Recon-Tools','Surveillance-Tools','Survey-Tools',
      'Armour','Cbt-Liquidbourne','Cbt-Zero-G'
      ].includes(skill.spec.name)) {
        char.fortes = [...char.fortes.filter(f => f.spec.name !== skill.spec.name), skill];
    }
    else if (skill.spec.name.split(':').length === 2) {
      char.fineFortes = [...char.fineFortes.filter(ff => ff.spec.name !== skill.spec.name), skill];
    }

    return skill;
  }

  /**
   * Tallies up all ranks (both spent and unspent) that were originally spendable on the abilities.
   * @param char 
   */
  public static abilityRanksAwardable = (char: Partial<Char>): number => {
    if (!char.skillScoreAwards) return 0;
    let ssAwards: SkillScoreAward[] = SkillScoreAwardDTO.findCountAwardsWithTargetEchelon(char.skillScoreAwards, SkillDefDTO.ECHELON_ABILITY);

    // some of the awards may be part of a group (those in a group will all have the same source)
    let score = 0
    let sourcesFound = new Array<CountSpecRef>();

    ssAwards.forEach(ssa => {
      if(sourcesFound.filter(s => CountSpecRefDTO.equals(s,ssa.source)).length === 0) {
        sourcesFound.push(ssa.source)
        score = score + ssa.awardableScore + ssa.awardedScore;
      }
    })
    return score;
  }
  
  /**
   * Tallies up all unspent (but spendable) ranks that can be spent on the abilities.
   * @param char 
   */
  public static abilityRanksAwardableRemaining = (char: Partial<Char>): number => {
    if (!char.skillScoreAwards) return 0;
    let ssAwards: SkillScoreAward[] = SkillScoreAwardDTO.findCountAwardsWithTargetEchelon(char.skillScoreAwards, SkillDefDTO.ECHELON_ABILITY);

    // some of the awards may be part of a group (those in a group will all have the same source)
    let score = 0
    let sourcesFound = new Array<CountSpecRef>();

    ssAwards.forEach(ssa => {
      if(sourcesFound.filter(s => CountSpecRefDTO.equals(s,ssa.source)).length === 0) {
        sourcesFound.push(ssa.source)
        score = score + ssa.awardableScore;
      }
    })
    return score;
  }

  public static abilityRanksBudget = (char: Partial<Char>): RankBudget => {
    return {available: CharDTO.abilityRanksAwardableRemaining(char), used: CharDTO.ranksOnAbilities(char), original: CharDTO.abilityRanksAwardable(char), unit: RANKS_ABILITY} as RankBudget
  }

  /**
   * Tallies up all ranks (both spent and unspent) that were originally spendable on the fortes.
   * @param char 
   */
  public static forteRanksAwardable = (char: Partial<Char>): number => {
    if (!char.skillScoreAwards) return 0;
    let ssAwards: SkillScoreAward[] = SkillScoreAwardDTO.findCountAwardsWithTargetEchelon(char.skillScoreAwards, SkillDefDTO.ECHELON_FORTE);
    let score = 0
    let sourcesFound = new Array<CountSpecRef>();

    ssAwards.forEach(ssa => {
      if(sourcesFound.filter(s => CountSpecRefDTO.equals(s,ssa.source)).length === 0) {
        sourcesFound.push(ssa.source)
        score = score + ssa.awardableScore + ssa.awardedScore;
      }
    })
    return score;
  }
  

  /**
   * Tallies up all unspent (but spendable) ranks that can be spent on the fortes.
   * @param char 
   */
  public static forteRanksAwardableRemaining = (char: Partial<Char>): number => {
    if (!char.skillScoreAwards) return 0;
    let ssAwards: SkillScoreAward[] = SkillScoreAwardDTO.findCountAwardsWithTargetEchelon(char.skillScoreAwards, SkillDefDTO.ECHELON_FORTE);
    let score = 0
    let sourcesFound = new Array<CountSpecRef>();

    ssAwards.forEach(ssa => {
      if(sourcesFound.filter(s => CountSpecRefDTO.equals(s,ssa.source)).length === 0) {
        sourcesFound.push(ssa.source)
        score = score + ssa.awardableScore;
      }
    })
    return score;
  }

  public static forteRanksBudget = (char: Partial<Char>): RankBudget => {
    return {available: CharDTO.forteRanksAwardableRemaining(char), used: CharDTO.ranksOnFortes(char), original: CharDTO.forteRanksAwardable(char), unit: RANKS_FORTE} as RankBudget
  }

  /**
   * Tallies up all ranks (both spent and unspent) that were originally spendable on the fine-fortes.
   * @param char 
   */
  public static fineForteRanksAwardable = (char: Partial<Char>): number => {
    if (!char.skillScoreAwards) return 0;
    let ssAwards: SkillScoreAward[] = SkillScoreAwardDTO.findCountAwardsWithTargetEchelon(char.skillScoreAwards, SkillDefDTO.ECHELON_FINE_FORTE);
    let score = 0
    let sourcesFound = new Array<CountSpecRef>();

    ssAwards.forEach(ssa => {
      if(sourcesFound.filter(s => CountSpecRefDTO.equals(s,ssa.source)).length === 0) {
        sourcesFound.push(ssa.source)
        score = score + ssa.awardableScore + ssa.awardedScore;
      }
    })
    return score;
  }

  /**
   * Tallies up all unspent (but spendable) ranks that can be spent on the fine-fortes.
   * @param char 
   */
  public static fineForteRanksAwardableRemaining = (char: Partial<Char>): number => {
    if (!char.skillScoreAwards) return 0;
    let ssAwards: SkillScoreAward[] = SkillScoreAwardDTO.findCountAwardsWithTargetEchelon(char.skillScoreAwards, SkillDefDTO.ECHELON_FINE_FORTE);
    let score = 0
    let sourcesFound = new Array<CountSpecRef>();

    ssAwards.forEach(ssa => {
      if(sourcesFound.filter(s => CountSpecRefDTO.equals(s,ssa.source)).length === 0) {
        sourcesFound.push(ssa.source)
        score = score + ssa.awardableScore;
      }
    })
    return score;
  }

  public static fineForteRanksBudget = (char: Partial<Char>): RankBudget => {
    return {available: CharDTO.fineForteRanksAwardableRemaining(char), used: CharDTO.ranksOnFineFortes(char), original: CharDTO.fineForteRanksAwardable(char), unit: RANKS_FINE_FORTE} as RankBudget
  }

  /**
   * Finds the award in Char.skillStartAwards and replaces it with the provided award, or adds the award if it does not exist.
   * @param char 
   * @param award 
   */
  public static substituteSkillScoreAward = (char: Partial<Char>, award: SkillScoreAward): SkillScoreAward => {
    
    if (!char.skillScoreAwards) char.skillScoreAwards = new Array<SkillScoreAward>();

    // wow. so this works
    char.skillScoreAwards = [...char.skillScoreAwards.filter(ssa => !SkillScoreAwardDTO.isReplaceable(ssa,award)), award];
    // but this doesnt
    //char.skillStartAwards = [...char.skillStartAwards.filter(ssa => {
    //  !SkillScoreAwardDTO.isReplaceable(ssa,award)
    //}), award];
    // in orther words, have to have it in a single line. going to have to figure out why

    return award;
  }
  
  /**
   * Applies all awards that target the given skill
   * @param char 
   * @param skill 
   * @returns 
   */
  public static applySkillAwardsForSkill = (char: Partial<Char>, skill: string): Partial<Char> => {
    //console.log('skill at start of try applySkillAwardsForSkill on: ' + skill);
    let skillDef = CharDTO.fetchSkill(char, skill);
    //console.log(skillDef)
    if (!skillDef) return char;
    else if (!char.skillScoreAwards) return char;
    else {
      // SSA will be aggregated into what part of the CountSpecRef they are awarding
      let countAwards = 0;
      let startAwards = 0;
      let offsetAwards = 0;
      // locate the skill in the character
      let ssAwards: SkillScoreAward[] = char.skillScoreAwards as SkillScoreAward[];


      // look for all the SkillScoreAward that target this skill
      // for each SkillScoreAward, see if the source for that award is shared with any other SkillScoreAward
      let applicable: SkillScoreAward[] = new Array<SkillScoreAward>();
      
      SkillScoreAwardDTO.findAllWithTarget(ssAwards, skill).forEach(ssa => {
        let unitScore = ssa.score;
        if (ssa.appliesTo === CountSpecRefDTO.PARAM_OP_COUNT) countAwards = countAwards + unitScore;
        if (ssa.appliesTo === CountSpecRefDTO.PARAM_OP_OFFSET) offsetAwards = offsetAwards + unitScore;
        if (ssa.appliesTo === CountSpecRefDTO.PARAM_OP_START) startAwards = startAwards + unitScore;

        // find everything else from the same source that targets this skill
        // if its the current SSA then set its awardedScore to the current unitScore, otherwise just set it to 0
        // in any case, set the awardableScore to 0
        let count = 0;
        applicable = SkillScoreAwardDTO.findAllWithSource(ssAwards, ssa.source).filter(sssa => sssa.skill.name === ssa.skill.name).map(sssa => {
          // adjust the first one that matches the target skill

          // all others that match the target skill, change the awardable score to 0
          if (count === 0) {
            sssa.awardedScore = unitScore;
          } else {
            sssa.awardedScore = 0;
          }
          sssa.awardableScore = 0;
          count++;
          return sssa;
        }); 
      });

      // because we changed the values in the applicables, merge them back into char.skillScoreAwards
      let unReplaceables: SkillScoreAward[] = new Array<SkillScoreAward>();
      applicable.forEach( assa => {
        unReplaceables = (char.skillScoreAwards as SkillScoreAward[]).filter(ssa => !SkillScoreAwardDTO.isReplaceable(ssa,assa));
      })
      console.log(unReplaceables);

      let replaceables: SkillScoreAward[] = new Array<SkillScoreAward>();
      applicable.forEach( assa => {
        replaceables = (char.skillScoreAwards as SkillScoreAward[]).filter(ssa => SkillScoreAwardDTO.isReplaceable(ssa,assa));
      })
      console.log(replaceables);

      applicable.forEach( assa => {
        char.skillScoreAwards = [...unReplaceables, assa];
      })

      // do the award, which means transfer the value from the score to the start/offset/count of the skill.
      skillDef.count = skillDef.count + countAwards;
      skillDef.offset = skillDef.offset + offsetAwards;
      skillDef.start = skillDef.start + startAwards;
      
      CharDTO.setSkill(char, skillDef);
      //console.log('char at end of try applySkillAwardsToSkill on: ' + skill);
      //console.log(char)
  
      return char;
    }
  }

  /**
   * Applies all skill awards that target each of the abilities
   * @param char 
   * @returns 
   */
  public static applyAbilityAwards = (char: Partial<Char>): Partial<Char> => {
    return ['Str','Dex','Con','Int','Wis','Cha','Per'].map(a => CharDTO.applySkillAwardsForSkill(char, a)).reduce(e => e);
  }



  public static findCharacterLevel = (char: Partial<Char>): number => {
    if (!char.levels) return 0;
    return char.levels.length;
  }

  public static str = (char: Partial<Char>): CountSpecRef => {
    if (!char.strength) {
      char.strength = new CountSpecRef(-3, 0, 0, { name: 'Str', ref: 'skill-def' })
    }
    return char.strength;
  }

  public static dex = (char: Partial<Char>): CountSpecRef => {
    if (!char.dexterity) {
      char.dexterity = new CountSpecRef(-3, 0, 0, { name: 'Dex', ref: 'skill-def' })
    }
    return char.dexterity;
  }

  public static con = (char: Partial<Char>): CountSpecRef => {
    if (!char.constitution) {
      char.constitution = new CountSpecRef(-3, 0, 0, { name: 'Con', ref: 'skill-def' });
    }
    return char.constitution;
  }

  public static int = (char: Partial<Char>): CountSpecRef => {
    if (!char.intelligence) {
      char.intelligence = new CountSpecRef(-3, 0, 0, { name: 'Int', ref: 'skill-def' });
    }
    return char.intelligence;
  }

  public static wis = (char: Partial<Char>): CountSpecRef => {
    if (!char.wisdom) {
      char.wisdom = new CountSpecRef(-3, 0, 0, { name: 'Wis', ref: 'skill-def' });
    }
    return char.wisdom;
  }

  public static cha = (char: Partial<Char>): CountSpecRef => {
    if (!char.charisma) {
      char.charisma = new CountSpecRef(-3, 0, 0, { name: 'Cha', ref: 'skill-def' });
    }
    return char.charisma;
  }

  public static per = (char: Partial<Char>): CountSpecRef => {
    if (!char.perception) {
      char.perception = new CountSpecRef(-3, 0, 0, { name: 'Per', ref: 'skill-def' });
    }
    return char.perception;
  }

  /**
   * Returns the total ranks spent on ability skills
   * @param char 
   * @returns 
   */
  public static ranksOnAbilities = (char: Partial<Char>): number => {
    // go through the abilities on the char and add up their counts
    return CharDTO.str(char).count + CharDTO.dex(char).count + CharDTO.con(char).count + CharDTO.int(char).count + CharDTO.wis(char).count + CharDTO.cha(char).count + CharDTO.per(char).count
  }


  /**
   * Returns the total number of ranks assigned to all fortes of the char
   * @param char the character (Char) instance owning the fortes
   * @returns the total ranks
   */
  public static ranksOnFortes = (char: Partial<Char>): number => {
    if (!char.fortes) {
      char.fortes = new Array<CountSpecRef>();
      return 0;
    }
    return char.fortes.reduce((acc, sk) => acc + sk.count, 0);
  }

  /**
   * Returns the total number of ranks assigned to all fineFortes of the char
   * @param char the character (Char) instance owning the fineFortes
   * @returns the total ranks
   */
  public static ranksOnFineFortes = (char: Partial<Char>): number => {

    if (!char.fineFortes) {
      char.fineFortes = new Array<CountSpecRef>();
      return 0;
    }
    return char.fineFortes.reduce((acc, sk) => acc + sk.count, 0);
  }

  /**
   * Returns the forte (as CountSpecRef) based on its string reference. Note that this
   * reference can be in singular form (such as Cbt-Melee) or in based form (with the base included viz: Str/Dex:Cbt-Melee).
   * @param char the character (Char) instance owning the forte found from ref
   * @param ref the string reference in in singular or based form ('Str/Dex:Cbt-Melee').
   * @returns the forte in the form of a CountSpecRef.
   */
  public static forteFromRef = (char: Partial<Char>, ref: string): CountSpecRef | undefined => {
    let fortes: CountSpecRef[] = char.fortes?.filter(f => f.spec.name === ref || f.spec.name.endsWith(ref)) as CountSpecRef[];
    if (fortes.length > 0) {
      return fortes.at(0);
    }
  }

  /**
   *  Returns a new CountSpecRef[] array with the member referenced by 'adaptRef' updated with the 'newValue'
   * @param adaptions the array of adaptions the `adaptRef` ref is in
   * @param adaptRef the adaption that is to be updated
   * @param newValue the value to be added to the corresponding member.
   * @returns 
   */
  public static addToAdaption = (adaptions: CountSpecRef[], adaptRef: string, newValue: number): CountSpecRef[] => {
    return adaptions.map(a => {
      if (a.spec.name === adaptRef) {
        return { ...a, count: (a.count + newValue) }
      } else {
        return a;
      }
    })
  }

  public static addToResistance = (resistances: CountSpecRef[], resistRef: string, newValue: number): CountSpecRef[] => {
    return resistances.map(r => {
      if (r.spec.name === resistRef) {
        return { ...r, count: (r.count + newValue) }
      } else {
        return r;
      }
    })
  }


  public static setSkillScoreAwards = (char: Partial<Char>, ranks: number, skills: string[], source: CountSpecRef, specKeys: string[]): SkillScoreAward[] => {
    
    let updatedSkillScoreAwards = new Array<SkillScoreAward>();
    if (!char.skillScoreAwards) char.skillScoreAwards = updatedSkillScoreAwards;

    let newAwards: SkillScoreAward[] = skills.map(s => SkillScoreAwardDTO.make(ranks, s, source, specKeys)).reduce((r,m) => [...r, ...m])
    // based on whether ranks awarded are positive or negative, decide whether to add SkillRankAward or subtract from existing award

    newAwards.map(nsra => {
      // find any matched records
      const msras: SkillScoreAward[] = char.skillScoreAwards?.filter(csra => csra.source.spec.name === nsra.source.spec.name && csra.source.offset === nsra.source.offset) || []
      if (nsra.score > 0) {
        // we'll consider it an add for the moment
        updatedSkillScoreAwards = [...char.skillScoreAwards as SkillScoreAward[], nsra]
      } else {
        if (msras.length > 0) {
          let toChange = msras[0]
          toChange.score = toChange.score + nsra.score
          updatedSkillScoreAwards = [...char.skillScoreAwards?.filter(csra => csra.source.spec.name === nsra.source.spec.name && csra.source.offset === nsra.source.offset) as SkillScoreAward[], toChange]
        }
      }
    })

    return updatedSkillScoreAwards;
  }


  /**
   * Looks through the char's skillScoreAwards to find all the items that target the skill and have a non-zero awardableScore
   * Note that this is not a simple count, as SkillScoreAwards that have the same source are not counted twice
   * @param char 
   * @param skill 
   */
  public static ranksAvailableToSkill = (char: Partial<Char>, skill: string): number => {
    if (!char.skillScoreAwards) return 0;
    else {
      const echelon = SpecRefDTO.findSkillEchelonFromName(skill)

      let ssAwards: SkillScoreAward[] = SkillScoreAwardDTO.findCountAwardsWithTargetEchelon(char.skillScoreAwards, echelon);

      // some of the awards may be part of a group (those in a group will all have the same source)
      let score = 0
      let sourcesFound = new Array<CountSpecRef>();
  
      ssAwards.forEach(ssa => {
        if(sourcesFound.filter(s => CountSpecRefDTO.equals(s,ssa.source)).length === 0 && ssa.skill.name === skill) {
          sourcesFound.push(ssa.source)
          score = score + ssa.awardableScore;
        }
      })
      return score;
    }
  }


  /**
   * Looks through the char's skillBonusAwards to find all the items that target the skill and have a non-zero awardableScore
   * @param char 
   * @param skill 
   */
  public static bonusAvailableToSkill = (char: Partial<Char>, skill: string): number => {
    if (!char.skillScoreAwards) return 0;
    else {
      const echelon = SpecRefDTO.findSkillEchelonFromName(skill)

      let ssAwards: SkillScoreAward[] = SkillScoreAwardDTO.findOffsetAwardsWithTargetEchelon(char.skillScoreAwards, echelon);

      // some of the awards may be part of a group (those in a group will all have the same source)
      let score = 0
      let sourcesFound = new Array<CountSpecRef>();
  
      ssAwards.forEach(ssa => {
        if(sourcesFound.filter(s => CountSpecRefDTO.equals(s,ssa.source)).length === 0 && ssa.skill.name === skill) {
          sourcesFound.push(ssa.source)
          score = score + ssa.awardableScore;
        }
      })
      return score;
   }
  }


  public static getAgeFromBackground = (char: Partial<Char>): number => {
    if (!char.tallies) return 0;
    let tallies = char.tallies.filter(f=>f.k===KEY_AGE_ADDER_FROM_BACKGROUND);
    if(tallies.length === 0) {
      return 0;
    } else {
      return tallies[0].v;
    }
  }
  public static setAgeFromBackground = (char: Partial<Char>, age: number): NumRecord[] => {
    if (!char.tallies) char.tallies = new Array<NumRecord>();
    let updatedTallies = [...char.tallies.filter(t => t.k !==KEY_AGE_ADDER_FROM_BACKGROUND), {k:KEY_AGE_ADDER_FROM_BACKGROUND, v:age}];
    char.tallies = updatedTallies;
    return updatedTallies;

  }

  /**
   * Advances the character by 1 level. In this process, it builds and applies SkillSoreAwards for 
   * The method requires that a sophont is already chosen, and the profession and vocation for the new level are also chosen
   * @param char 
   * @param profession 
   * @param vocation 
   */
  public static advanceLevel = (char: Partial<Char>, sophont: Sophont, profession: Profession, vocation: Vocation, all: SkillDef[]) => {

    let currentLevel = char.levels ? char.levels.length : 0;

    let healthFactor: number = SophontDTO.getNamedPropValueAsNumber(PROP_HEALTH_ADVANCEMENT, sophont);
    const healthSsa = SkillScoreAwardDTO.makeWithAppliesTo(healthFactor, {name: 'Con:Health', ref:'skill-def'}, CountSpecRefDTO.makeLevelAdvancementSource(currentLevel), CountSpecRefDTO.PARAM_OP_START)
    console.log(healthSsa)

    let energyFactor: number = SophontDTO.getNamedPropValueAsNumber(PROP_ENERGY_ADVANCEMENT, sophont);
    const energySsa = SkillScoreAwardDTO.makeWithAppliesTo(energyFactor, {name: 'Con/Wis:Energy', ref:'skill-def'}, CountSpecRefDTO.makeLevelAdvancementSource(currentLevel), CountSpecRefDTO.PARAM_OP_START)
    console.log(energySsa)

    let focusFactor: number = SophontDTO.getNamedPropValueAsNumber(PROP_FOCUS_ADVANCEMENT, sophont);
    const focusSsa = SkillScoreAwardDTO.makeWithAppliesTo(focusFactor, {name: 'Int/Wis/Cha:Focus', ref:'skill-def'}, CountSpecRefDTO.makeLevelAdvancementSource(currentLevel), CountSpecRefDTO.PARAM_OP_START)
    console.log(focusSsa)

    const profSsa = ProfessionDTO.makeSkillScoreAwards(profession, currentLevel);
    console.log(profSsa)
    const vocSsa = VocationDTO.makeSkillScoreAwards(vocation, currentLevel);
    console.log(vocSsa)

    char.skillScoreAwards = [...char.skillScoreAwards as SkillScoreAward[], healthSsa, energySsa, focusSsa, ...profSsa, ...vocSsa]
    console.log('skillscoreawards in char')
    console.log(char.skillScoreAwards)

    let levelAbilityBonus = 0;
    if (currentLevel === 1) levelAbilityBonus = 21;
    if (currentLevel === 2 || currentLevel % 4 === 0) levelAbilityBonus = 1;
    if (levelAbilityBonus !== 0) {
      char.skillScoreAwards = [...char.skillScoreAwards as SkillScoreAward[],
        SkillScoreAwardDTO.makeWithAppliesTo(
          levelAbilityBonus, 
          {name: 'Str,Dex,Con,Int,Wis,Cha,Per', ref:'skill-def'}, 
          CountSpecRefDTO.makeLevelAdvancementSource(currentLevel), 
          CountSpecRefDTO.PARAM_OP_COUNT)
        ]
    }
   
    // apply the resources SkillSoreAwards
    // at the moment, these are applied to the skills but the SSAs are not updated.
    CharDTO.applySkillAwardsForSkill(char, 'Con:Health');
    CharDTO.applySkillAwardsForSkill(char, 'Con/Wis:Energy');
    CharDTO.applySkillAwardsForSkill(char, 'Int/Wis/Cha:Focus');
    // the rest are up to playeers to choose...
    console.log('after advanceLevel')
    console.log(char);
  }



}
export const RANKS_ABILITY: string = 'ability ranks'
export const RANKS_FORTE: string = 'forte ranks'
export const RANKS_FINE_FORTE: string = 'fine-forte ranks'

export interface RankBudget {
  /**
   * the number of ranks that can be allocated currently
   */
  available: number

  /**
   * the number of ranks that have been allocated already
   */
  used: number

  /**
   * The number of ranks that the character can have assigned by vitue of their level, sophont, or background
   */
  original: number

  /**
   * a choice between ability ranks, forte ranks and fine-forte ranks
   */
  unit: string
}

