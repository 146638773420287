
import { SpecRef, CountSpecRef, SkillScoreAward, CountSpecRefDTO, SkillDefDTO, SpecRefDTO } from ".";


export class SkillScoreAwardDTO {

  /**
   * Returns a SkillScoreAward, containing the given ranks.
   * 
   * @param score 
   * @param skillRef 
   * @param source
   * @returns 
   */
  public static makeOne = (score: number, skillRef:SpecRef, source: CountSpecRef): SkillScoreAward  => {
    return {score: score, awardableScore: score, awardedScore: 0, skill: skillRef, source: source} as SkillScoreAward;
  }

  public static makeWithAppliesTo = (score: number, skillRef:SpecRef, source: CountSpecRef, appliesTo: string | undefined) => {
    appliesTo = SkillScoreAwardDTO.resolveAppliesTo(appliesTo);
    return {score: score, awardableScore: score, awardedScore: 0, appliesTo: appliesTo, skill: skillRef, source: source} as SkillScoreAward;
  }

    /**
   * Returns an array of SkillScoreAward, each element containing the given ranks.
   * The size of the array returned and the target skills contained in each, depends on what matches the given string skillRef.
   * It will be a single item if the reference matches a single skill or several if its a wildcard
   * @param score 
   * @param skillRef generally a grouping reference for a skill like: Dex/Wis/Per:Vcl-Ground:Any
   * @param source
   * @param keys
   * @returns 
   */
  public static make = (score: number, skillRef:string, source: CountSpecRef, keys: string[]): SkillScoreAward[]  => {

    let specrefs: SpecRef[] = SkillDefDTO.findAllOfGroupInStateAsSpecRef(skillRef, keys);

    // the constructor initiallised appliesTo field with SkillScoreAwardDTO.resolveAppliesTo(appliesTo)
    let ret = new Array<SkillScoreAward>();

    ret = specrefs.map(sr => this.makeOne(score, sr, source));

    return ret;
  }

  public static multiply = (award: SkillScoreAward, factor: number): SkillScoreAward  => {
    award.score = award.score * factor;
    return award;
  }


  public static add = (award: SkillScoreAward, factor: number): SkillScoreAward  => {
    award.score = award.score + factor;
    return award;
  }


  public static subtract = (award: SkillScoreAward, factor: number): SkillScoreAward  => {
    award.score = award.score - factor;
    return award;
  }


  public static resolveAppliesTo = (appliesTo: string | undefined): string | undefined => {
    return appliesTo? (appliesTo===CountSpecRefDTO.PARAM_OP_START ? CountSpecRefDTO.PARAM_OP_START : 
      appliesTo===CountSpecRefDTO.PARAM_OP_OFFSET? CountSpecRefDTO.PARAM_OP_OFFSET : 
          appliesTo===CountSpecRefDTO.PARAM_OP_COUNT? CountSpecRefDTO.PARAM_OP_COUNT : undefined) 
    : undefined;
  }

  public static findAllWithTarget = (awards: SkillScoreAward[], skillRef: string): SkillScoreAward[]  => {
    if (!awards) return [];
    else {
      return awards.filter(a => a.skill.name === skillRef)
    }
  }

  public static findCountAwardsWithTargetEchelon = (awards: SkillScoreAward[], echelon: string): SkillScoreAward[]  => {
    if (!awards) return [];
    else {
      return awards.filter(a => SpecRefDTO.findSkillEchelon(a.skill) === echelon && a.appliesTo === CountSpecRefDTO.PARAM_OP_COUNT )
    }
  }

  public static findOffsetAwardsWithTargetEchelon = (awards: SkillScoreAward[], echelon: string): SkillScoreAward[]  => {
    if (!awards) return [];
    else {
      return awards.filter(a => SpecRefDTO.findSkillEchelon(a.skill) === echelon && a.appliesTo === CountSpecRefDTO.PARAM_OP_OFFSET )
    }
  }

  public static findAllWithSource = (awards: SkillScoreAward[], source: CountSpecRef): SkillScoreAward[]  => {
    if (!awards) return [];
    else {
      return awards.filter(a => CountSpecRefDTO.equals(a.source, source))
    }

  }

  /**
   * SkillScoreAward a is replacebale by b if the target skill, source spec and source offset are all equal.
   * @param a the SkillScoreAward being replaced
   * @param b the replacement
   */
  public static isReplaceable = (a: SkillScoreAward, b: SkillScoreAward): boolean => {
    return SpecRefDTO.equals(a.skill,b.skill) && SpecRefDTO.equals(a.source.spec, b.source.spec) && a.source.offset === b.source.offset
  }



}