import React, { useEffect } from 'react';
import Wrapper from '../../../assets/wrappers/Navbar'
import IconButton from '@mui/material/IconButton';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { toggleSidebar, toggleDropdown, infoAlert } from '../appSlice';
import { logoutUser, findAndLoadUser, toggleRegistered } from '../../auth/authSlice'
import { Logo } from './Logo'
import { User } from '../../auth/User';
import { useNavigate } from 'react-router-dom';
export const Navbar = () => {

  
  const userstate = useAppSelector(state => state.userstate);
  const appstate = useAppSelector(state => state.appstate);
  const dispatch = useAppDispatch();

  
  let loggedInUser = userstate.loggedInUser as User;
  if(!loggedInUser) {
    // there may not be one, but look for one anyway just in case the state has changed and this component hasn't realised it yet.
    dispatch(findAndLoadUser());
  }
  console.log(loggedInUser && JSON.stringify(loggedInUser.role))

  const navigate = useNavigate()

  return (
    <Wrapper>
      <div className='full-spread-container'>

        <IconButton aria-label="toggle sidebar"
          onClick={() => {
            dispatch(toggleSidebar());
          }}>
          <AlignHorizontalLeftIcon />
        </IconButton>



        <div>
          <Logo scale="80%"/>
          <h2 className='logo-text'>Striker (RPG)</h2>
        </div>
        <div className='btn-container'>
          <button
            type='button'
            className='btn'
            onClick={() => {
            dispatch(toggleDropdown());
          }}
          >
            <PersonIcon />
            {loggedInUser?.username || loggedInUser?.email || 'anonymous'}
            <ExpandMoreIcon />
          </button>
          <div className={appstate.showDropdown ? 'dropdown show-dropdown' : 'dropdown'}>
            <IconButton aria-label={loggedInUser ? 'logout' : 'login'}
              onClick={() => {
                if(!userstate.isRegistered) {
                  dispatch(toggleRegistered());
                  navigate('/register');
                } else if (loggedInUser) {
                  dispatch(logoutUser(loggedInUser));
                  dispatch(infoAlert('successfully logged out'));
                  navigate('/');
                }
              }}>
              {loggedInUser ? 'logout' : 'login'}
            </IconButton>

          </div>
        </div>
      </div>
    </Wrapper>
  )
}

