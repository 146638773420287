import React from 'react';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { useAppSelector } from './app/hooks';
import { Auth } from './features/auth/components/Auth'
import { ProtectedRoute, SharedLayout, Error } from './features/shared/components/'
import {About, Home, Future, Foundry, Errata, Products, Mechanic} from './features/intro/components'
import { BlogContainer, BlogEntryForm, BlogDisplay } from './features/blogs/components/'
import { CharContainer, CharDisplay } from './features/character/components';
import { CharEntry } from './features/character/components/gen';
import { SectorPlaceholder } from './features/sectors/components/SectorPlaceholder';
import { StrikerPlaceholder } from './features/strikers/components/StrikerPlaceholder';

function App() {
  const userstate = useAppSelector(state => state.userstate);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <SharedLayout />
          }
        >
          <Route index element={<Home />} />
          <Route path='home' element={<Home />} />
          <Route path='errata' element={<Errata />} />
          <Route path='foundry' element={<Foundry />} />
          <Route path='future' element={<Future />} />
          <Route path='mechanic' element={<Mechanic />} />
          <Route path='products' element={<Products />} />
          <Route path='about' element={<About />} />
          <Route path='blogs' element={<BlogContainer />} />
          <Route path='add-blog' element={<BlogEntryForm />} />
          <Route path='edit-blog/:id' element={<BlogEntryForm />} />
          <Route path='blog/:id' element={<BlogDisplay />} />
          <Route path='chars' element={<CharContainer />} />
          <Route path='add-char' element={<CharEntry />} />
          <Route path='edit-char/:id' element={<CharEntry />} />
          <Route path='char/:id' element={<CharDisplay />} />
          <Route path='strikers' element={<StrikerPlaceholder />} />
          <Route path='sectors' element={<SectorPlaceholder />} />
        </Route>
        <Route path='/register' element={<Auth />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
