import { createSlice } from '@reduxjs/toolkit';

export const ALERT_ERROR:string = 'danger'
export const ALERT_WARN:string = 'warn'
export const ALERT_INFO:string = 'success'

export interface AppState {
    readonly showSidebar: boolean,
    readonly showDropdown: boolean,
    readonly iSidebarOpen: number,
    readonly iSidebarSelected: number,
    readonly jSidebarOpen: number,
    readonly jSidebarSelected: number,
    readonly showAlert: boolean,
    readonly alertType: null | string,
    readonly alertText: null | string
}

const initialState: AppState = {} as AppState;

export const appSlice = createSlice({
    name: 'appstate', // not sure what this 'name' is for. For the moment, I'll just call it the same as the reference in store.ts
    initialState,
    reducers: {
        reset: () => initialState,
        setISidebarOpen: (state, action) => {
            //console.log(action.payload)
            state.iSidebarOpen = action.payload
        },
        setISidebarSelected: (state, action) => {state.iSidebarSelected = action.payload},
        setJSidebarOpen: (state, action) => {
            //console.log(action.payload)
            state.jSidebarOpen = action.payload
        },
        setJSidebarSelected: (state, action) => {state.jSidebarSelected = action.payload},
        toggleSidebar: (state) => {state.showSidebar = !state.showSidebar},
        toggleDropdown: (state) => {state.showDropdown = !state.showDropdown},
        errorAlert: (state, action) => {
            state.showAlert = true;
            state.alertType = ALERT_ERROR;
            state.alertText = action.payload;
        },
        warnAlert: (state, action) => {
            state.showAlert = true;
            state.alertType = ALERT_WARN;
            state.alertText = action.payload;
        },
        infoAlert: (state, action) => {
            state.showAlert = true;
            state.alertType = ALERT_INFO;
            state.alertText = action.payload;
        },
        clearAlert: (state) => {
            state.showAlert = false;
            state.alertType = null;
            state.alertText = null;
        },
    }
});

export const {setISidebarOpen, setISidebarSelected, setJSidebarOpen, setJSidebarSelected, toggleSidebar, toggleDropdown, errorAlert, warnAlert, infoAlert, clearAlert} = appSlice.actions


export default appSlice.reducer;