import { Char } from "./models/specs"
import { User } from "../auth/User";
import { WritableDraft } from 'immer/dist/internal';
import { findLoggedInUser } from "../auth/authSlice";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const pageSize = 4;

export interface PageState {
  readonly pageCount: number
  readonly page: number
}
export interface CharSearchState {
  readonly hasProfession: boolean
  readonly likeName: string
}
export interface CharacterSliceState {
  readonly chars: Partial<Char>[]
  readonly char: Partial<Char>
  readonly displayMode: string
  readonly pageState: PageState      
  readonly searchState: CharSearchState
  readonly selectedTabIndex: number
  readonly isLoading: boolean
  readonly errorMessage: string
}
const initialState: CharacterSliceState = {selectedTabIndex: 0, pageState: {pageCount:0,page:1}} as CharacterSliceState;

export const fetchChars = createAsyncThunk<Char[], void, { readonly rejectValue: { readonly errorMessage: string } }>(
  'chars/fetch',
  async (arg, thunkAPI) => {
    const user: User = findLoggedInUser() as User;

    if (!user) {
      return thunkAPI.rejectWithValue({ errorMessage: 'user not authorised' });
    }

    return await fetch(
      `/api/chars/${user.id}`,
      {
        method: 'GET'
      })

      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(`Unexpected response from server (code ${response.status}).`);
        }
      })

      .catch(function (error) {
        console.error(error);
        return thunkAPI.rejectWithValue({ errorMessage: error.message });
      });
  }
);

export const fetchChar =
  createAsyncThunk<Partial<Char>, string, { readonly rejectValue: { readonly errorMessage: string } }>(
    'char/fetch',

    async (arg, thunkAPI) => {
      const user: User = findLoggedInUser() as User;

      if (!user) {
        return thunkAPI.rejectWithValue({ errorMessage: 'user not authorised' });
      }
      return await fetch(
        `/api/chars/${user.id}/${arg}`,
        {
          method: 'GET'
        })

        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error(`Unexpected response from server (code ${response.status}).`);
          }
        })

        .catch(function (error) {
          console.error(error);
          return thunkAPI.rejectWithValue({ errorMessage: error.message });
        });
    }
  );

export const createChar =
  createAsyncThunk<Partial<Char>, Partial<Char>, { rejectValue: { readonly errorMessage: string, readonly char: Partial<Char> } }>(
    'chars/create',

    async (arg, thunkAPI) => {
      arg.created = new Date().toISOString();
      const user: User = findLoggedInUser() as User;

      if (!user) {
        return thunkAPI.rejectWithValue({ errorMessage: 'user not authorised', char: arg });
      }

      return await fetch(
        `/api/chars`,
        {
          method: 'POST',
          body: JSON.stringify(arg),
          ...(user.userToken ? { headers: { Authorization: `Basic ${user.userToken as string}` } } : {})
        })

        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error(`Unexpected response from server (code ${response.status}).`);
          }
        })

        .catch(function (error) {
          console.error(error);
          return thunkAPI.rejectWithValue({ errorMessage: error.message, char: arg });
        });
    }
  );

export const editChar = createAsyncThunk<Partial<Char>, Partial<Char>, { rejectValue: { readonly errorMessage: string, readonly char: Partial<Char> } }>(
  'chars/edit',

  async (arg, thunkAPI) => {

    const user: User = findLoggedInUser() as User;

    if (!user) {
      return thunkAPI.rejectWithValue({ errorMessage: 'user not authorised', char: arg });
    }
    return await fetch(
      `/api/chars/${arg.id}`,
      {
        method: 'PATCH',
        body: JSON.stringify(arg),
        ...(user.userToken ? { headers: { Authorization: `Basic ${user.userToken as string}` } } : {})
      })

      .then(response => {
        if (response.status === 200) {
          // this and create... need to return the new objects
          return response.json();
        } else {
          throw new Error(`Unexpected response from server (code ${response.status}).`);
        }
      })

      .catch(function (error) {
        console.error(error);
        return thunkAPI.rejectWithValue({ errorMessage: error.message, char: arg });
      });
  }
);

export const deleteChar = createAsyncThunk<string, string, { rejectValue: { readonly errorMessage: string } }>(
  'chars/delete',

  async (arg, thunkAPI) => {

    const user: User = findLoggedInUser() as User;

    if (!user) {
      return thunkAPI.rejectWithValue({ errorMessage: 'user not authorised' });
    }
    return await fetch(
      `/api/chars/${arg}`,
      {
        method: 'DELETE',
        body: JSON.stringify(arg),
        ...(user.userToken ? { headers: { Authorization: `Basic ${user.userToken as string}` } } : {})
      })

      .then(response => {
        if (response.status === 200) {
          return arg;
        } else {
          throw new Error(`Unexpected response from server (code ${response.status}).`);
        }
      })

      .catch(function (error) {
        console.error(error);
        return thunkAPI.rejectWithValue({ errorMessage: error.message });
      });
  }
);
// export const ranksOnAbilities = (char: Partial<Char>): number => {
  // go through the abilities on the char and add up their counts


export const characterSlice = createSlice({
  name: 'charstate', // name the state
  initialState,
  reducers: {
    reset: () => initialState,
    setCharDisplayMode: (state, action) => { state.displayMode = action.payload },
    setLoading: (state, action) => { state.isLoading = action.payload },
    setEmptyChar: (state, action) => { state.char = action.payload },
    filterChars: (state, action) => {
      if (!state.chars) {
        state.errorMessage = 'blogs have not been fetched, nothing to filter';
      } else {
        let cs = action.payload as WritableDraft<CharSearchState>
        if (cs.hasProfession || cs.likeName) {
          const copy = [...state.chars].map(item => ({ ...item }))
          // figure out how to filter by profession, sophontcy etc.
          state.chars = copy.filter(c => c.name?.includes(cs.likeName)) as [WritableDraft<Char>];
        }
      }
    },
    changePage: (state, action) => { state.pageState.page = action.payload },
    prevPage: (state, action) => {
      if (!state.pageState) {
        state.pageState = { pageCount: 0, page: 1 };
      } else {
        let newPage = state.pageState.page - 1
        if (newPage < 1) {
          newPage = state.pageState.pageCount;
        }
        state.pageState.page = newPage;
      }
    },
    nextPage: (state, action) => {
      if (!state.pageState) {
        state.pageState = { pageCount: 0, page: 1 };
      } else {
        let newPage = state.pageState.page - 1
        if (newPage < state.pageState.pageCount) {
          newPage = 1;
        }
        state.pageState.page = newPage;
      }
    },
    setSelectedTabIndex: (state, action) => { state.selectedTabIndex = action.payload },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChars.fulfilled, (state, action) => {
        // action.payload is read only
        // so copy array.
        const copyOfActionPayload = [...action.payload]
        state.chars = copyOfActionPayload;
        state.pageState = { pageCount: Math.ceil(copyOfActionPayload.length / pageSize), page: 1 }

        state.isLoading = false;
      })
      .addCase(fetchChars.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.errorMessage = action.payload.errorMessage;
        }
        state.isLoading = false;
      })
      .addCase(fetchChar.fulfilled, (state, action) => {
        console.log(`just called fetchChar, and got...`)
        console.log(action.payload)

        state.char = action.payload;

        state.isLoading = false;
      })
      .addCase(fetchChar.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.errorMessage = action.payload.errorMessage;
        }
        state.isLoading = false;
      })
      .addCase(createChar.fulfilled, (state, action) => {
        console.log(`just called createChar, and got...`)
        console.log(action.payload)
        state.char = action.payload;

        state.errorMessage = '';
        state.isLoading = false;
      })
      .addCase(createChar.rejected, (state, action) => {
        console.log(action.payload?.errorMessage)
        if (action.payload !== undefined) {
          state.errorMessage = action.payload.errorMessage;
        }
        state.isLoading = false;
      })
      .addCase(editChar.fulfilled, (state, action) => {
        console.log(`just called editChar, and got...`)
        console.log(action.payload)

        state.char = action.payload;
        state.errorMessage = '';
        state.isLoading = false;
      })
      .addCase(editChar.rejected, (state, action) => {
        console.log(action.payload?.errorMessage)
        if (action.payload !== undefined) {
          state.errorMessage = action.payload.errorMessage;
        }
        state.isLoading = false;
      })
      .addCase(deleteChar.fulfilled, (state, action) => {
        state.char = Object.apply(Char);
        state.errorMessage = '';
        state.isLoading = false;
      })
      .addCase(deleteChar.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.errorMessage = action.payload.errorMessage;
        }
        state.isLoading = false;
      })
      ;
  }
});

export const {
  setLoading,
  setCharDisplayMode,
  setEmptyChar,
  filterChars,
  changePage,
  prevPage,
  nextPage,
  setSelectedTabIndex,
} = characterSlice.actions

export default characterSlice.reducer;