import * as React from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Char } from "../../models/specs";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { strikerTheme } from "../../../shared/components/StrikerTheme";
import TextField from "@mui/material/TextField";

export const EquipmentForm = ({
    onSubmit,
    data
  }:{
    data: Partial<Char>;
    onSubmit: SubmitHandler<Partial<Char>>;
    }) => {

  const { handleSubmit, control } = useForm({
    defaultValues: data
  });

  /**
   * This should be a series of radio groups with expandable areas for explanation of what
   * the sophonts are.
   */

  return (
    
    <ThemeProvider theme={strikerTheme}>
      <form className='form' onSubmit={handleSubmit(onSubmit)}>
        <h4>Equipment</h4>
        <div className='form-row'>
          <Controller name="name" control={control} render={({ field }) => <TextField {...field} label="name" />} />
        </div>
        <button
          type='submit'
          className='btn btn-block btn-danger'
        >
          Submit
        </button>
      </form>
    </ThemeProvider>


    
    
  )
};
