import styled from 'styled-components'

export const Wrapper = styled.article`
.tally-content {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: 1fr;
}
.tally-subject {
  grid-row-start: 1;
  grid-row-end: 2;
  color: var(--farout-brown);
  text-transform: capitalize;
  align-self: start;
}
.tally-value {
  grid-row-start: 1;
  grid-row-end: 2;
  color: var(--farout-green);
  align-self: start;
}
.tally-description {
  grid-row-start: 1;
  grid-row-end: 2;
  color: var(--farout-brown);
  align-items: start;
}
`