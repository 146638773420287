import React from 'react';
import { FormEvent, useEffect } from 'react';
import { Wrapper } from '../../../assets/wrappers/BlogSearch'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { useForm } from 'react-hook-form';
import { setLoading, fetchChars, filterChars, CharSearchState } from '../characterSlice';

export const CharSearch = () => {
    const { register, control, formState: { errors } } = useForm<CharSearchState>({
        defaultValues: {hasProfession: false, likeName: ''} as CharSearchState // or dispatch call?
    });
    const userstate = useAppSelector(state => state.userstate);
    const charstate = useAppSelector(state => state.charstate);


    const dispatch = useAppDispatch();

    
    useEffect(() => {
        dispatch(setLoading(true))
        dispatch(fetchChars());
    }, [dispatch]);

    
    const handleSearch = (e: FormEvent<HTMLInputElement>) => {
        if (charstate.isLoading) return
        dispatch(filterChars({...charstate.searchState, [e.currentTarget.name]: e.currentTarget.value}));

    }
    
    const clearFilters = (e: FormEvent<HTMLInputElement>) => {
        e.preventDefault()
    
    }
    return (
        <Wrapper>
        <form className='form'>
            <h4>search form</h4>
            <div className='form-row'>

                <label className='form-label'>Tag</label>
                <input className='form-input' type="text" {...register('hasProfession', {onChange: (e: React.FormEvent<HTMLInputElement>) => handleSearch(e)})} />
            </div>

            <div className='form-row'>
                <label className='form-label'>Title</label>
                <input className='form-input' type="text" {...register('likeName', {onChange: (e: React.FormEvent<HTMLInputElement>) => handleSearch(e)})} />
            </div>
            <button
                className='btn btn-danger'
                disabled={charstate.isLoading}                
                onClick={() => clearFilters}
            >
                clear filters
            </button>
        </form>
        </Wrapper>
    )
}