import React, { useEffect, useState } from "react";
import { Char, CharDTO, CountSpecRefDTO } from "../../models/specs";
import { useAppSelector, useAppDispatch } from '../../../../app/hooks'
import AppBar from "@mui/material/AppBar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from "@mui/material/Box";
import { createChar, editChar, fetchChar, setEmptyChar, setSelectedTabIndex } from "../../characterSlice";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { strikerTheme } from "../../../shared/components/StrikerTheme";
import { TYPE_BACKGROUND, TYPE_ENHANCEMENT, TYPE_PROFESSION, TYPE_QUALITY, TYPE_SKILL_DEF, TYPE_SOPHONT, TYPE_VOCATION, TYPE_WEAPON, fetchSpecs, getSpecsByType } from "../../../shared/specSlice";
import { addHoursToDate } from "../../../shared/utils/general";
import { useNavigate, useParams } from "react-router-dom";
import { AbilitiesForm } from "./AbilitiesForm";
import { ProfessionsForm } from "./ProfessionsForm";
import { SophontcyForm } from "./SophontcyForm";
import { BackgroundsForm } from "./BackgroundsForm";
import { EnhancementsForm } from "./EnhancementsForm";
import { EquipmentForm } from "./EquipmentForm";
import { SkillsForm } from "./SkillsForm";
import { DescriptionForm } from "./DescriptionForm";
import { Loading } from "../../../shared/components/Loading";
import { findAndLoadUser } from "../../../auth/authSlice";
import { infoAlert } from "../../../shared/appSlice";

const TTL = 24; // hours

interface TabPanelProps {
  children?: React.ReactNode;
  tabIndex: any;
  selectedTabIndex: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, selectedTabIndex, tabIndex, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTabIndex !== tabIndex}
      id={`simple-tabpanel-${tabIndex}`}
      aria-labelledby={`simple-tab-${tabIndex}`}
      {...other}
    >
      {selectedTabIndex === tabIndex && <Box p={8}>{children}</Box>}
    </div>
  );
}

export const SimpleTabs = () => {
  const charstate = useAppSelector(state => state.charstate);
  const userstate = useAppSelector(state => state.userstate);
  const specstate = useAppSelector(state => state.specState);
  
  
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { id } = useParams();
  
  const [user, setUser] = useState(userstate.loggedInUser?.username as string);
  const [data, setData] = useState(CharDTO.makeEmptyChar());
  const [loading, setLoading] = useState(true);


  const refreshCache = async (type: string) => {
    await dispatch(fetchSpecs({ foundry: 'chars', type: type}))
  }

  const loadSpecs = (type: string) => {
    const cached = getSpecsByType(type, specstate.specKeys);
    if (cached.length === 0) {
      refreshCache(type);

    } else {
      let refresh = cached.find(se => addHoursToDate(new Date(se.at), TTL).getTime() > Date.now())
  
      if (refresh) {
        refreshCache(type);
      }
    }
  }

  const loadChar = () => {
    dispatch(findAndLoadUser())
    userstate.loggedInUser && setUser(userstate.loggedInUser.username as string);
    if (user) {
      if (id) {
        console.log('reloading char (data) from charState')
        const f = async(id:string) => {
          let theAction = await dispatch(fetchChar(id));
          // here. we are using the return from fetchChar directly, because the redux state doesn't seem to get updated in time.
          // however action.payload is readonly, so we have to deep copy it.
          setData(structuredClone(theAction.payload) as Partial<Char>);
  
          // this has to stay here because we can only setLoading(false) after we have awaited the dispatch
          // if we put this at the end of the useEffect() (as I keep being tempted to do) it'll get called too early and the form will be blank.
          setLoading(false);
          console.log('did we get here?')
        }
        f(id);
      } else {
        // set local state with the blank character with user
        console.log(`the user's name is: ${user}`)
        if (user) {
          let theAction = dispatch(setEmptyChar(CharDTO.makeEmptyInitialisedCharFromUser(user)));
          setData(structuredClone(theAction.payload) as Partial<Char>);
          console.log(theAction.payload)
          if (!data.user) {
            console.log('WARNING: no user in char delivered by TabGroup')
          }
        }    
        setLoading(false);
      }
      // the following clearly fires off before data has been set in the above.
      console.log(data)
      loadSpecs(TYPE_SKILL_DEF);
      loadSpecs(TYPE_QUALITY);
      loadSpecs(TYPE_SOPHONT);
      loadSpecs(TYPE_BACKGROUND);
      loadSpecs(TYPE_PROFESSION);
      loadSpecs(TYPE_VOCATION);
      loadSpecs(TYPE_ENHANCEMENT);
      loadSpecs(TYPE_WEAPON);
      dispatch(setSelectedTabIndex(0))
  
    } else {
      // alert + navigate
      dispatch(infoAlert('cannot add/edit char when not logged in'))
      setTimeout(() => {
        navigate('/')
      }, 3000)

    }
  }

  useEffect(() => {
    console.log('data at useEffect')
    console.log(data)
    // data may be hung onto between renderings even when the id/user changes, so the below resets it
    let theAction = dispatch(setEmptyChar(Object.apply(Char)));
    setData(structuredClone(theAction.payload) as Partial<Char>);
    console.log(theAction.payload)
    loadChar();
    
  }, [id,user]);


  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    dispatch(setSelectedTabIndex(newValue));
  };

  const onSubmit = async (
    data: Partial<Char>
  ) => {
    console.log(`submitting char (data) with id: ${charstate.char.id}`)

    setLoading(true);
    if (id || charstate.char.id) {
      let theAction = await dispatch(editChar(data));
      setData(structuredClone(theAction.payload) as Partial<Char>);
    } else {
      // its a new char, so create 

      let theAction = await dispatch(createChar(data));
      setData(structuredClone(theAction.payload) as Partial<Char>);
    }
    setLoading(false);
  };


  return loading ? (
    <Loading centre />
  ) : (
    <div>
      <ThemeProvider theme={strikerTheme}>
      <AppBar position="static">
        <Tabs
          value={charstate.selectedTabIndex}
          onChange={handleChange}
          aria-label="character creation steps"
        >
          <Tab label="Description" />
          <Tab label="Sophontcy" />
          <Tab label="Professions" />
          <Tab label="Abilities" />
          <Tab label="Backgrounds" />
          <Tab label="Skills" />
          <Tab label="Enhancements" />
          <Tab label="Equipment" />
        </Tabs>
      </AppBar>
      <TabPanel selectedTabIndex={charstate.selectedTabIndex} tabIndex={0}>
        <DescriptionForm onSubmit={onSubmit} data={data} />
      </TabPanel>
      <TabPanel selectedTabIndex={charstate.selectedTabIndex} tabIndex={1}>
        <SophontcyForm onSubmit={onSubmit} data={data} />
      </TabPanel>
      <TabPanel selectedTabIndex={charstate.selectedTabIndex} tabIndex={2}>
        <ProfessionsForm onSubmit={onSubmit} data={data} />
      </TabPanel>
      <TabPanel selectedTabIndex={charstate.selectedTabIndex} tabIndex={3}>
        <AbilitiesForm onSubmit={onSubmit} data={data} />
      </TabPanel>
      <TabPanel selectedTabIndex={charstate.selectedTabIndex} tabIndex={4}>
        <BackgroundsForm onSubmit={onSubmit} data={data} />
      </TabPanel>
      <TabPanel selectedTabIndex={charstate.selectedTabIndex} tabIndex={5}>
        <SkillsForm onSubmit={onSubmit} data={data} />
      </TabPanel>
      <TabPanel selectedTabIndex={charstate.selectedTabIndex} tabIndex={6}>
        <EnhancementsForm onSubmit={onSubmit} data={data} />
      </TabPanel>
      <TabPanel selectedTabIndex={charstate.selectedTabIndex} tabIndex={7}>
        <EquipmentForm onSubmit={onSubmit} data={data} />
      </TabPanel>
      </ThemeProvider>
    </div>
  );
}