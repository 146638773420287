export class Spec {

  name: string;
  description: string;
  
  constructor(
    name: string,
    description: string,
  ) { 
    this.name = name;
    this.description = description;
  }
}