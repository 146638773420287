import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { fetchBlogs } from '../blogsSlice';
import { setBlogyearOpenIndex, setBlogyearSelectedIndex, setBlogmonthOpenIndex, setBlogmonthSelectedIndex, setBlogSelectedIndex } from '../blogsSlice';
import { Loading } from '../../shared/components/Loading'

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


export const BlogsListByMonth = () => {
    const blogstate = useAppSelector(state => state.blogstate);
    
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(!blogstate.blogs) {
            dispatch(fetchBlogs()).then(() => setLoading(false));
        }
        setLoading(false);
    }, [loading]);

    const navigate = useNavigate();


    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: string | undefined,
        type: string
      ) => {
        console.log(index)
        if (type === 'year') {
            dispatch(setBlogyearOpenIndex(index));
            dispatch(setBlogyearSelectedIndex(index));
            dispatch(setBlogmonthOpenIndex(''));
            dispatch(setBlogmonthSelectedIndex(''));
            dispatch(setBlogSelectedIndex(''));
        } else if (type === 'month') {
            dispatch(setBlogmonthOpenIndex(index));
            dispatch(setBlogmonthSelectedIndex(index));
            dispatch(setBlogSelectedIndex(''));
        } else {
            dispatch(setBlogSelectedIndex(index));
            setTimeout(() => {
                navigate(`/blog/${index}`)
              }, 1000)
    
        }
      };

    return loading ? (
      <Loading centre />
    ) : (
        <List component="div" disablePadding>

        {blogstate.menuBlogs && blogstate.menuBlogs.map(by => (
            <div>
                <ListItemButton sx={{ pl: 4 }} selected={blogstate.menuState.blogyearSelectedIndex === by.year} 
                onClick={(evt) => handleListItemClick(evt, by.year, 'year')}>
                    <ListItemText primary={by.year} />
                    {blogstate.menuState.blogyearOpenIndex === by.year ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={blogstate.menuState.blogyearOpenIndex === by.year} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    {by.months.map(bm => (
                        <div>
                        <ListItemButton sx={{ pl: 6 }} selected={blogstate.menuState.blogmonthSelectedIndex === bm.month} 
                        onClick={(evt) => handleListItemClick(evt, bm.month, 'month')}>
                            <ListItemText primary={bm.month} />
                            {blogstate.menuState.blogmonthOpenIndex === bm.month ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>

                        <Collapse in={blogstate.menuState.blogmonthOpenIndex === bm.month} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        {bm.blogs.map(b => (
                            <ListItemButton sx={{ pl: 8 }} selected={blogstate.menuState.blogSelectedIndex === b.id} 
                            onClick={(evt) => handleListItemClick(evt, b.id, 'blog')}>
                                <ListItemText sx={{'& .MuiListItemText-primary': {fontSize: '0.8rem'}}} primary={b.title} />
                            </ListItemButton>
                        ))}
                        </List>
                        </Collapse>
                        </div>
                    ))}
                    </List>
                </Collapse>
                
            </div>
        ))}
       
        
      </List>


        
    )
}

