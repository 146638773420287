import { Type } from "class-transformer";
import { SpecRef } from ".";

export class Level {
  level: number

  @Type(() => SpecRef)
  profession: SpecRef

  @Type(() => SpecRef)
  vocation: SpecRef

  constructor(
    level: number,

    profession: SpecRef,
  
    vocation: SpecRef
  
  ){
    this.level = level;

    this.profession = profession;
  
    this.vocation = vocation;
  
  }
}