import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { createBlog, editBlog, fetchBlog, fetchBlogTags } from '../blogsSlice';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Blog, BlogDTO } from '../models';
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { strikerTheme } from "../../shared/components/StrikerTheme";
import TextField from "@mui/material/TextField";
import { shrinkIfValue } from '../../shared/utils/general';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from 'html-to-draftjs';
import { Tag } from '../../shared/components';
import AddIcon from '@mui/icons-material/Add';
import { Checkbox, FormControlLabel } from '@mui/material';
import { FileUploadResponseData, useUploadMutation } from '../../shared/fileSlice';

type FormValues = {
  blog: Blog;
  tagsInBlog: { name: string }[];
  newTag: string;
};
export const BlogEntryForm = () => {

  const { id } = useParams();
  const navigate = useNavigate();

  const userstate = useAppSelector(state => state.userstate);
  const blogstate = useAppSelector(state => state.blogstate);

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<FormValues>({ blog: BlogDTO.makeEmptyBlog(), tagsInBlog: [], newTag: '' });
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [tagsInUseElsewhere, setTagsInUseElsewhere] = useState<string[]>([]);
  const [uploadedImages, setUploadedImages] = useState<{file:File,localSrc:string}[]>([]);



  const { handleSubmit, getValues, setValue, control } = useForm<FormValues>({ values: data });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tagsInBlog"
  });

  const setTheEditorState = (blog: Blog) => {
    const contentBlock = htmlToDraft(blog.body);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    setEditorState(editorState);
  }

  const setFormData = (blog: Blog) => {
    setData({
      blog: blog, tagsInBlog: blog.tags.map(t => {
        return { name: t }
      }), newTag: ''
    });
  }

  useEffect(() => {
    console.log('running useEffect ok?');
    dispatch(fetchBlogTags())
      .then(a => setTagsInUseElsewhere((a.payload as string[])
        .map(t => {
          return t
        })));

    if (id) {
      console.log(id)
      dispatch(fetchBlog(id))
        .then(b => {
          let returnedBlog = structuredClone(b.payload) as Blog
          setFormData(returnedBlog);
          setTheEditorState(returnedBlog);
          dispatch(fetchBlogTags())
            .then(a => setTagsInUseElsewhere((a.payload as string[])
              .filter(t => !returnedBlog.tags.includes(t))
              .map(t => {
                return t
              })))
        })
        .then(() => {
          setLoading(false)
          console.log(getValues())
        });
    }

  }, []);

  useEffect(() => {
    console.log('12 gauge autoloader')
    setLoading(false)
  }, [loading]);

  const getCurrentAuthor = (): string => {
    return userstate.loggedInUser?.username as string
  }

  const onSubmit = async (data: FormValues) => {
    setLoading(true);
    console.log(`submitting blog (data) with id: ${id}`)
    console.log(getValues()) // this has everything, including the new tag in the blog model
    console.log(data)

    // move tagsInBlog model into data.blog.tags
    let tagModel: string[] = getValues().tagsInBlog.map(nt => {
      return nt.name
    })
    data.blog.tags = tagModel;
    console.log(data)

    if (data.blog.id) {
      let theAction = await dispatch(editBlog(data.blog));
      let returnedBlog = structuredClone(theAction.payload) as Blog
      setFormData(returnedBlog);
      setTheEditorState(returnedBlog);
      setLoading(false);
    } else {
      // its a new char, so create 
      data.blog.author = getCurrentAuthor();
      let theAction = await dispatch(createBlog(data.blog));
      let returnedBlog = structuredClone(theAction.payload) as Blog

      // crucially, the returned blog must have an id
      console.log(returnedBlog)
      // just did a create where the id was not set, pretty sure returned data had the id

      setFormData(returnedBlog);
      setTheEditorState(returnedBlog);
      setLoading(false);
    }
    navigate('/blogs')
  };
  const [uploadFile, {data: item}] = useUploadMutation();


  const _uploadImageCallBack = async (file: File):Promise<{ data: { link: string } }>  => {
    
    let item: {data: FileUploadResponseData} = await uploadFile({ name: file.name, file: file }) as {data: FileUploadResponseData};

    let uploadUrl = `http://${item.data.fileUploadUrl}`;

    return new Promise(
      (resolve, reject) => {
        resolve({ data: { link: uploadUrl } });
      }
    );
  }

  return loading ? (
    <p>Loading...</p>
  ) : (
    <ThemeProvider theme={strikerTheme}>
      <form className='form-wide' onSubmit={handleSubmit(onSubmit)}>
        <Controller name="blog.title" control={control} render={({ field }) =>
          <TextField sx={{ width: '60ch' }} {...field} value={field.value} label="title" InputLabelProps={shrinkIfValue(field.value)} />} />
        <Controller name="blog.subtitle" control={control} render={({ field }) =>
          <TextField sx={{ width: '80ch' }} {...field} value={field.value} label="subtitle" InputLabelProps={shrinkIfValue(field.value)} />} />
        <Controller
          name='blog.body'
          control={control}
          defaultValue=''
          render={({ field: { value, onChange } }) => (
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={newState => {
                setEditorState(newState);
                setValue('blog.body', (draftToHtml(convertToRaw(newState.getCurrentContent()))));
              }}
              toolbar={{ image: { uploadCallback: _uploadImageCallBack, inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg,application/pdf'}}}
            />
          )}
        />
        <h5>Current Tags</h5>
        {!getValues().tagsInBlog || getValues().tagsInBlog.length === 0 ? `There are no tags to display` : ``}

        {getValues().tagsInBlog?.map((t, i) => (
          <div>
          <Tag key={i} content={t.name} isLarge={false} onClick={() => {
              setLoading(true)
              remove(i)
              //setValue('tagsInBlog', data.tagsInBlog.filter(tib => tib.name !== t.name))
            }} />
        </div>
        ))}

        <h5>Add Tags</h5>
        Either create a new tag ...
        <Controller name="newTag" control={control} render={({ field }) =>
        <div className='tight-container'>
          <TextField sx={{ width: '20ch' }} {...field} value={field.value} label="new tag" InputLabelProps={shrinkIfValue(field.value)} />
          <button
            type='button'
            className='btn add-btn mt-5 ml-5'
            disabled={field.value === ''}
            onClick={() => {
              setLoading(true)
              append({
                name: field.value
              })
              
            }}
          >
            Append
          </button>
        </div>
          } />

          Or add one that is in use elsewhere.
        {tagsInUseElsewhere?.map((t, i) => (
          <Tag key={i} icon={<AddIcon sx={{ color: 'fff' }} fontSize="small" />} content={t} isLarge={false} onClick={() => append({ name: t })}></Tag>
        ))}


        <FormControlLabel
          control={
            <Controller
              name="blog.isPublished"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          }
          label={getValues().blog.isPublished ? 'Published?' : 'Publish Immediately?'}
        />

        <button
          type='submit'
          className='btn btn-block btn-danger'
        >
          Submit
        </button>

      </form>
    </ThemeProvider>


  );

}

