import React, { useEffect } from 'react';
import { CharSearchAndList, CharDisplay } from '.'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { User, ROLE_ADMIN, ROLE_PLAYER } from '../../auth/User'
import { CharEntry } from './gen';
import { Intro } from './Intro';
import { setCharDisplayMode } from '../characterSlice';

const DISPLAY_MODE_LIST:string = 'DISPLAY-MODE-LIST'
const DISPLAY_MODE_NEW:string = 'DISPLAY-MODE-NEW'
const DISPLAY_MODE_EDIT:string = 'DISPLAY-MODE-EDIT'
const DISPLAY_MODE_READ:string = 'DISPLAY-MODE-READ'

/**
 * 
 * 
 * @returns <Intro /> unless role permits <CharSearchAndList /> and activity directs <CharEntry />
 */
export const CharContainer = () => {
  const userstate = useAppSelector(state => state.userstate);
  const charstate = useAppSelector(state => state.charstate);

  const dispatch = useAppDispatch();
  // useEffect Hook = componentDidMount, componentDidUpdate, and componentWillUnmount combined
  useEffect(() => {
    if (!charstate.displayMode) {
        console.log(`setting displayMode`)
        dispatch(setCharDisplayMode(DISPLAY_MODE_LIST))
    }

  }, []);

  const canWrite = () => {
    const user: User = userstate.loggedInUser as User;
    return user && (user.role === ROLE_ADMIN || user.role === ROLE_PLAYER);
  }

  const isListing = () => {
    return charstate.displayMode === DISPLAY_MODE_LIST;
  }

  const isWriting = () => {
    return charstate.displayMode === DISPLAY_MODE_NEW || charstate.displayMode === DISPLAY_MODE_EDIT;
  }

  return (
    <>
      {canWrite() ? (isListing() ? <CharSearchAndList /> : isWriting() ? <CharEntry /> : <CharDisplay />) : <Intro />}
    </>
  )
}

