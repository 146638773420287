import React, { useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { fetchBlogs, pageSize } from '../blogsSlice';

import { Wrapper } from '../../../assets/wrappers/BlogList';
import { BlogSummary, PageBtnContainer } from '.';
import { Link } from 'react-router-dom';
import { ROLE_ADMIN, ROLE_WRITER } from '../../auth/User';
import { pageArray } from '../../shared/utils/general';
import { Blog } from '../models';
import { Loading } from '../../shared/components/Loading';


/**
 * Protected search page with results for blog maintenance
 * @returns 
 */
export const BlogList = () => {
    const userstate = useAppSelector(state => state.userstate);
    const blogstate = useAppSelector(state => state.blogstate);
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(true);
    const [blogs, setBlogs] = useState(blogstate.blogs);
    const [noOfBlogs, setNoOfBlogs] = useState(blogstate.blogs ? blogstate.blogs.length : 0);
    const [blogPageItems, setBlogPageItems] = useState(new Array<Blog>());
      

    useEffect(() => {
      console.log('BlogSummary component just decremented noOfChars which just changed the loading state')
      // fetch the blogs again and (re) setNoOfBlogs (should end up being the same as what the subcomponent set)
      dispatch(fetchBlogs()).then((a) => {
        let returnedBlogs: Array<Blog> = structuredClone(a.payload) as Array<Blog>
        setBlogs(returnedBlogs)
        setNoOfBlogs(returnedBlogs.length || 0)
        let pageState = { pageCount: Math.ceil(returnedBlogs.length / pageSize), page: 1 }
        setBlogPageItems(pageArray(returnedBlogs ? returnedBlogs : [], pageState.page, pageSize))
      });

      setLoading(false);
    }, [noOfBlogs]);
  
    console.log(blogstate.blogs)

    const user = userstate.loggedInUser;

    const canEdit = user && (user.role === ROLE_WRITER || user.role === ROLE_ADMIN)

    
     
    if (!blogs) {
        return loading ? (
          <Loading centre />
        ) : (
          <Wrapper>
            <h4>No blogs to display...</h4>
                  { canEdit && (
                    <footer>
                      <div className='actions'>
                        <Link
                          to='/add-blog'
                          className='btn edit-btn'
                        >
                          Add
                        </Link>
                      </div>
                    </footer>
                  )}
                  
          </Wrapper>
        )
      }
    
      return loading ? (
        <Loading centre />
      ) : (
        <Wrapper>
                  { canEdit && (
          <footer>
            <div className='actions'>
              <Link
                to='/add-blog'
                className='btn edit-btn'
              >
                Add
              </Link>
            </div>
          </footer>
        )}

          <h5>
            {/* wow, fancy */}
            {blogs.length} blog{blogs.length > 1 && 's'} found
          </h5>
          <div className='blogs'>
            {
              
            blogPageItems.map((blog) => {
              return <BlogSummary blog={blog} />
            })
            }
          </div>
          {blogstate.pageState.pageCount > 1 && <PageBtnContainer pageState={blogstate.pageState} />}
        </Wrapper>
      )
    };