import { WritableDraft } from 'immer/dist/internal';

export const DISPLAY_MODE_NEW:string = 'DISPLAY-MODE-NEW'
export const DISPLAY_MODE_EDIT:string = 'DISPLAY-MODE-EDIT'
export const DISPLAY_MODE_READ:string = 'DISPLAY-MODE-READ'

export class Blog {

    public static makeEmpty(): Blog {
        return new Blog([], '', false, '', '', '', '', '', '');
    }

    public static makeEmptyFromTitle(title: string): Blog {
        return new Blog([], '', false, 'Mike', '', '', title, '', '');
    }

    public static asWritableDraft(o: Blog): WritableDraft<Blog> {
        return {
            tags: o.tags,
            id: o.id,
            isPublished: o.isPublished,
            author: o.author,
            created: o.created,
            updated: o.updated,
            title: o.title,
            subtitle: o.subtitle,
            body: o.body   
        }
    }


    constructor(
        public tags: string[],
        public id: string,
        public isPublished: boolean,
        public author: string,
        public created: string,
        public updated: string,
        public title: string,
        public subtitle: string,
        public body: string
    ) {}
    
}
