import React, { useState, useEffect } from 'react';
import { Char } from '../models/specs/Char';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { fetchChars, pageSize } from '../characterSlice';

import { Wrapper } from '../../../assets/wrappers/BlogList';
import { CharSummary, PageBtnContainer } from '.';
import { Link } from 'react-router-dom';
import { ROLE_ADMIN, ROLE_WRITER } from '../../auth/User';
import { pageArray } from '../../shared/utils/general';
import { Loading } from '../../shared/components/Loading';


/**
 * Protected search page with results for blog maintenance
 * @returns 
 */
export const CharList = () => {
  const userstate = useAppSelector(state => state.userstate);
  const charstate = useAppSelector(state => state.charstate);

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const [noOfChars, setNoOfChars] = useState(charstate.chars ? charstate.chars.length : 0);

  useEffect(() => {
    console.log('CharSummary component just decremented noOfChars which just changed the loading state')
    // fetch the chars again and (re) setNoOfChars (should end up being the same as what the subcomponent set)
    dispatch(fetchChars()).then((c) => {
      setNoOfChars(((c.payload) as Char[]).length || 0)
    });
    setLoading(false);
  }, [noOfChars]);

  const user = userstate.loggedInUser;

  const canEdit = user && (user.role === ROLE_WRITER || user.role === ROLE_ADMIN)

  const charPageItems = pageArray(charstate.chars, charstate.pageState.page, pageSize);


  if (!charstate.chars) {
    // this first return is here just in case the whole char model is uninitialised (shouldn't be the case)
    return loading ? (
      <Loading centre />
    ) : (
      <Wrapper>
        <h4>No characters to display...</h4>
        {canEdit && (
          <footer>
            <div className='actions'>
              <Link
                to='/add-char'
                className='btn info-btn'
              >
                Add
              </Link>
            </div>
          </footer>
        )}

      </Wrapper>
    )
  }

  // this is the case we expect to return (either exists but 0 or exists and has chars)
  return loading ? (
    <Loading centre />
  ) : (
    <Wrapper>
      {canEdit && (
        <footer>
          <div className='actions'>
            <Link
              to='/add-char'
              className='btn info-btn'
            >
              Add
            </Link>
          </div>
        </footer>
      )}

      <h5>
        {/* wow, fancy */}
        {charstate.chars.length} character{charstate.chars.length !== 1 && 's'} found
      </h5>
      <div className='blogs'>
        {charPageItems.map((char) => {
          return <CharSummary key={char.id as string} char={char} loadingState={setLoading} nCharsState={setNoOfChars} />
        })}
      </div>
      {charstate.pageState.pageCount > 1 && <PageBtnContainer pageState={charstate.pageState} />}
    </Wrapper>
  )
};