import { TextField, MenuItem } from "@mui/material";
import { forwardRef, Dispatch, SetStateAction, Ref, useState, useEffect, ChangeEvent } from "react";
import { UseControllerProps } from "react-hook-form";
import { useAppSelector } from "../../../../app/hooks";
import { Wrapper } from "../../../../assets/wrappers/SkillDisplayForm";
import { CountSpecRef, Char, CountSpecRefDTO } from "../../models/specs";

/**
 * Component presents the based modifier in two format sizes: large for displaying headlining numbers, 
 * or small for less ostentatious display
 * @param param0 
 * @returns 
 */
export const SkillDisplayForm = forwardRef(({
  control,
  onChange,
  name,
  value,
  watchedValue,
  rankTracker,
  showLarge,
}: {
  onChange: any
  value: CountSpecRef
  watchedValue: (CountSpecRef | Dispatch<SetStateAction<CountSpecRef | undefined>> | undefined)[]
  rankTracker: any
  showLarge: boolean
} & UseControllerProps<Partial<Char>>, ref:Ref<HTMLInputElement>) => {

  const specState = useAppSelector(state => state.specState);

  // a watched value such as that defined by:   const [str, setStr] = useState(getValues().strength);
  // vProp any 
  let useStateAbility: CountSpecRef | undefined = watchedValue[0] as CountSpecRef;
  let setUseStateAbility: Dispatch<SetStateAction<CountSpecRef>> | undefined = watchedValue[1] as Dispatch<SetStateAction<CountSpecRef>>;

  let watchedValueArray: CountSpecRef[] = useStateAbility ? [useStateAbility as CountSpecRef] : Array<CountSpecRef>();

  const [count, setCount] = useState(value.count);
  const [stringBMs, setStringBMs] = useState(CountSpecRefDTO.stringifyBMs(CountSpecRefDTO.readBMs(value,watchedValueArray)) || 0);

  useEffect(() => {
//    console.log(`useEffect for ${name} called`)
    let bmMap = CountSpecRefDTO.readBMs(value,watchedValueArray)
    setStringBMs(CountSpecRefDTO.stringifyBMs(bmMap))  
  }) // getValues updates, so potentially, I could set this to change when char changes - hope that doesn't just mak an infinite loop

//  console.log(`${value.spec.name} is at ${value.count} so the bms are ${stringBMs}`)
//  console.log('the watched skill is...')
//  console.log(watchedValue[0])

  // in a reference like Str/Dex:Cbt-Melee:Wpn-Poles
  // we return 2 / 3 : 1 : 1, so the toal based modifier is either 3 or 4
  // the map should then read:
  // {
  //   Str, 2
  //   Dex, 3
  //   Str/Dex:Cbt-Melee, 1
  //   Str/Dex:Cbt-Melee:Wpn-Poles, 1
  // }
 
  // skill = the CountSpecRef for the leaf node, so Wpn:Poles in this example
  // although the skill.spec.name will be Str/Dex:Cbt-Melee:Wpn-Poles giving us a guide on where to get the bases
  
  // display
  // 2/3 + 1 + 1 = +3/+4
  // Str/Dex:Cbt-Melee:Wpn-Poles

  function handleChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    if(setUseStateAbility !== undefined) setUseStateAbility(value)
    value.count = parseInt(event.target.value);

    // why are we setting this?
    setCount(value.count);

    onChange(value);
    rankTracker();
  }

  function setInitialValue(value: CountSpecRef | undefined): number {
    let val = value ? value.count : 0;
    return val;
  }
  

	return (

    <Wrapper>
      <div className='skill-content'>
        <div className='skill-score'>{stringBMs}</div>
        <div className='skill-name'>{value.spec.name}</div>
        <div className='ranks-score'>
        <TextField sx={{ width: '6ch' }} inputRef={ref} name={name} value={setInitialValue(value)} onChange={handleChange} select>
        {[...Array(11).keys()].map((n) => (
          <MenuItem key={n} value={n} selected={value?.count===n}>
            {n}
          </MenuItem>
        ))}
        </TextField>
        </div>
        <div className='ranks-label'>Ranks</div>
        
      </div>      
    </Wrapper>
	);
});