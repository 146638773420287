import { Wrapper } from "../../../../assets/wrappers/TallyPanel";

export const TallyPanel = ({
  subject,
  value,
  description
}: {
  subject: string,
  value: number,
  description: string
}) => {

  return (
    <Wrapper>
      <div className='tally-content'>
        <div className='tally-subject'>{subject}</div>
        <div className='tally-value'>{value}</div>
        <div className='tally-description'>{description}</div>
      </div>      

    </Wrapper>
  );
}