import React from 'react';
import { FormEvent, useEffect } from 'react';
import { Wrapper } from '../../../assets/wrappers/BlogSearch'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { useForm, useFieldArray } from 'react-hook-form';
import { setLoading, fetchBlogs, fetchBlogTags, filterBlogs } from '../blogsSlice';
import { Blog, BlogSearchState } from '../models';

export const BlogSearch = () => {
    const { register, control, setValue, watch, formState: { errors } } = useForm<BlogSearchState>({
        defaultValues: BlogSearchState.makeEmpty() // or dispatch call?
    });
    const blogstate = useAppSelector(state => state.blogstate);

    const dispatch = useAppDispatch();

    
    // get the default list of blogs
    // now I'm hoping that this sets the 
    useEffect(() => {
        dispatch(setLoading(true))
        dispatch(fetchBlogs());
    }, [dispatch]);

    // update the blogs state array, using hte filter
    
    const handleSearch = (e: FormEvent<HTMLInputElement>) => {
        if (blogstate.isLoading) return
        dispatch(filterBlogs({...blogstate.searchState, [e.currentTarget.name]: e.currentTarget.value}));

    }
    
    const clearFilters = (e: FormEvent<HTMLInputElement>) => {
        e.preventDefault()
    
    }
    return (
        <Wrapper>
        <form className='form'>
            <h4>search form</h4>
            <div className='form-row'>
            {/* search by tag */}

                <label className='form-label'>Tag</label>
                <input className='form-input' type="text" {...register('hasTag', {onChange: (e) => handleSearch(e)})} />
            </div>

            <div className='form-row'>
                <label className='form-label'>Title</label>
                <input className='form-input' type="text" {...register('likeTitle', {onChange: (e) => handleSearch(e)})} />
            </div>
            <button
                className='btn btn-danger'
                disabled={blogstate.isLoading}                
                onClick={() => clearFilters}
            >
                clear filters
            </button>
        </form>
        </Wrapper>
    )
}